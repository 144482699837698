import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./login.css";
import AlertDismissible from "../../components/Alert/Alert";
import { Credentials, User } from "./login.types";
import { loginUser, signUpUser } from "../../services/api";

import { useDispatch } from "react-redux";
import { setLogin } from "../../store/auth-slice";
import { setLoader } from "../../store/common-slice";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";

function Login() {
    let [authMode, setAuthMode] = useState("signin");
    let [identifier, setIdentifier] = useState("");
    let [userName, setUserName] = useState("");
    let [password, setPassword] = useState("");
    let [mailId, setMailId] = useState("");
    let [error, setError] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const changeAuthMode = () => {
        setAuthMode(authMode === "signin" ? "signup" : "signin");
    };

    const signIn = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let cred: Credentials = {
            identifier: identifier,
            password: password,
        };
        try {
            dispatch(setLoader(true));
            const userData = await loginUser(cred);
            if (
                userData.jwt !== null &&
                typeof userData.jwt !== "undefined" &&
                userData.jwt !== ""
            ) {
                dispatch(
                    setLogin({
                        user: userData.user.username,
                        token: userData.jwt,
                    })
                );
                dispatch(setLoader(false));
                navigate("/map");
            } else {
                setError(userData.error.message);
            }
        } catch (error) {
            dispatch(setLoader(false));
        }
    };

    const signUp = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let data: User = {
            username: userName,
            email: mailId,
            password: password,
        };
        try {
            dispatch(setLoader(true));
            const userData = await signUpUser(data);
            setAuthMode("signin");
            dispatch(setLoader(false));
        } catch (error) {
            dispatch(setLoader(false));

        }
    };

    if (authMode === "signin") {
        return (
            <>
                {error !== null ? (
                    <AlertDismissible
                        variant="danger"
                        msg={error}
                    ></AlertDismissible>
                ) : (
                    <></>
                )}
                <div className="Auth-form-container">
                    <form className="Auth-form" onSubmit={signIn}>
                        <div className="Auth-form-content">
                            <h3 className="Auth-form-title">Sign In</h3>
                            <div className="form-group mt-3">
                                <label>User name / Email address</label>
                                <input
                                    className="form-control mt-1"
                                    placeholder="Enter email"
                                    onChange={(e) =>
                                        setIdentifier(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control mt-1"
                                    placeholder="Enter password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                            <div className="text-center">
                                Sign in with
                                <a
                                    href={
                                        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/connect/microsoft`
                                    }
                                >
                                    <span className="link-primary">
                                        {"GCP"}
                                    </span>
                                </a>
                                {/* <span
                                    className="link-primary"
                                    onClick={() =>
                                        (window.location.href =
                                            "https://stlpricing-dev.azurewebsites.net/api/connect/microsoft")
                                    }
                                >
                                    {" Azure"}
                                </span> */}
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="Auth-form-container">
                <form className="Auth-form" onSubmit={signUp}>
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Sign Up</h3>
                        <div className="form-group mt-3">
                            <label>Full Name</label>
                            <input
                                className="form-control mt-1"
                                placeholder="e.g Jane Doe"
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Email address</label>
                            <input
                                type="email"
                                className="form-control mt-1"
                                placeholder="Email Address"
                                onChange={(e) => setMailId(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control mt-1"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        </div>
                        <div className="text-center">
                            Already registered?{" "}
                            <span
                                className="link-primary"
                                onClick={changeAuthMode}
                            >
                                Sign In
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Login;
