import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSalesTax } from "../../../../store/proposal-slice";

function Discounts(props: {
    salesTaxOnTotalAfterDiscount: Function; packageType: String;
}) {
    const [checkedState, setCheckedState] = useState<boolean>(false);
    const [salesTax, setCurrSalesTax] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        setCheckedState(false);
        setCurrSalesTax(0);
    }, [props.packageType]);

    //setCheckedState(false);
    const handleOnChange = () => {
        setCheckedState(!checkedState);
        if (checkedState) {
            setCurrSalesTax(0);
            props.salesTaxOnTotalAfterDiscount(0);
            dispatch(setSalesTax(0));
        }
    };

    const updateSalesTax = (val: number) => {
        if (val <= 0) {
            val = 0;
        }
        if (val > 100) {
            val = 100;
        }
        setCurrSalesTax(val);
        props.salesTaxOnTotalAfterDiscount(val);
        dispatch(setSalesTax(val));
    };

    return (
        <div style={{ paddingBottom: '5px', paddingTop: '10px' }}>
            <h4 className="fs-6 fw-bold">SalesTax</h4>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={checkedState}
                    id="salestax-check"
                    onChange={() => handleOnChange()}
                />
                <label
                    className="form-check-label text-light fw-normal"
                    htmlFor="salestax-check"
                >
                    Add Sales Tax (in %)
                </label>
                <div
                    className="right-section d-flex align-items-end flex-column"
                    style={{ marginTop: "-20px" }}
                >
                    <div className="input-group mb-3" style={{ width: "17%" }}>
                        <input
                            className="bg-darkprimary text-light form-control input-wrap"
                            style={{
                                borderRadius: "0",
                                padding: "10px",
                                border: "none",
                                textAlign: "center",
                            }}
                            type="number"
                            min="1"
                            max="100"
                            placeholder="%"
                            onChange={(e) => {
                                let val = parseInt(e.target.value, 10);
                                if (!isNaN(val)) {
                                    updateSalesTax(val);
                                }
                            }}
                            disabled={!checkedState}
                            value={salesTax}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Discounts;
