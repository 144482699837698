export const PackageTypeEnum = Object.freeze({
    TAZ_BASED_PACKAGES: 1,
    ZONE_BASED_PACKAGES: 2,
    SOLUTION_PACKAGES: 3,
    MODE_PACKAGES: 4
});


export const MarginalCostToAddModule: { [key: number]: number } = Object.freeze({
    0: 0,
    1: 1,
    2: 0.75,
    3: 0.5625,
    4: 0.421875,
    5: 0.31640625,
    6: 0.2373046875,
});