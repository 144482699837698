import axios from "axios";
import { Credentials, User } from "../pages/login/login.types";

export async function loginUser(payload: Credentials) {
    let resp = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/auth/local`,
        data: JSON.stringify(payload),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).catch((error: any) => {
        return error;
    });
    return resp.data;
}

export async function signUpUser(payload: User) {
    let resp = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/auth/local/register`,
        data: JSON.stringify(payload),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).catch((error: any) => {
        return error;
    });
    return resp.data;
}

export async function getMasterData(token: string) {
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/api`,
        timeout: 10000,
        headers: { Authorization: "Bearer " + token },
    });
    const resp = await Promise.all([
        instance.get("/addons"),
        instance.get("/modes"),
        instance.get("/package-additives"),
        instance.get("/subscriptions"),
        instance.get("/get-packages"),
        instance.get("/modules"),
        instance.get("/package-subscription-mappings?filters[is_active][[$eq]=true"),
        instance.get("/subscription-module-mappings?filters[is_active][[$eq]=true"),
        instance.get("/subscription-addon-mappings?filters[is_active][[$eq]=true"),
        instance.get("/subscription-module-disable-mappings"),
        instance.get("/factors"),
        instance.get("/decision-rules"),
    ]).catch((error: any) => {
        return error;
    });
    //const data = resp.map((res) => res.data);
    return resp;
}

export async function getBasePrice(token: string, tazCount: number) {
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/api`,
        timeout: 10000,
        headers: { Authorization: "Bearer " + token },
        params: { "filters[taz_count][$eq]": tazCount },
    });

    const resp = await instance.get("/base-prices").catch((error: any) => {
        return error;
    });

    //const data = resp.map((res) => res.data);
    return resp;
}

export async function getModeMetrices(
    modeIds: Array<number | undefined>,
    token: string
) {
    let resp = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/mode-analysis-mapping/get-metrics`,
        data: JSON.stringify({ ids: [modeIds] }),
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        },
    }).catch((error: any) => {
        return error;
    });
    return resp.data;
}

export async function getSubscriptionMetrices(
    modeIds: Array<number | undefined>,
    token: string
) {
    let resp = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/subscription-mapping/get-metrics`,
        data: JSON.stringify({ ids: [modeIds] }),
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        },
    }).catch((error: any) => {
        return error;
    });
    return resp.data;
}
