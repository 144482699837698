import { useState, useEffect } from "react";
import { Zone } from "../../../models/local-models";

function RefineTaz(props: {
    currentStep: number;
    setTazCount: Function;
    zoneList: Zone[];
}) {
    let [totTazCount, setTotTazCount] = useState(0);
    let [tazCount, setTazCount] = useState(0);
    let [tazdiff, setTazdiff] = useState(0);
    let [tazAction, setTazAction] = useState(false);
    let uniqueIntersectZoneIds: any[] = [];
    let uniqueIntersectRecords: any[] = [];
    let uniqueWithInZoneIds: any[] = [];
    let uniqueWithInRecords: any[] = [];
    function getUniqueIntersects(data: Zone) {
        let x = data.d_interectval;
        if (x && x !== undefined) {
            x.forEach((record: any) => {
                if (record.geometry.type === "MultiPolygon") {
                    const zoneId = record.properties.zone_id;

                    if (!uniqueIntersectZoneIds.includes(zoneId)) {
                        uniqueIntersectRecords.push(record);
                        uniqueIntersectZoneIds.push(zoneId);
                    }
                }
            });
        }
    }

    function getUniqueWithin(data: Zone) {
        let x = data.d_withinval;
        if (x && x !== undefined) {
            x.forEach((record: any) => {
                if (record.geometry.type === "MultiPolygon") {
                    const zoneId = record.properties.zone_id;

                    if (!uniqueWithInZoneIds.includes(zoneId)) {
                        uniqueWithInRecords.push(record);
                        uniqueWithInZoneIds.push(zoneId);
                    }
                }
            });
        }
    }

    useEffect(() => {
        let taz = 0;
        let tazdiff = 0;
        if (props.zoneList.length > 0) {
            props.zoneList.forEach((data) => {
                if (data.zoneType === "Draw") {
                    getUniqueIntersects(data);
                    getUniqueWithin(data);

                    let x =
                        uniqueIntersectZoneIds.length -
                        uniqueWithInZoneIds.length;
                    tazdiff = x;
                    taz = uniqueIntersectRecords.length;
                } else {
                    taz = taz + data.tazcount;
                }
            });
        }

        setTazCount(taz);
        setTotTazCount(taz);
        props.setTazCount(taz);
        setTazdiff(tazdiff);
        setTazAction(false);
    }, [props.zoneList]);

    if (props.currentStep !== 3) {
        return null;
    }
    const addTaz = () => {
        setTazCount(tazCount + tazdiff);
        setTazAction(!tazAction);
        props.setTazCount(tazCount + tazdiff);
    };
    const removeTaz = () => {
        setTazCount(totTazCount - tazdiff);
        setTazAction(!tazAction);
        props.setTazCount(totTazCount - tazdiff);
    };

    return (
        <div className="sidebar p-5">
            <div className="p-3 text-light bg-normalprimary d-flex align-items-center shadow">
                <div style={{ width: "30em" }}>
                    <h2 className="fs-5 fw-normal">Refine TAZ Selection</h2>
                    <div className="bg-midprimary my-4 p-4">
                        <div className="my-2">
                            <span className="bg-paleprimary text-darkprimary p-2 fw-bold">
                                Number of TAZ(s) intersecting selected region :{" "}
                                {tazdiff}
                            </span>
                        </div>
                        <div>
                            <p className="pt-4">
                                TAZ(s) that partially intersect with your region
                                but are not entirely contained within its
                                boundaries won't be automatically selected. You
                                have the option to manually include or exclude
                                TAZ from your current selection.
                            </p>
                        </div>
                        <div className="d-flex gap-2">
                            <button
                                className="btn btn-outline-dark btn-sm"
                                disabled={!tazAction}
                                onClick={addTaz}
                            >
                                Add to selection
                            </button>
                            <button
                                className="btn btn-outline-dark btn-sm"
                                disabled={tazAction}
                                onClick={removeTaz}
                            >
                                Remove from selection
                            </button>
                        </div>
                    </div>
                    <div className="bg-midprimary p-4">
                        <div className="my-2">
                            <span className="bg-altprimary text-darkprimary p-2 fw-bold">
                                Number of TAZ(s) selected : {tazCount}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RefineTaz;
