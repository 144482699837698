import { ChangeEvent, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Zone } from "../../../models/local-models";
import { FaWindowClose } from "react-icons/fa";
import JSZip from "jszip";
import {
    featureCollection,
    AllGeoJSON,
    Feature,
    Geometry,
    Properties,
    Point,
    LineString,
    Polygon,
    MultiPoint,
    MultiLineString,
    MultiPolygon,
} from "@turf/helpers";
import shp from "shpjs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import proj4 from "proj4";
import { kml } from "@tmcw/togeojson";

import {
    GeometryCollection,
    Geometry as geojsongeom,
    Feature as geojsonFeature,
    GeoJsonProperties,
} from "geojson";
proj4.defs([
    [
        "WGS84",
        "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees",
    ],
    [
        "EPSG:3857",
        "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs",
    ],
]);
type ShpGeometry = Point | Polygon | LineString;
const isSpecificGeometry = (
    geom: Geometry
): geom is
    | Point
    | LineString
    | Polygon
    | MultiPoint
    | MultiLineString
    | MultiPolygon => {
    return [
        "Point",
        "LineString",
        "Polygon",
        "MultiPoint",
        "MultiLineString",
        "MultiPolygon",
    ].includes(geom.type);
};
// Function to extract individual geometries from a GeometryCollection
const extractGeometries = (
    geometryCollection: GeometryCollection
): Geometry[] => {
    let geometries: Geometry[] = [];

    geometryCollection.geometries.forEach((geometry) => {
        if (geometry.type === "GeometryCollection") {
            geometries = geometries.concat(extractGeometries(geometry));
        } else {
            geometries.push(geometry);
        }
    });

    return geometries;
};

type SingleGeometry =
    | GeoJSON.Point
    | GeoJSON.LineString
    | GeoJSON.Polygon
    | GeoJSON.MultiPoint
    | GeoJSON.MultiLineString
    | GeoJSON.MultiPolygon;
type AnyGeometry = SingleGeometry | GeoJSON.GeometryCollection;

const convertKMLToGeoJSON = (kmlContent: string) => {
    const kmlObject = new DOMParser().parseFromString(kmlContent, "text/xml");
    const geojson = kml(kmlObject as Document);

    const originalFeatures = geojson.features;

    let features: Feature[] = [];
    let fidCounter = 0; // Counter for unique FID

    originalFeatures.forEach((originalFeature) => {
        if (originalFeature.geometry) {
            if (originalFeature.geometry.type === "GeometryCollection") {
                const extractedGeometries = extractGeometries(
                    originalFeature.geometry
                );

                extractedGeometries.forEach((geometry) => {
                    if (isSpecificGeometry(geometry)) {
                        const newFeature: Feature = {
                            type: "Feature",
                            properties: {
                                ...(originalFeature.properties || {}), // if properties is null, use an empty object
                                FID: fidCounter++,
                            },
                            geometry,
                        };
                        features.push(newFeature);
                    }
                });
            } else if (isSpecificGeometry(originalFeature.geometry)) {
                if (originalFeature.properties) {
                    originalFeature.properties.FID = fidCounter++;
                } else {
                    originalFeature.properties = { FID: fidCounter++ };
                }
                features.push(originalFeature as Feature);
            }
        }
    });

    const newFeatureCollection = featureCollection(features);

    return newFeatureCollection;
};

function SelectRegion(props: {
    currentStep: number;
    geo: Function;
    zone: Zone;
    updateZoneData: Function;
    updateRadioCheck: Function;
    getupdatedzoneData: Function;
    getremovedZoneVal: Function;
    getZipFile: Function;
}) {
    const [radiocheck, setradiocheck] = useState("");
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);
    const [zonedata, setZonedata] = useState<Zone[]>([]);
    const [removedzoneval, setremovedZoneval] = useState("");
    const optionsList = [
        "Select",
        "MPO",
        "Counties",
        "Cities",
        "ZIP",
        "States",
        "TAZ",
    ];
    let [showAlert, setShowAlert] = useState(false);
    let [errorMessage, setErrorMessage] = useState("");

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };

    const setSelectedThenCloseDropdown = (index: any) => {
        setradiocheck("Geographies");
        props.updateRadioCheck("Geographies");
        setIsOptionsOpen(false);
        props.geo(optionsList[index]);
        // Clear all selected zone once geaography changes
        if (selectedOption !== index) {
            let emptyZone: Zone[] = [];
            setZonedata(emptyZone);
            props.updateZoneData(emptyZone);
            props.getupdatedzoneData(emptyZone);
        }
        setSelectedOption(index);
    };
    const handleKeyDown = (index: any) => (e: any) => {
        switch (e.key) {
            case " ":
            case "SpaceBar":
            case "Enter":
                e.preventDefault();
                setSelectedThenCloseDropdown(index);
                break;
            default:
                break;
        }
    };
    const handleListKeyDown = (e: any) => {
        switch (e.key) {
            case "ArrowUp":
                e.preventDefault();
                setSelectedThenCloseDropdown(
                    selectedOption - 1 >= 0
                        ? selectedOption - 1
                        : optionsList.length - 1
                );
                break;

            case "ArrowDown":
                e.preventDefault();
                setSelectedThenCloseDropdown(
                    selectedOption == optionsList.length - 1
                        ? 0
                        : selectedOption + 1
                );
                break;

            default:
                break;
        }
    };
    const loadAndClose = () => {
        setShowAlert(false);
    };
    useEffect(() => {
        if (
            props.zone.zoneType === "Upload" &&
            props.zone.uploadarr != undefined
        ) {
            setZonedata(props.zone.uploadarr);
            props.updateZoneData(props.zone.uploadarr);
            props.getupdatedzoneData(props.zone.uploadarr);
        } else {
            let arrayzones = zonedata;
            let zonedataexists = () => {
                for (let i = 0; i < arrayzones.length; i++) {
                    if (
                        arrayzones[i].zoneType === props.zone.zoneType &&
                        arrayzones[i].id === props.zone.id
                    ) {
                        return true;
                    }
                }
                return false;
            };
            if (zonedataexists()) {
                arrayzones.map((arrayzonevals) => {
                    if (
                        arrayzonevals.zoneType === props.zone.zoneType &&
                        arrayzonevals.id === props.zone.id
                    ) {
                        arrayzonevals.tazcount = Number(props.zone.tazcount);
                        // arrayzonevals.tazintersects = Number(props.zone.tazintersects);
                        if (props.zone.name === "") {
                            arrayzonevals.name = props.zone.id;
                        } else {
                            arrayzonevals.name = props.zone.name;
                        }
                    }
                });
            } else {
                arrayzones.unshift(props.zone);
            }

            arrayzones = arrayzones.filter(
                (arrayzone) => typeof arrayzone.id !== "undefined"
            );
            //  arrayzones = arrayzones.filter((ele, ind) => ind === arrayzones.findIndex(elem => elem.id === ele.id && elem.name === ele.name))
            setZonedata(arrayzones);

            props.updateZoneData(arrayzones);
            props.getupdatedzoneData(arrayzones);
        }
    }, [props.zone]);

    const handleChange = (event: any) => {
        // setradiocheck(event.target.value)
        let emptyZone: Zone[] = [];
        setZonedata(emptyZone);
        props.updateZoneData(emptyZone);
        props.getupdatedzoneData(emptyZone);
        if (event.target.value === "Geographies") {
            setradiocheck(event.target.value);
            props.updateRadioCheck(event.target.value);
        }
        if (event.target.value === "Draw") {
            props.geo(optionsList[0]);
            setSelectedOption(0);
            setradiocheck(event.target.value);
            props.updateRadioCheck(event.target.value);
        }
        if (event.target.value === "Upload") {
            props.geo(optionsList[0]);
            setSelectedOption(0);
            setradiocheck(event.target.value);
            props.updateRadioCheck(event.target.value);
        }
    };

    if (props.currentStep !== 2) {
        return null;
    }

    //Remove zone from list
    const removeZone = (index: number) => {
        let arrayzones = zonedata.map((data) => data);
        if (!(undefined === arrayzones[index].id)) {
            setremovedZoneval(arrayzones[index].id);
            props.getremovedZoneVal(arrayzones[index].id);
        }
        arrayzones.splice(index, 1);
        setZonedata(arrayzones);

        props.updateZoneData(arrayzones);
        props.getupdatedzoneData(arrayzones);
        if (radiocheck == "Upload") {
            props.getZipFile(
                true,
                true
            );
        }

    };

    function reprojectGeometry(
        geometry: Polygon | MultiPolygon,
        originalProjection: string,
        targetProjection: string
    ): Polygon | MultiPolygon {
        const reprojectCoord = (coord: number[]): [number, number] => {
            const [x, y] = proj4(originalProjection, targetProjection, [
                coord[0],
                coord[1],
            ]);
            return [x, y];
        };

        const reprojectCoords = (coords: number[][]): [number, number][] =>
            coords.map(reprojectCoord);

        if (geometry.type === "Polygon") {
            const newCoordinates = geometry.coordinates.map(reprojectCoords);
            return {
                ...geometry,
                coordinates: newCoordinates,
            };
        } else if (geometry.type === "MultiPolygon") {
            const newCoordinates = geometry.coordinates.map((polygon) =>
                polygon.map(reprojectCoords)
            );
            return {
                ...geometry,
                coordinates: newCoordinates,
            };
        } else {
            throw new Error("Invalid geometry type");
        }
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setradiocheck("Upload");
        props.updateRadioCheck("Upload");
        props.geo(optionsList[0]);
        setSelectedOption(0);
        if (e.target.files) {
            //process file and draw on map
            console.log(e.target.files[0]);
            const file = e.target.files[0];
            const reader = new FileReader();
            const fileType = file.name.split(".").pop();

            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            let featureCollection: { features: any; type?: string };
            reader.onload = async () => {
                const binaryStr = reader.result;

                if (fileType === "zip" && binaryStr != null) {
                    JSZip.loadAsync(binaryStr)
                        .then((zip) => {
                            const fileNames = Object.keys(zip.files);
                            const shpFiles = fileNames.filter((fileName) =>
                                /\.shp$/i.test(fileName)
                            );
                            const dbfFiles = fileNames.filter((fileName) =>
                                /\.dbf$/i.test(fileName)
                            );

                            if (
                                shpFiles.length === 1 &&
                                dbfFiles.length === 1
                            ) {
                                const shpPromise = zip
                                    .file(/\.shp$/i)[0]
                                    .async("arraybuffer");
                                const dbfPromise = zip
                                    .file(/\.dbf$/i)[0]
                                    .async("arraybuffer");
                                // const cpgPromise = zip
                                //     .file(/\.cpg$/i)[0]
                                //     .async("arraybuffer");
                                const prjFiles = fileNames.filter((fileName) =>
                                    /\.prj$/i.test(fileName)
                                );
                                let prjPromise;
                                let promiseArr = [shpPromise, dbfPromise];
                                if (prjFiles && prjFiles.length === 1) {
                                    prjPromise = zip
                                        .file(/\.prj$/i)[0]
                                        .async("arraybuffer");
                                    promiseArr.push(prjPromise);
                                }

                                Promise.all(promiseArr) //remove cpgPromise
                                    .then(
                                        ([
                                            shpArrayBuffer,
                                            dbfArrayBuffer,
                                            prjArrayBuffer,
                                        ]) => {
                                            const decoder = new TextDecoder();

                                            //const features = shp.parseShp(shpArrayBuffer,dbfString);
                                            const dbf = new Uint8Array(
                                                dbfArrayBuffer
                                            );
                                            const dbfString =
                                                decoder.decode(dbf);
                                            let features /*: geojsongeom[]*/ =
                                                shp.parseShp(
                                                    shpArrayBuffer,
                                                    dbfString
                                                );
                                            // const properties = shp.parseDbf(
                                            //     dbfArrayBuffer,
                                            //     shpArrayBuffer               //properties have come out using this.
                                            // );
                                            const properties: GeoJSON.GeoJsonProperties[] =
                                                shp.parseDbf(
                                                    dbfArrayBuffer,
                                                    shpArrayBuffer
                                                );
                                            let n_features: GeoJSON.Feature<
                                                AnyGeometry,
                                                GeoJSON.GeoJsonProperties
                                            >[] = features.map(
                                                (geometry, i) => {
                                                    let propertiesWithFID = {
                                                        ...properties[i],
                                                        FID: i,
                                                    };
                                                    return {
                                                        type: "Feature",
                                                        geometry: geometry,
                                                        properties:
                                                            propertiesWithFID,
                                                    };
                                                }
                                            );

                                            if (prjArrayBuffer) {
                                                const prjString =
                                                    new TextDecoder().decode(
                                                        prjArrayBuffer
                                                    );
                                                proj4.defs(
                                                    "custom_projection",
                                                    prjString
                                                );

                                                const projectionDef =
                                                    proj4.defs(
                                                        "custom_projection"
                                                    );
                                                const usNatAtlasEqualArea =
                                                    proj4.Proj(prjString);

                                                // const mercator = proj4.Proj('EPSG:3857');
                                                const US_National_Atlas_Equal_Area = `+proj=laea +lat_0=45 +lon_0=-100 +x_0=0 +y_0=0 +a=6370997 +b=6370997 +units=m +no_defs`;
                                                const WGS84 = `+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs`;
                                                proj4.defs(
                                                    "US_National_Atlas_Equal_Area",
                                                    US_National_Atlas_Equal_Area
                                                );
                                                proj4.defs("WGS84", WGS84);

                                                if (
                                                    projectionDef &&
                                                    projectionDef.datumCode !==
                                                    "wgs84"
                                                ) {
                                                    const originalProjection =
                                                        "custom_projection";
                                                    const mercProjection =
                                                        "EPSG:3857";
                                                    const wgs84Projection =
                                                        "WGS84";

                                                    //project org to merc
                                                    n_features = n_features.map(
                                                        (feature) => {
                                                            const geometry =
                                                                feature.geometry;

                                                            if (
                                                                geometry.type ===
                                                                "Polygon" ||
                                                                geometry.type ===
                                                                "MultiPolygon"
                                                            ) {
                                                                return {
                                                                    type: "Feature",
                                                                    geometry:
                                                                        reprojectGeometry(
                                                                            geometry,
                                                                            originalProjection,
                                                                            mercProjection
                                                                        ),
                                                                    properties:
                                                                        feature.properties,
                                                                };
                                                            } else {
                                                                // If it's not a Polygon or MultiPolygon, return the feature as is.
                                                                return feature;
                                                            }
                                                        }
                                                    );
                                                    //project merc to wgs84
                                                    n_features = n_features.map(
                                                        (feature) => {
                                                            const geometry =
                                                                feature.geometry;

                                                            if (
                                                                geometry.type ===
                                                                "Polygon" ||
                                                                geometry.type ===
                                                                "MultiPolygon"
                                                            ) {
                                                                return {
                                                                    type: "Feature",
                                                                    geometry:
                                                                        reprojectGeometry(
                                                                            geometry,
                                                                            mercProjection,
                                                                            wgs84Projection
                                                                        ),
                                                                    properties:
                                                                        feature.properties,
                                                                };
                                                            } else {
                                                                // If it's not a Polygon or MultiPolygon, return the feature as is.
                                                                return feature;
                                                            }
                                                        }
                                                    );

                                                    featureCollection = {
                                                        type: "FeatureCollection",
                                                        features: n_features,
                                                    };
                                                } else {
                                                    featureCollection = {
                                                        type: "FeatureCollection",

                                                        features: features.map(
                                                            (geometry, i) => ({
                                                                type: "Feature",
                                                                geometry,
                                                                properties:
                                                                    properties[
                                                                    i
                                                                    ],
                                                            })
                                                        ),
                                                    };
                                                }
                                            } else {
                                                featureCollection = {
                                                    type: "FeatureCollection",

                                                    features: features.map(
                                                        (geometry, i) => ({
                                                            type: "Feature",
                                                            geometry,
                                                            properties:
                                                                properties[i],
                                                        })
                                                    ),
                                                };
                                            }
                                            if (
                                                featureCollection.features.some(
                                                    (feature: {
                                                        geometry: {
                                                            type: string;
                                                        };
                                                    }) =>
                                                        feature.geometry
                                                            .type !==
                                                        "Polygon" &&
                                                        feature.geometry
                                                            .type !==
                                                        "MultiPolygon"
                                                )
                                            ) {
                                                // Return default FeatureCollection if any of the geometries is not a Polygon
                                                setErrorMessage(
                                                    `Upload Error :: Shape Contains invalid geometry${featureCollection.features[0].geometry.type}`
                                                );
                                                setShowAlert(true);
                                                return;
                                            }

                                            props.getZipFile(
                                                file,
                                                featureCollection
                                            );

                                        }
                                    )
                                    .catch((error) => {
                                        console.log(
                                            "error loading shapefile",
                                            error
                                        );
                                    });
                            } else {
                                setErrorMessage(
                                    `Upload Error :: Zip contains invalid data or multiple shape files ${fileNames.join(
                                        ", "
                                    )}`
                                );
                                setShowAlert(true);
                            }
                        })
                        .catch((error) => {
                            console.log("error loading zip file", error);
                        });
                } else {
                    if (
                        fileType?.toString().toLocaleLowerCase() === "kmz" &&
                        binaryStr != null
                    ) {
                        let kmlFile: string | JSZip.JSZipObject | undefined;
                        JSZip.loadAsync(file)
                            .then(async (KMZ) => {
                                kmlFile = Object.values(KMZ.files).find(
                                    (file) =>
                                        file.name.toLowerCase().endsWith(".kml")
                                );
                                if (!kmlFile) {
                                    console.error("No KML file found in KMZ");
                                    return;
                                }

                                try {
                                    const textContent = await kmlFile.async(
                                        "text"
                                    );

                                    const geojson_kmz =
                                        convertKMLToGeoJSON(textContent);
                                    props.getZipFile(file, geojson_kmz);

                                    console.log("geojson:", geojson_kmz);
                                } catch (error) {
                                    console.error("Error reading file:", error);
                                }
                            })
                            .catch((error) => {
                                console.log("error loading KMZ", error);
                            });
                    } else if (
                        fileType?.toString().toLocaleLowerCase() === "kml"
                    ) {
                        try {
                            const textContent = await file.text();
                            const geojson_kml =
                                convertKMLToGeoJSON(textContent);
                            props.getZipFile(file, geojson_kml);
                            // const geojson = combine(features);
                            console.log("geojson:", geojson_kml);
                        } catch (error) {
                            console.error("Error reading KML file:", error);
                        }
                    } else {
                        console.error(
                            "Invalid file type. Only KML and KMZ files are supported."
                        );
                    }
                }
            };

            if (fileType === "zip") {
                reader.readAsArrayBuffer(file);
            } else {
                reader.readAsText(file);
            }
            e.target.value = "";
        }
    };

    return (
        <div className="sidebar p-5">
            <div className="p-3 rounded-3 text-light bg-normalprimary d-flex align-items-center shadow">
                <div style={{ width: "23em" }}>
                    <h2 className="fs-5 fw-normal">Select Region</h2>
                    <p className="mt-4">
                        Select your region of interest. You can use preset
                        geographies or create your own. Your selection will help
                        determine the number of TAZ(s) needed.
                    </p>
                    <form>
                        <Accordion flush className="border border-lightprimary">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="bg-primary">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="Geographies"
                                        id="r11"
                                        value="Geographies"
                                        checked={radiocheck === "Geographies"}
                                        onChange={handleChange}
                                    />
                                    <span
                                        className="px-2"
                                        style={{ color: "white" }}
                                    >
                                        Geographies
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="dropdown-container">
                                        <div className="d-grid">
                                            <button
                                                type="button"
                                                id="options-toggle"
                                                aria-haspopup="listbox"
                                                aria-expanded={isOptionsOpen}
                                                className={
                                                    "btn btn-darkprimary dropdown-toggle"
                                                }
                                                onClick={toggleOptions}
                                                onKeyDown={handleListKeyDown}
                                            >
                                                {optionsList[selectedOption]}
                                            </button>
                                        </div>

                                        <ul
                                            className={`options ${isOptionsOpen ? "show" : ""
                                                }`}
                                            role="listbox"
                                            aria-activedescendant={
                                                optionsList[selectedOption]
                                            }
                                            tabIndex={-1}
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {optionsList.map(
                                                (option, index) => (
                                                    <li
                                                        id={option}
                                                        role="option"
                                                        aria-selected={
                                                            selectedOption ===
                                                            index
                                                        }
                                                        tabIndex={0}
                                                        onKeyDown={handleKeyDown(
                                                            index
                                                        )}
                                                        onClick={() => {
                                                            setSelectedThenCloseDropdown(
                                                                index
                                                            );
                                                        }}
                                                    >
                                                        {option}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <input
                                        type="radio"
                                        id="r12"
                                        className="form-check-input"
                                        name="Draw"
                                        value="Draw"
                                        checked={radiocheck === "Draw"}
                                        onChange={handleChange}
                                    />
                                    <span
                                        className="px-2"
                                        style={{ color: "white" }}
                                    >
                                        Draw
                                    </span>
                                    {/* <FontAwesomeIcon icon={icon({name: 'circle', style: 'regular'})} /> <span className='px-2'>Draw</span> */}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div style={{ padding: "8px" }}>Click on the map to start drawing and double
                                        click to finish</div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    {/* <FontAwesomeIcon icon={icon({name: 'circle', style: 'regular'})} /> */}
                                    <input
                                        type="radio"
                                        id="r13"
                                        className="form-check-input"
                                        name="Upload"
                                        value="Upload"
                                        checked={radiocheck === "Upload"}
                                        onChange={handleChange}
                                    />
                                    <span
                                        className="px-2"
                                        style={{ color: "white", padding: "8px" }}
                                    >
                                        Upload
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div style={{ padding: "8px" }}>Upload Shape file</div>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        accept=".zip,.rar,.7zip,.kml,.kmz"
                                        onChange={handleFileChange}
                                    ></input>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </form>
                    <table
                        className="t-region-table-auto t-region-table-inside-auto mt-3 w-100"
                        style={{ height: "100px" }}
                    >
                        <thead className="table-dark w-100">
                            <tr
                                className="t-region-table-table1-header-auto t-region-table-table1-header-inside-auto"
                                style={{ width: "auto" }}
                            >
                                <th className="t-region-table-table1-cell01-auto t-region-table-table1-header-col-head01-inside-auto t-region-table-table1-cell01-close t-region-table-table1-cell01-box-color t-region-table-table1-header-col-head01-auto-background">
                                    #
                                </th>
                                <th className="t-region-table-table1-cell02-auto t-region-table-table1-header-col-head02-inside-auto t-region-table-table1-cell02-name t-region-table-table1-cell02-name-inside-auto t-region-table-table1-cell02-color t-region-table-table1-header-col-head02-auto-background">
                                    Name
                                </th>
                                <th className="t-region-table-table1-cell03-auto t-region-table-table1-header-col-head03-inside-auto t-region-table-table1-cell03-name t-region-table-table1-cell03-name-inside-auto t-region-table-table1-cell03-color t-region-table-table1-header-col-head03-auto-background">
                                    Zone Type
                                </th>
                            </tr>
                        </thead>
                        <tbody className="border border-lightprimary w-100 overflow-auto">
                            {zonedata.length > 0 &&
                                zonedata.map((zone, index) => (
                                    <tr
                                        style={{ width: "auto" }}
                                        className="t-region-table-table1-header-auto t-region-table-table1-header-inside-auto"
                                        key={zone.id}
                                    >
                                        <td className="border border-lightprimary t-region-table-table1-cell01-auto t-region-table-table1-header-col-head01-inside-auto t-region-table-table1-cell01-close t-region-table-table1-cell01-box-color">
                                            <button
                                                style={{
                                                    background: "#292929",
                                                    color: "#e6c560",
                                                    padding: "0px",
                                                    border: "none",
                                                }}
                                                onClick={() =>
                                                    removeZone(index)
                                                }
                                            >
                                                <FaWindowClose></FaWindowClose>
                                            </button>
                                        </td>
                                        <td className="border border-lightprimary t-region-table-table1-cell02-auto t-region-table-table1-header-col-head02-inside-auto t-region-table-table1-cell02-background t-region-table-table1-cell02-name t-region-table-table1-cell02-name-inside-auto t-region-table-table1-cell02-color">
                                            {zone.name}
                                        </td>
                                        <td className="border border-lightprimary t-region-table-table1-cell03-auto t-region-table-table1-header-col-head03-inside-auto t-region-table-table1-cell03-background t-region-table-table1-cell03-name t-region-table-table1-cell03-name-inside-auto t-region-table-table1-cell03-color">
                                            {zone.zoneType}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                show={showAlert}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={loadAndClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SelectRegion;
