import React from 'react'

function Stepper(props:{
  currentStep: number;
 }) {

  let stepPercentage = '0%';

  if (props.currentStep === 1) {
    stepPercentage = '0%';
  } else if (props.currentStep === 2) {
    stepPercentage = '25%';
  } else if (props.currentStep === 3) {
    stepPercentage = '75%';
  }else if (props.currentStep === 4) {
    stepPercentage = '100%';
  } else {
    stepPercentage = '0%';
  }
  return (
    <div className="p-2 flex-grow-1 d-flex align-items-center">
    <div className='text-light px-2'>Step {props.currentStep} of 4</div>
      <div className="progress bg-lightprimary rounded-0 flex-fill m-3" style={{height:'4px'}}>
          <div className="progress-bar bg-altprimary rounded-0" 
              role="progressbar" aria-label="Basic example" 
              aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} 
              style={{width: stepPercentage}}>
          </div>
      </div>
    </div>
  )
}

export default Stepper