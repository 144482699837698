import React, { useEffect, useState } from "react";
import { TrainingAddons, ProposalBaseData } from "../../../../models/redux-models";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getFormattedPrice } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import { setTraining } from "../../../../store/proposal-slice";

function Training(props: { packageType: String; trainingTotal: Function }) {
    let [trainings, setTrainings] = useState<TrainingAddons[]>([]);
    let [totTrainingCost, setTotTrainingCost] = useState(0);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const trainingAddons = useSelector((state: RootState) => state.master.trainingAddons);
    const dispatch = useDispatch();

    useEffect(() => {
        setTrainings(trainingAddons);
        setCheckedState(new Array(trainingAddons.length).fill(false));
        setTotTrainingCost(0);
    }, [props.packageType]);

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        let trainingDetails: ProposalBaseData[] = [];
        const totalPrice = updatedCheckedState.reduce(
            (sum, currentState, index) => {
                if (currentState === true) {
                    let trainingDetail = {
                        name: trainings[index].name,
                        price: trainings[index].price,
                    };
                    trainingDetails.push(trainingDetail);
                    return sum + trainings[index].price;
                }
                return sum;
            },
            0
        );

        setTotTrainingCost(totalPrice);
        props.trainingTotal(totalPrice);
        dispatch(setTraining(trainingDetails));
    };

    return (
        <div style={{ borderBottom: '0.5px solid white', paddingBottom: '5px' }}>
            <h4 className="fs-6 fw-bold">Training</h4>
            {totTrainingCost > 0 ? (
                <div
                    className="right-section d-flex align-items-end flex-column fw-bold"
                    style={{ marginTop: "-20px" }}
                >
                    {getFormattedPrice(totTrainingCost)}
                </div>
            ) : (
                <></>
            )}
            <>
                {trainings &&
                    trainings.map((training, index) => (
                        <div className="form-check" key={index}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={training.name}
                                id={`train-check-${index}`}
                                checked={checkedState[index]}
                                onChange={() => handleOnChange(index)}
                            />
                            <label
                                className="form-check-label text-light fw-normal"
                                htmlFor={`train-check-${index}`}
                            >
                                {training.name}
                            </label>
                            {checkedState[index] && (
                                <div
                                    className="right-section d-flex align-items-end flex-column"
                                    style={{ marginTop: "-20px" }}
                                >
                                    {getFormattedPrice(training.price)}
                                </div>
                            )}
                        </div>
                    ))}
            </>
        </div>
    );
}

export default Training;
