import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="loader-container d-flex flex-column">
      <div className="spinner"/>
      <label className="h5" style={{color: '#FFDB6B'}}>Please wait...</label>
    </div>
  );
}