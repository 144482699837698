import React, { useEffect, useState } from "react";
import { getFormattedPrice } from "../../../../utils/utils";
import { FaWindowClose, FaPlusCircle } from "react-icons/fa";
import { ProposalBaseData } from "../../../../models/redux-models";
import { useDispatch } from "react-redux";
import { setCustomAddon } from "../../../../store/proposal-slice";

function CustomAddons(props: { custTotal: Function; packageType: String }) {
    const [custAddonTotalPrice, setCustAddonTotalPrice] = useState(0);
    const [custAddons, setCustAddons] = useState<ProposalBaseData[]>([]);
    const dispatch = useDispatch();

    const addCustomAddon = () => {
        let newAddOn: ProposalBaseData = { name: "New Addon", price: 0 };
        let addOns = custAddons.map((data) => data);
        addOns.push(newAddOn);
        setCustAddons(addOns);
    };

    const removeCustomAddon = (index: number) => {
        let addOns = custAddons.map((data) => data);
        addOns.splice(index, 1);
        setCustAddons(addOns);
        const addOnTotalPrice = addOns.reduce((sum, currentObj) => {
            return sum + currentObj.price;
        }, 0);
        setCustAddonTotalPrice(addOnTotalPrice);
        props.custTotal(addOnTotalPrice);
        dispatch(setCustomAddon(addOns));
    };

    const updateAddonName = (
        e: React.ChangeEvent<HTMLInputElement>,
        position: number
    ) => {
        let addOns = custAddons.map((data, index) => {
            if (index === position) {
                // data.name = e.currentTarget.value;
                return { ...data, name: e.currentTarget.value };
            }
            return data;
        });
        setCustAddons(addOns);
    };

    const updateAddonPrice = (val: number, position: number) => {
        let addOns = custAddons.map((data, index) => {
            if (index === position) {
                return { ...data, price: val };
            }
            return data;
        });
        setCustAddons(addOns);
        const addOnTotalPrice = addOns.reduce((sum, currentObj) => {
            return sum + currentObj.price;
        }, 0);
        setCustAddonTotalPrice(addOnTotalPrice);
        props.custTotal(addOnTotalPrice);
        dispatch(setCustomAddon(addOns));
    };

    useEffect(() => {
        setCustAddons([]);
        setCustAddonTotalPrice(0);
    }, [props.packageType]);

    return (
        <div style={{
            borderBottom: '0.5px solid white', marginTop: "5px",
            marginBottom: "2px", paddingTop: "10px", paddingBottom: "10px"
        }}>
            <h4 className="fs-6 fw-bold">Custom Add-Ons</h4>
            {custAddonTotalPrice > 0 ? (
                <div
                    className="right-section d-flex align-items-end flex-column fw-bold"
                    style={{ marginTop: "-20px" }}
                >
                    {getFormattedPrice(custAddonTotalPrice)}
                </div>
            ) : (
                <></>
            )}
            <div className="d-flex flex-row">
                <FaPlusCircle onClick={() => addCustomAddon()} />
                <h4 className="fs-6 text-light fw-normal ms-3"> Add item</h4>
            </div>
            {custAddons.length > 0 &&
                custAddons.map((addon, index) => (
                    <div className="container">
                        <div className="row">
                            <div className="d-flex align-content-center p-1">
                                <div className="mt-1">
                                    <FaWindowClose
                                        className="fa-lg"
                                        onClick={() => removeCustomAddon(index)}
                                    />
                                </div>
                                <div className="col ms-3">
                                    <div className="input-group">
                                        <input
                                            className="form-control bg-darkprimary text-light"
                                            type="text"
                                            required
                                            value={addon.name}
                                            onChange={(e) =>
                                                updateAddonName(e, index)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col d-flex justify-content-end">
                                    <div className="input-group w-25 mb-3">
                                        <input
                                            className=" form-control bg-darkprimary text-light ms-auto ms-3"
                                            type="number"
                                            min="1"
                                            value={addon.price}
                                            onChange={(e) => {
                                                let val = parseInt(
                                                    e.target.value,
                                                    10
                                                );
                                                if (!isNaN(val) && val >= 0) {
                                                    updateAddonPrice(
                                                        val,
                                                        index
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default CustomAddons;
