import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapForms from "./mapforms";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import bbox from "@turf/bbox";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import proj4 from "proj4";

import {
    setAddOn,
    setFactor,
    setMasterAnalysis,
    setMasterMode,
    setModeAnalysisMapping,
    setPackageAdditive,
    setSubscription,
    setSubscriptionMapping,
    setDecisionRule,
    setSubscriptionPackageMapping,
    setSubscriptionModuleMapping,
    setSubscriptionAddonMapping,
    setSubscriptionModuleDisableMapping,
    setModules,
    setModeAddons,
    setMetricAddons,
    setTrainingAddons,
    setSupportAddons,
    setServiceAddons,
    setSpecialAccessAddons,
} from "../../store/master-slice";
import { updateMap, setLoader } from "../../store/common-slice";
import { useSelector, useDispatch } from "react-redux";
import { getMasterData, getBasePrice } from "../../services/api";

mapboxgl.accessToken =
    "pk.eyJ1IjoiamFkb25hbGQ3MiIsImEiOiJjbGIzZ3pwOTgwZWE2M3BuM2RiZmJoNzg5In0.jHzCWs3yWq2hNe4Mr1bJVw";

var zip3857 =
    `${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/gwc/service/wmts?layer=stl-geopricing:zip3857&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&FORMAT=application/vnd.mapbox-vector-tile&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}`;
var county3857 =
    `${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/gwc/service/wmts?layer=stl-geopricing:county3857&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&FORMAT=application/vnd.mapbox-vector-tile&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}`;
var cbsa3857 =
    `${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/gwc/service/wmts?layer=stl-geopricing:zone_us_mpo&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&FORMAT=application/vnd.mapbox-vector-tile&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}`;
var taz3857 =
    `${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/gwc/service/wmts?layer=stl-geopricing:taz3857&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&FORMAT=application/vnd.mapbox-vector-tile&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}`;
var cities3857 =
    `${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/gwc/service/wmts?layer=stl-geopricing:cities3857&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&FORMAT=application/vnd.mapbox-vector-tile&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}`;

var states3857 =
    `${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/gwc/service/wmts?layer=stl-geopricing:states_3857_&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&FORMAT=application/vnd.mapbox-vector-tile&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}`;

var tazpostURL =
    `${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/wfs?service=WFS&version=2.0.0&request=getfeature&typeNames=stl-geopricing:taz3857&outputformat=json`;

var tazURL = encodeURIComponent(`${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/wfs?service=WFS&version=1.0.0&request=getfeature&typeNames=stl-geopricing:taz3857&cql_filter`);
var geoURL = encodeURIComponent(`${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=stl-geopricing:`);

var tazpostURL_point =
    `${process.env.REACT_APP_GEO_SERVER_BASE_URL}/stl-geopricing/wfs?service=WFS&version=2.0.0&request=getfeature&typeNames=stl-geopricing:centroids3857&outputformat=json`;

export default function Map() {
    const mapContainer = useRef(null);
    const map = useRef(null);

    let [geo, setGeoLoc] = useState("");
    let [showTaz, setShowTaz] = useState("none");
    let [showAlert, setShowAlert] = useState(false);
    let [radioOption, setradioOption] = useState("");
    let [zonesList, setzonesList] = useState([]);
    let [selectedFeatures, setselectedFeatures] = useState([]);
    let [zonervalemoved, setZonevalRemoved] = useState("");
    let [showDrawPopUp, setShowDrawPopUp] = useState(false);
    let [shapeZip, setShapeZip] = useState();
    let [geoJSON, setgeoJSON] = useState();
    let [customlayerid, setCustomLayerId] = useState();
    const setGeoJson = (file, JSONval) => {
        setShapeZip(file);
        setgeoJSON(JSONval);
    };
    var getfeatureselected = [];
    let [tazsCount, settazsCount] = useState(0);

    const geoLocationval = (data) => {
        setGeoLoc(data);
    };
    const updateRadioCheck = (data) => {
        setradioOption(data);
    };
    const updateZonesList = (data) => {
        setzonesList(data);
    };
    const setZoneRemoved = (data) => {
        let deltevaldata = data;
        setZonevalRemoved(data);

        if (radioOption == "Geographies") {
            if (selectedFeatures.length > 0) {
                for (let i = 0; i <= selectedFeatures.length; i++) {
                    if (
                        selectedFeatures[i].properties.zone_id === deltevaldata
                    ) {
                        selectedFeatures.splice(i, 1);
                        const zonesids = selectedFeatures.map(
                            (zonefeature) => zonefeature.properties.zone_id
                        );
                        map.current.setFilter(geo.toString() + "-Highlighted", [
                            "in",
                            "zone_id",
                            ...zonesids,
                        ]);
                        break;
                    }
                }
            }
        } else if (radioOption == "Draw") {
            map.current._controls[2].delete(data);
        }
    };

    const token = useSelector((state) => state.auth.token);
    const mapImage = useSelector((state) => state.common.mapScreen);

    const dispatch = useDispatch();
    let [zoneval, setzoneval] = useState({});
    const getStep = async (step) => {
        if (step === 4) {
            const canvas = map.current.getCanvas();
            const mapData = canvas.toDataURL("image/png");
            dispatch(updateMap({ ...mapImage, map: mapData }));
        }
    };

    var getdrawfeatures = [];
    var layerid_name;
    var getUploadfeaturesSelected = [];

    proj4.defs([
        [
            "EPSG:3857",
            "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs",
        ],
        [
            "WGS84",
            "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees",
        ],
    ]);

    const handleClose = () => setShowDrawPopUp(false);
    const updateZone = (val) => {
        setzoneval({ ...zoneval, name: val });
    };
    var EventHandled = false;
    var lightgreyColor = "rgb(211, 211, 211)";
    var yellowColor = "rgb(240, 207, 127)";
    useEffect(() => {
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            // Select which mapbox-gl-draw control buttons to add to the map.
            controls: {
                polygon: true,
                trash: true,
            },
            userProperties: true,

            styles: [
                {
                    id: "gl-draw-polygon-fill-inactive",
                    type: "fill",
                    filter: [
                        "all",
                        ["==", "active", "false"],
                        ["==", "$type", "Polygon"],
                        ["!=", "mode", "static"],
                    ],
                    paint: {
                        "fill-color": [
                            "case",
                            ["==", ["get", "user_class_id"], 1],
                            "#F0CF7F",
                            ["==", ["get", "user_class_id"], 2],
                            "#F0CF7F",
                            "#F0CF7F",
                        ],
                        "fill-outline-color": "#F0CF7F",
                        "fill-opacity": 0.5,
                    },
                },
                {
                    id: "gl-draw-polygon-fill-active",
                    type: "fill",
                    filter: [
                        "all",
                        ["==", "active", "true"],
                        ["==", "$type", "Polygon"],
                    ],
                    paint: {
                        "fill-color": "#F0CF7F",
                        "fill-outline-color": "#F0CF7F",
                        "fill-opacity": 0.1,
                    },
                },
                {
                    id: "gl-draw-polygon-midpoint",
                    type: "circle",
                    filter: [
                        "all",
                        ["==", "$type", "Point"],
                        ["==", "meta", "midpoint"],
                    ],
                    paint: {
                        "circle-radius": 3,
                        "circle-color": "#fbb03b",
                    },
                },
                {
                    id: "gl-draw-polygon-stroke-inactive",
                    type: "line",
                    filter: [
                        "all",
                        ["==", "active", "false"],
                        ["==", "$type", "Polygon"],
                        ["!=", "mode", "static"],
                    ],
                    layout: {
                        "line-cap": "round",
                        "line-join": "round",
                    },
                    paint: {
                        "line-color": "#F0CF7F",
                        "line-width": 1,
                    },
                },
                {
                    id: "gl-draw-polygon-stroke-active",
                    type: "line",
                    filter: [
                        "all",
                        ["==", "active", "true"],
                        ["==", "$type", "Polygon"],
                    ],
                    layout: {
                        "line-cap": "round",
                        "line-join": "round",
                    },
                    paint: {
                        "line-color": "#F0CF7F",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2,
                    },
                },
                {
                    id: "gl-draw-line-inactive",
                    type: "line",
                    filter: [
                        "all",
                        ["==", "active", "false"],
                        ["==", "$type", "LineString"],
                        ["!=", "mode", "static"],
                    ],
                    layout: {
                        "line-cap": "round",
                        "line-join": "round",
                    },
                    paint: {
                        "line-color": "#F0CF7F",
                        "line-width": 2,
                    },
                },
                {
                    id: "gl-draw-line-active",
                    type: "line",
                    filter: [
                        "all",
                        ["==", "$type", "LineString"],
                        ["==", "active", "true"],
                    ],
                    layout: {
                        "line-cap": "round",
                        "line-join": "round",
                    },
                    paint: {
                        "line-color": "#F0CF7F",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2,
                    },
                },
                {
                    id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
                    type: "circle",
                    filter: [
                        "all",
                        ["==", "meta", "vertex"],
                        ["==", "$type", "Point"],
                        ["!=", "mode", "static"],
                    ],
                    paint: {
                        "circle-radius": 5,
                        "circle-color": "#fff",
                    },
                },
                {
                    id: "gl-draw-polygon-and-line-vertex-inactive",
                    type: "circle",
                    filter: [
                        "all",
                        ["==", "meta", "vertex"],
                        ["==", "$type", "Point"],
                        ["!=", "mode", "static"],
                    ],
                    paint: {
                        "circle-radius": 3,
                        "circle-color": "#fbb03b",
                    },
                },
                {
                    id: "gl-draw-point-point-stroke-inactive",
                    type: "circle",
                    filter: [
                        "all",
                        ["==", "active", "false"],
                        ["==", "$type", "Point"],
                        ["==", "meta", "feature"],
                        ["!=", "mode", "static"],
                    ],
                    paint: {
                        "circle-radius": 5,
                        "circle-opacity": 1,
                        "circle-color": "#fff",
                    },
                },
                {
                    id: "gl-draw-point-inactive",
                    type: "circle",
                    filter: [
                        "all",
                        ["==", "active", "false"],
                        ["==", "$type", "Point"],
                        ["==", "meta", "feature"],
                        ["!=", "mode", "static"],
                    ],
                    paint: {
                        "circle-radius": 3,
                        "circle-color": "#3bb2d0",
                    },
                },
                {
                    id: "gl-draw-point-stroke-active",
                    type: "circle",
                    filter: [
                        "all",
                        ["==", "$type", "Point"],
                        ["==", "active", "true"],
                        ["!=", "meta", "midpoint"],
                    ],
                    paint: {
                        "circle-radius": 7,
                        "circle-color": "#fff",
                    },
                },
                {
                    id: "gl-draw-point-active",
                    type: "circle",
                    filter: [
                        "all",
                        ["==", "$type", "Point"],
                        ["!=", "meta", "midpoint"],
                        ["==", "active", "true"],
                    ],
                    paint: {
                        "circle-radius": 5,
                        "circle-color": "#fbb03b",
                    },
                },
                {
                    id: "gl-draw-polygon-fill-static",
                    type: "fill",
                    filter: [
                        "all",
                        ["==", "mode", "static"],
                        ["==", "$type", "Polygon"],
                    ],
                    paint: {
                        "fill-color": "#F0CF7F",
                        "fill-outline-color": "#F0CF7F",
                        "fill-opacity": 0.1,
                    },
                },
                {
                    id: "gl-draw-polygon-stroke-static",
                    type: "line",
                    filter: [
                        "all",
                        ["==", "mode", "static"],
                        ["==", "$type", "Polygon"],
                    ],
                    layout: {
                        "line-cap": "round",
                        "line-join": "round",
                    },
                    paint: {
                        "line-color": "#F0CF7F",
                        "line-width": 2,
                    },
                },
                {
                    id: "gl-draw-line-static",
                    type: "line",
                    filter: [
                        "all",
                        ["==", "mode", "static"],
                        ["==", "$type", "LineString"],
                    ],
                    layout: {
                        "line-cap": "round",
                        "line-join": "round",
                    },
                    paint: {
                        "line-color": "#404040",
                        "line-width": 2,
                    },
                },
                {
                    id: "gl-draw-point-static",
                    type: "circle",
                    filter: [
                        "all",
                        ["==", "mode", "static"],
                        ["==", "$type", "Point"],
                    ],
                    paint: {
                        "circle-radius": 5,
                        "circle-color": "#404040",
                    },
                },
            ],
        });

        const CityLayer = {
            id: "Cities",
            type: "fill",
            source: { type: "vector", tiles: [cities3857] },
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            visible: false,
            "source-layer": "cities3857",
            paint: {
                "fill-outline-color": "#FFFFFF",
                "fill-color": "transparent",
                "fill-opacity": 1.0,
            },
        };
        const MPOLayer = {
            id: "MPO",
            type: "fill",
            source: { type: "vector", tiles: [cbsa3857] },
            layout: {
                // make layer invisible by default
                visibility: "none",
            },

            visible: false,
            "source-layer": "zone_us_mpo",
            paint: {
                "fill-outline-color": "#FFFFFF",
                "fill-color": "transparent",
                "fill-opacity": 1.0,
            },
        };
        const STATESLayer = {
            id: "States",
            type: "fill",
            source: { type: "vector", tiles: [states3857] },
            layout: {
                // make layer invisible by default
                visibility: "none",
            },

            visible: false,
            "source-layer": "states_3857_",
            paint: {
                "fill-outline-color": "#FFFFFF",
                "fill-color": "transparent",
                "fill-opacity": 1.0,
            },
        };

        const ZIPLayer = {
            id: "ZIP",
            type: "fill",
            // 'source': { 'type': 'geojson', 'data': ZIPData },
            source: { type: "vector", tiles: [zip3857] },
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            visible: false,
            "source-layer": "zip3857",
            paint: {
                "fill-outline-color": "#FFFFFF",
                "fill-color": "transparent",
                "fill-opacity": 1.0,
            },
        };
        const CountyLayer = {
            id: "Counties",
            type: "fill",
            source: { type: "vector", tiles: [county3857] },
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            visible: false,
            "source-layer": "county3857",
            paint: {
                "fill-outline-color": "#FFFFFF",
                "fill-color": "transparent",
                "fill-opacity": 1.0,
            },
        };

        const TAZLayer = {
            id: "TAZ",
            type: "fill",
            // 'source': { 'type': 'geojson', 'data': TAZData },
            source: { type: "vector", tiles: [taz3857] },
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            visible: false,
            "source-layer": "taz3857",
            paint: {
                "fill-outline-color": "#FFFFFF",
                "fill-color": "transparent",
                //"fill-color": "green",
                "fill-opacity": 1.0,
            },
        };
        /* will be filtered once we get the above one done*/

        const MPOHighlighted = {
            id: "MPO-Highlighted",
            type: "fill",
            source: "MPO",
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            "source-layer": "zone_us_mpo",

            paint: {
                "fill-outline-color": "#F0CF7F",
                "fill-color": "#F0CF7F",
                "fill-opacity": 0.5,
            },

            filter: ["in", "zone_id", ""],
        };

        const STATESHighlighted = {
            id: "States-Highlighted",
            type: "fill",
            source: "States",
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            "source-layer": "states_3857_",

            paint: {
                "fill-outline-color": "#F0CF7F",
                "fill-color": "#F0CF7F",
                "fill-opacity": 0.5,
            },

            filter: ["in", "zone_id", ""],
        };

        const CitiesHighlighted = {
            id: "Cities-Highlighted",
            type: "fill",
            source: "Cities",
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            "source-layer": "cities3857",
            paint: {
                "fill-outline-color": "#F0CF7F",
                "fill-color": "#F0CF7F",
                "fill-opacity": 0.5,
            },

            filter: ["in", "zone_id", ""],
        };
        const ZIPHighlighted = {
            id: "ZIP-Highlighted",
            type: "fill",
            source: "ZIP",
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            "source-layer": "zip3857",
            paint: {
                "fill-outline-color": "#F0CF7F",
                "fill-color": "#F0CF7F",
                "fill-opacity": 0.5,
            },

            filter: ["in", "zone_id", ""],
        };
        const CountiesHighlighted = {
            id: "Counties-Highlighted",
            type: "fill",
            source: "Counties",
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            "source-layer": "county3857",
            paint: {
                "fill-outline-color": "#F0CF7F",
                "fill-color": "#F0CF7F",
                "fill-opacity": 0.5,
            },
            filter: ["in", "zone_id", ""],
        };
        const TAZHighlighted = {
            id: "TAZ-Highlighted",
            type: "fill",
            source: "TAZ",
            layout: {
                // make layer invisible by default
                visibility: "none",
            },
            "source-layer": "taz3857",
            paint: {
                "fill-outline-color": "#F0CF7F",
                "fill-color": "#F0CF7F",
                "fill-opacity": 0.5,
            },
            filter: ["in", "zone_id", ""],
        };

        function handleMouseEnter(e) {
            map.current.setLayoutProperty("TAZ", "visibility", "none");

            // Additional logic can be added here.
        }
        const dropdownlist = [
            "select",
            "MPO",
            "Cities",
            "Counties",
            "ZIP",
            "States",
            "TAZ",
        ]; //not inculed TAZ
        let hoveredStateId = null;
        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                //style: "mapbox://styles/jadonald72/clbdohinu000814pmlla9de2w",
                style: "mapbox://styles/mapbox/satellite-streets-v12",
                center: ["-99.1714", "38.8513"],
                attributionControl: false,
                zoom: "4",
                projection: "mercator",
                preserveDrawingBuffer: true,
            });
            map.current.setStyle("mapbox://styles/mapbox/dark-v11");
            const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
            });

            map.current.on("getTAZCounts", async function (e) {
                try {
                    var featureBBox = [];
                    for (let i = 0; i <= e.jsonObj.features.length - 1; i++) {
                        const originalProperties =
                            e.jsonObj.features[i].properties;
                        const properties = {};
                        if (originalProperties) {
                            for (const [key, value] of Object.entries(
                                originalProperties
                            )) {
                                if (typeof value === "string") {
                                    properties[key.toLowerCase()] = value;
                                } else {
                                    properties[key.toLowerCase()] = value;
                                }
                            }
                        }
                        if (
                            e.jsonObj.features[i].geometry.coordinates.length >
                            1
                        ) {
                            for (
                                let j = 0;
                                j <=
                                e.jsonObj.features[i].geometry.coordinates
                                    .length -
                                1;
                                j++
                            ) {
                                featureBBox.push({
                                    property: properties,
                                    coord: e.jsonObj.features[i].geometry
                                        .coordinates[j],
                                });
                            }
                        } else {
                            featureBBox.push({
                                property: properties,
                                coord: e.jsonObj.features[i].geometry
                                    .coordinates,
                            });
                        }
                    }

                    const newfeatPromises = featureBBox.map(async (feature) => {
                        let querybuilder = [];
                        const secondProjection =
                            "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs";
                        const firstProjection =
                            "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees";
                        try {
                            feature["coord"][0].forEach((featureCoords) => {
                                const coord = proj4(
                                    firstProjection,
                                    secondProjection,
                                    featureCoords
                                );
                                const coord_str = coord[0] + " " + coord[1];
                                querybuilder.push(coord_str);
                            });

                            const querybuilder_str = querybuilder.join(",");
                            const myHeaders = new Headers();
                            myHeaders.append(
                                "Content-Type",
                                "application/x-www-form-urlencoded"
                            );
                            const urlencoded = new URLSearchParams();
                            urlencoded.append(
                                "CQL_FILTER",
                                "WITHIN(point_geom,POLYGON((" +
                                querybuilder_str +
                                ")))"
                            );
                            const requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: "follow",
                            };

                            const response = await fetch(
                                tazpostURL_point,
                                requestOptions
                            );
                            const data = await response.json();
                            let poly_datafeatures = [];

                            //TAZ point feature's data
                            if (data.features.length > 0) {
                                const geoids = data.features
                                    .filter(
                                        (feature) =>
                                            feature &&
                                            feature.properties &&
                                            feature.properties.geoid10 !==
                                            undefined &&
                                            feature.properties.geoid10 !== ""
                                    )
                                    .map(
                                        (feature) =>
                                            `'${feature.properties.geoid10}'`
                                    )
                                    .join(", ");

                                const queryfilter_poly = `cql_filter=geoid IN (${geoids})`;
                                const decodedQueryfilter_poly =
                                    decodeURIComponent(queryfilter_poly);
                                const requestOptions_poly = {
                                    method: "POST",
                                    headers: {
                                        "Content-Type":
                                            "application/x-www-form-urlencoded",
                                    },
                                    body: decodedQueryfilter_poly,
                                    redirect: "follow",
                                };
                                const polyResponse = await fetch(
                                    tazpostURL,
                                    requestOptions_poly
                                );
                                const polyData = await polyResponse.json();
                                if (polyData && polyData.features.length > 0) {
                                    poly_datafeatures.push(
                                        ...polyData.features
                                    );
                                }

                                return {
                                    id: feature["property"].fid,
                                    name: feature["property"].fid,
                                    zoneType: "Upload",
                                    tazcount: data.features.length,
                                    datafeatures: poly_datafeatures,
                                };
                            }
                        } catch (error) { }
                    });
                    dispatch(setLoader(true));
                    const results = await Promise.all(newfeatPromises);
                    const filteredResults = results.filter(
                        (result) =>
                            result &&
                            result.datafeatures &&
                            result.datafeatures !== undefined &&
                            result.datafeatures.length > 0
                    );

                    const featcollection = {
                        type: "FeatureCollection",
                        features: filteredResults.map((result) => ({
                            type: "Feature",
                            geometry: {
                                type: "MultiPolygon",
                                coordinates:
                                    result.datafeatures[0].geometry.coordinates,
                            },
                            properties: result.datafeatures[0].properties,
                        })),
                    };
                    dispatch(setLoader(false));
                    if (filteredResults.length > 0) {
                        filteredResults.forEach((result) => {
                            setzoneval({
                                id: result.fid,
                                name: result.fid,
                                zoneType: "Upload",
                                tazcount: result.tazcount,
                                datafeatures: featcollection,
                                uploadarr: filteredResults,
                            });
                        });
                    } else {
                        setzoneval((prevState) => ({
                            ...prevState,
                            id: "",
                            name: "",
                            zoneType: "Upload",
                            tazcount: 0,
                            datafeatures: {},
                            uploadarr: [],
                        }));
                    }
                } catch (error) {
                    console.error(error);
                    return;
                }
            });

            function waitForSourceDataLoaded(
                map,
                sourceId,
                eventName,
                geojsonSource
            ) {
                return new Promise((resolve) => {
                    const checkLoaded = () => {
                        if (
                            map.getSource(sourceId) &&
                            map.getSource(sourceId).loaded()
                        ) {
                            map.off("sourcedata", checkLoaded);
                            resolve();
                            map.fire(eventName, { jsonObj: geojsonSource });
                        }
                    };

                    map.on("sourcedata", checkLoaded);
                    checkLoaded();
                });
            }

            map.current.on("load", (e) => {
                if (e.b === "Upload") {
                    const showtazvisibility = () => {
                        const layernotExists =
                            map.current.getLayer(customlayerid) === undefined;
                        if (layernotExists) {
                            let customlayer_Id = Math.random().toString();
                            setCustomLayerId(customlayer_Id);
                            map.current.addLayer({
                                id: customlayer_Id,
                                type: "fill",
                                isCustom: true,
                                source: { type: "geojson", data: e.a },
                                layout: {
                                    visibility: "visible",
                                },
                                paint: {
                                    "fill-outline-color": "#FFFFFF",
                                    "fill-color": "#F0CF7F",
                                    "fill-opacity": 0.6,
                                },
                            });

                            waitForSourceDataLoaded(
                                map.current,
                                customlayer_Id,
                                "getTAZCounts",
                                e.a
                            ).then(() => {
                                // Define the bounding box
                                const polygonBoundingBox = bbox(e.a);
                                const southWest = [
                                    polygonBoundingBox[0],
                                    polygonBoundingBox[1],
                                ];
                                const northEast = [
                                    polygonBoundingBox[2],
                                    polygonBoundingBox[3],
                                ];
                                const bounds = [southWest, northEast];
                                map.current.fitBounds(bounds, {
                                    padding: 20, // Add padding to the edges of the map
                                });
                            });
                        }
                    };
                    showtazvisibility();
                } else {
                    const showtazvisibility = () => {
                        map.current.addLayer(MPOLayer);
                        map.current.addLayer(STATESLayer);
                        map.current.addLayer(ZIPLayer);
                        map.current.addLayer(TAZLayer);
                        map.current.addLayer(CountyLayer);
                        map.current.addLayer(CityLayer);
                        map.current.addLayer(MPOHighlighted);
                        map.current.addLayer(STATESHighlighted);
                        map.current.addLayer(CitiesHighlighted);
                        map.current.addLayer(ZIPHighlighted);
                        map.current.addLayer(CountiesHighlighted);
                        map.current.addLayer(TAZHighlighted);
                        var tazlayer = map.current.getLayer("TAZ");
                        map.current.setPaintProperty(
                            tazlayer.id,
                            "fill-outline-color",
                            "transparent"
                        );
                        map.current.setLayoutProperty(
                            tazlayer.id,
                            "visibility",
                            "visible"
                        );
                    };
                    showtazvisibility();
                }
            });
            document
                .getElementById("geocoder-container")
                .appendChild(geocoder.onAdd(map.current));
            map.current.addControl(
                new mapboxgl.NavigationControl({ showCompass: false })
            );
            map.current.addControl(draw, "bottom-right");
            document.getElementsByClassName(
                "mapboxgl-ctrl-group"
            )[1].style.display = "none";

            // Create a link.
            const link = document.createElement("a");
            link.id = "TAZ_button";
            link.type = "button";
            link.href = "#";
            link.textContent = "TAZ";
            link.className =
                "mapboxgl-ctrl  mapboxgl-ctrl-bottom-right-  active";
            link.style.backgroundColor = "lightgrey";
            // Show or hide layer when the toggle is clicked.

            link.onclick = async function (e) {
                const clickedLayer = this.textContent;

                var tazlayer = map.current.getLayer("TAZ");

                e.preventDefault();
                e.stopPropagation();

                const visibility = map.current.getLayoutProperty(
                    clickedLayer,
                    "visibility"
                );
                const showtazvisibility = async () => {
                    // Toggle layer visibility by changing the layout object's visibility property.
                    if (link.style.backgroundColor == "lightgrey") {
                        if (visibility === "none") {
                            map.current.setPaintProperty(
                                tazlayer.id,
                                "fill-outline-color",
                                "transparent"
                            );
                            map.current.setLayoutProperty(
                                clickedLayer,
                                "visibility",
                                "visible"
                            );
                            this.className = "";
                        } else {
                            this.className =
                                "mapboxgl-ctrl  mapboxgl-ctrl-top-left- active";
                            map.current.setLayoutProperty(
                                clickedLayer,
                                "visibility",
                                "visible"
                            );
                            map.current.setPaintProperty(
                                tazlayer.id,
                                "fill-outline-color",
                                "#FFFFFF"
                            );
                            link.style.backgroundColor = "#F0CF7F";
                        }
                    } else {
                        if (visibility === "visible") {
                            map.current.setPaintProperty(
                                tazlayer.id,
                                "fill-outline-color",
                                "transparent"
                            );
                            map.current.setLayoutProperty(
                                clickedLayer,
                                "visibility",
                                "none"
                            );
                            this.className = "";
                            link.style.backgroundColor = "lightgrey";
                        } else {
                            this.className =
                                "mapboxgl-ctrl  mapboxgl-ctrl-top-left- active";
                            map.current.setLayoutProperty(
                                clickedLayer,
                                "visibility",
                                "visible"
                            );
                            map.current.setPaintProperty(
                                tazlayer.id,
                                "fill-outline-color",
                                "#FFFFFF"
                            );
                            link.style.backgroundColor = "#F0CF7F";
                        }
                    }
                };
                await showtazvisibility();
            };
            const layers = document.getElementById("menu");
            layers.appendChild(link);
            var submitButton = document.getElementsByClassName(
                "btn btn-success btn-simple text-white"
            );
            if (submitButton.length > 0) {
                let buttonClicked = false;
                submitButton[0].addEventListener(
                    "click",
                    function handleClick() {
                        console.log("Submit button is clicked");
                        if (buttonClicked) {
                            console.log("Submit button has already clicked");
                        }
                        buttonClicked = true;
                    }
                );
            }
        } else {
            try {
                for (const id of dropdownlist) {
                    if (geo === id) {
                        if (geo === "select") {
                            const all_layers = map.current.getStyle().layers;
                            for (const lyr in all_layers) {
                                const layer = all_layers[lyr];
                                if (dropdownlist.includes(layer.id)) {
                                    map.current.setLayoutProperty(
                                        layer.id,
                                        "visibility",
                                        "none"
                                    );
                                    map.current.setLayoutProperty(
                                        layer.id + "-Highlighted",
                                        "visibility",
                                        "none"
                                    );
                                }
                            }
                        } else {
                            if (
                                map.current.getLayoutProperty(
                                    id,
                                    "visibility"
                                ) === "none"
                            ) {
                                map.current.setLayoutProperty(
                                    id,
                                    "visibility",
                                    "visible"
                                );
                                map.current.setLayoutProperty(
                                    geo.toString() + "-Highlighted",
                                    "visibility",
                                    "visible"
                                );
                                map.current.setFilter(
                                    geo.toString() + "-Highlighted",
                                    ["in", "zone_id", ""]
                                );
                            }
                            if (geo === "TAZ") {
                                if (
                                    map.current.getPaintProperty(
                                        id,
                                        "fill-outline-color"
                                    ) === "transparent"
                                ) {
                                    map.current.setPaintProperty(
                                        id,
                                        "fill-outline-color",
                                        "#FFFFFF"
                                    );
                                    map.current.setLayoutProperty(
                                        geo.toString() + "-Highlighted",
                                        "visibility",
                                        "visible"
                                    );
                                    map.current.setFilter(
                                        geo.toString() + "-Highlighted",
                                        ["in", "zone_id", ""]
                                    );
                                }
                            }
                        }
                    } else {
                        if (
                            map.current.getLayer(id) &&
                            map.current.getLayoutProperty(id, "visibility") ===
                            "visible" &&
                            id !== "TAZ"
                        ) {
                            map.current.setLayoutProperty(
                                id,
                                "visibility",
                                "none"
                            );
                            map.current.setLayoutProperty(
                                id + "-Highlighted",
                                "visibility",
                                "none"
                            );
                            map.current.setFilter(id + "-Highlighted", [
                                "in",
                                "zone_id",
                                "",
                            ]);
                        } else {
                            if (
                                map.current.getPaintProperty(
                                    id,
                                    "fill-outline-color"
                                ) === "#FFFFFF" &&
                                id === "TAZ"
                            ) {
                                map.current.setPaintProperty(
                                    id,
                                    "fill-outline-color",
                                    "transparent"
                                );
                                map.current.setLayoutProperty(
                                    id.toString() + "-Highlighted",
                                    "visibility",
                                    "none"
                                );
                                map.current.setFilter(
                                    id.toString() + "-Highlighted",
                                    ["in", "zone_id", ""]
                                );
                            }
                        }
                    }
                }

                if (radioOption == "Geographies") {
                    const layerExists =
                        map.current.getLayer(customlayerid) !== undefined;
                    if (layerExists) {
                        if (map.current.getLayer(customlayerid)) {
                            map.current.removeSource(customlayerid);
                            map.current.removeLayer(customlayerid);
                        }
                    }
                    setCustomLayerId(null);
                    setShapeZip(null);
                    setgeoJSON(null);
                    map.current.fire("draw.delete", {
                        a: map.current._controls[2],
                        b: radioOption,
                    });
                    let drawbutton = document.getElementsByClassName(
                        "mapbox-gl-draw_ctrl-draw-btn"
                    )[0];

                    drawbutton.disabled = true;
                    document.getElementsByClassName(
                        "mapboxgl-ctrl-group"
                    )[1].style.display = "none";

                    document.getElementsByClassName(
                        "mapboxgl-ctrl-group"
                    )[1].style.display = "none";
                } else if (radioOption === "Draw") {
                    const layerExists =
                        map.current.getLayer(customlayerid) !== undefined;
                    if (layerExists) {
                        // removeLayer('Custom-Layer');
                        if (map.current.getLayer(customlayerid)) {
                            map.current.removeSource(customlayerid);
                            map.current.removeLayer(customlayerid);
                        }
                    }
                    setCustomLayerId(null);
                    setShapeZip(null);
                    setgeoJSON(null);

                    for (const id of dropdownlist) {
                        if (id === "TAZ") {
                            map.current.setPaintProperty(
                                id,
                                "fill-outline-color",
                                "transparent"
                            );
                            map.current.setLayoutProperty(
                                id,
                                "visibility",
                                "none"
                            );
                            map.current.setLayoutProperty(
                                id + "-Highlighted",
                                "visibility",
                                "none"
                            );
                        } else {
                            map.current.setLayoutProperty(
                                id,
                                "visibility",
                                "none"
                            );
                            map.current.setLayoutProperty(
                                id + "-Highlighted",
                                "visibility",
                                "none"
                            );
                        }
                    }

                    map.current._controls[2].changeMode("draw_polygon");
                    document.getElementsByClassName(
                        "mapboxgl-ctrl-group"
                    )[1].style.display = "none";
                } else if (radioOption == "Upload") {
                    if (
                        shapeZip &&
                        shapeZip !== undefined &&
                        radioOption == "Upload" &&
                        geoJSON &&
                        geoJSON !== undefined
                    ) {
                        for (const id of dropdownlist) {
                            if (id === "TAZ") {
                                map.current.setPaintProperty(
                                    id,
                                    "fill-outline-color",
                                    "transparent"
                                );
                                map.current.setLayoutProperty(
                                    id,
                                    "visibility",
                                    "none"
                                );
                            } else {
                                if (
                                    map.current.getLayoutProperty(
                                        id,
                                        "visibility"
                                    ) === "none" ||
                                    map.current.getLayoutProperty(
                                        id,
                                        "visibility"
                                    ) === "visible"
                                ) {
                                    map.current.setLayoutProperty(
                                        id,
                                        "visibility",
                                        "none"
                                    );
                                    map.current.setLayoutProperty(
                                        id + "-Highlighted",
                                        "visibility",
                                        "none"
                                    );
                                }
                            }
                        }
                        map.current.fire("draw.delete", {
                            a: map.current._controls[2],
                            b: radioOption,
                        });
                        let drawbutton = document.getElementsByClassName(
                            "mapbox-gl-draw_ctrl-draw-btn"
                        )[0];

                        drawbutton.disabled = true;
                        document.getElementsByClassName(
                            "mapboxgl-ctrl-group"
                        )[1].style.display = "none";

                        document.getElementsByClassName(
                            "mapboxgl-ctrl-group"
                        )[1].style.display = "none";

                        const layerExists =
                            map.current.getLayer(customlayerid) !== undefined;
                        if (layerExists) {
                            if (map.current.getLayer(customlayerid)) {
                                map.current.removeSource(customlayerid);
                                map.current.removeLayer(customlayerid);
                            }
                        }
                        map.current.fire("load", {
                            a: geoJSON,
                            b: radioOption,
                            c: shapeZip.name.split(".")[0],
                        });
                    }
                    for (const id of dropdownlist) {
                        if (id === "TAZ") {
                            map.current.setPaintProperty(
                                id,
                                "fill-outline-color",
                                "transparent"
                            );
                            map.current.setLayoutProperty(
                                id,
                                "visibility",
                                "none"
                            );
                        } else {
                            if (
                                map.current.getLayoutProperty(
                                    id,
                                    "visibility"
                                ) === "none" ||
                                map.current.getLayoutProperty(
                                    id,
                                    "visibility"
                                ) === "visible"
                            ) {
                                map.current.setLayoutProperty(
                                    id,
                                    "visibility",
                                    "none"
                                );
                                map.current.setLayoutProperty(
                                    id + "-Highlighted",
                                    "visibility",
                                    "none"
                                );
                            }
                        }
                    }
                    map.current.fire("draw.delete", {
                        a: map.current._controls[2],
                        b: radioOption,
                    });
                    let drawbutton = document.getElementsByClassName(
                        "mapbox-gl-draw_ctrl-draw-btn"
                    )[0];

                    drawbutton.disabled = true;
                    document.getElementsByClassName(
                        "mapboxgl-ctrl-group"
                    )[1].style.display = "none";

                    document.getElementsByClassName(
                        "mapboxgl-ctrl-group"
                    )[1].style.display = "none";
                }

                // Create a popup, but don't add it to the map yet.
                const popup = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false,
                });
                map.current.on("mouseenter", geo, (e) => {
                    // Change the cursor style as a UI indicator.
                    const firstchild =
                        document.getElementById("menu").children[0];
                    const TAZ_button_color =
                        window.getComputedStyle(firstchild).backgroundColor;

                    if (geo !== "TAZ" && geo !== "select") {
                        if (TAZ_button_color !== yellowColor) {
                            // Change the cursor style as a UI indicator.
                            map.current.getCanvas().style.cursor = "pointer";
                            map.current.setLayoutProperty(
                                "TAZ",
                                "visibility",
                                "none"
                            );
                        }
                        const description = e.features[0].properties.zone_name;
                        popup
                            .setLngLat(e.lngLat)
                            .setText(description)
                            .addTo(map.current);
                    } else {
                        map.current.getCanvas().style.cursor = "pointer";

                        map.current.setLayoutProperty(
                            geo,
                            "visibility",
                            "visible"
                        );
                        const description = e.features[0].properties.zone_name;
                        if (geo === "TAZ" && TAZ_button_color !== yellowColor) {
                            popup
                                .setLngLat(e.lngLat)
                                .setText(description)
                                .addTo(map.current);
                        }
                    }
                });

                map.current.on("mouseleave", geo, () => {
                    map.current.getCanvas().style.cursor = "";
                    popup.remove();
                });
                map.current.on("draw.select", function (e) { });
                map.current.on("draw.delete", function (e) {
                    if (e.b == "Geographies" || e.b == "Upload") {
                        if (!(undefined == e.a)) {
                            e.a.getAll();
                            e.a.deleteAll();
                        }
                    } else {
                        e.getAll();
                        e.deleteAll();
                    }

                    map.current._controls[2].changeMode("simple_select");
                });

                var submitButton;
                var drawpop = new mapboxgl.Popup();
                const divElement = document.createElement("div");
                const assignBtn = document.createElement("div");
                map.current.on("drawfinish", function (e) {
                    setShowAlert(true);
                    const coordinates =
                        e.c.features[0].geometry.coordinates[0][0];

                    /**Create a button in pop up */
                    const name = "abc";
                    const innerHtmlContent = `<div style="min-width: 600px;font-size: large;color : black;">
                    <h4 class="h4Class">${name} </h4> </div>`;

                    assignBtn.innerHTML = `<button id="mytestbutton" class="btn btn-success btn-simple text-white" > Assign</button>`;
                    divElement.innerHTML = innerHtmlContent;
                    divElement.appendChild(assignBtn);
                    const popup =
                        document.getElementsByClassName("mapboxgl-popup");

                    drawpop
                        .setLngLat(coordinates)
                        .setDOMContent(divElement)
                        .addTo(map.current);

                    if (popup.length > 1) {
                        popup[popup.length - 1].remove();
                    }
                });

                var intersects = 0;
                var within = 0;
                var tazcounts = 0;
                var totaldiff = 0;
                const secondProjection =
                    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs";
                const firstProjection =
                    "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees";

                map.current.on("draw.create", function (e) {
                    try {
                        if (!e.features) return;

                        const feature = e.features[0];
                        if (
                            getdrawfeatures &&
                            !getdrawfeatures.includes(feature)
                        ) {
                            getdrawfeatures.push(feature);
                            setselectedFeatures(getdrawfeatures);
                        }

                        const querybuilder = feature.geometry.coordinates[0]
                            .map((coord) =>
                                proj4(firstProjection, secondProjection, coord)
                            )
                            .map((coord) => `${coord[0]} ${coord[1]}`)
                            .join(",");
                        dispatch(setLoader(true));
                        Promise.all([
                            fetchTAZIntersectCounts(querybuilder),
                            fetchTAZwithinCounts(querybuilder),
                        ])
                            .then(([intersectData, withinData]) => {
                                const tazcounts = intersectData.length;
                                const intersects = tazcounts;
                                const within = withinData.length;
                                const totaldiff = intersects - within;

                                const _zoneval = {
                                    id: feature.id,
                                    name: feature.id,
                                    zoneType: "Draw",
                                    tazcount: tazcounts,
                                    tazintersects: totaldiff,
                                    d_interectval: intersectData,
                                    d_withinval: withinData,
                                };
                                setzoneval(_zoneval);

                                dispatch(setLoader(false));
                                setShowDrawPopUp(true);
                                map.current._controls[2].changeMode(
                                    "draw_polygon"
                                );
                            })
                            .catch((error) => {
                                console.error("Error:", error);
                            });

                        map.current.off("draw.create");
                    } catch (error) {
                        console.error("Error:", error);
                    }
                });

                function fetchTAZIntersectCounts(querybuilder) {
                    const urlencoded = new URLSearchParams();
                    urlencoded.append(
                        "CQL_FILTER",
                        `INTERSECTS(poly_geom_3857,POLYGON((${querybuilder})))`
                    );

                    const requestOptions = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: urlencoded,
                        redirect: "follow",
                    };

                    return fetch(tazpostURL, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data) {
                                return data.features;
                            }
                        })
                        .catch((error) => {
                            console.log("error", error);
                        });
                }

                function fetchTAZwithinCounts(querybuilder) {
                    const urlencoded = new URLSearchParams();
                    urlencoded.append(
                        "CQL_FILTER",
                        `WITHIN(poly_geom_3857,POLYGON((${querybuilder})))`
                    );

                    const requestOptions = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: urlencoded,
                        redirect: "follow",
                    };

                    return fetch(tazpostURL, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data) {
                                return data.features;
                            }
                        })
                        .catch((error) => {
                            console.log("error", error);
                        });
                }
                const buildQueryString = (type, feature) => {
                    if (type === "Counties") {
                        return `%3D%22statefp10%22%3D'${feature.properties.statefp.toString()}'%20AND%20%22countyfp10%22%3D'${feature.properties.countyfp.toString()}'%26outputformat%3Djson`;
                    }
                    if (type === "States") {
                        return `%3D%22statefp10%22%3D'${feature.properties.statefp10.toString()}'%26outputformat%3Djson`;
                    }

                    // Add logic for 'States' if needed.
                    return "";
                };
                const fetchDataForGeo = async (
                    geo,
                    feature,
                    tazURL,
                    dispatch,
                    setzoneval
                ) => {
                    const queryString = buildQueryString(geo, feature);
                    const new_URL = decodeURIComponent(tazURL + queryString);

                    dispatch(setLoader(true));

                    try {
                        const response = await fetch(new_URL);
                        const data = await response.json();

                        if (data) {
                            setzoneval({
                                id: feature.properties.zone_id,
                                name: `${feature.properties.zone_name},${feature.properties.statefp}`,
                                zoneType: geo,
                                tazcount: data.features.length,
                                datafeatures: data,
                            });
                            dispatch(setLoader(false));
                        }
                    } catch (error) {
                        console.log(error.message);
                    }
                };

                map.current.on("click", async (e) => {
                    if (radioOption == "Geographies") {
                        const _bbox = [
                            [e.point.x - 1, e.point.y - 1],
                            [e.point.x + 1, e.point.y + 1],
                        ];
                        const _selectedFeatures =
                            map.current.queryRenderedFeatures(_bbox, {
                                layers: [geo],
                            });

                        var filter = await _selectedFeatures.reduce(
                            async function (memo, feature) {
                                memo.push(feature.properties.zone_id);
                                getfeatureselected.push(feature);
                                setselectedFeatures(getfeatureselected);

                                console.log(getfeatureselected);
                                var intersect_filter = [];

                                var polygonBoundingBox = bbox(feature);
                                var southWest = [
                                    polygonBoundingBox[0],
                                    polygonBoundingBox[1],
                                ];
                                var northEast = [
                                    polygonBoundingBox[2],
                                    polygonBoundingBox[3],
                                ];
                                var northEastPointPixel =
                                    map.current.project(northEast);
                                var southWestPointPixel =
                                    map.current.project(southWest);

                                var querystring;
                                var new_URL;

                                if (geo === "Counties") {
                                    await fetchDataForGeo(
                                        geo,
                                        feature,
                                        tazURL,
                                        dispatch,
                                        setzoneval
                                    );
                                } else if (geo === "States") {
                                    await fetchDataForGeo(
                                        geo,
                                        feature,
                                        tazURL,
                                        dispatch,
                                        setzoneval
                                    );
                                } else if (geo === "Cities") {
                                    const fetchgeofeaturebboxtest =
                                        async () => {
                                            querystring =
                                                "city_centroid" +
                                                "%26cql_filter%3D%22city_id%22%20%3D'" +
                                                feature.properties.zone_id.toString() +
                                                "'%26outputFormat%3Dapplication%2Fjson";
                                            new_URL = decodeURIComponent(
                                                geoURL + querystring
                                            );
                                            dispatch(setLoader(true));
                                            fetch(new_URL)
                                                .then((response) =>
                                                    response.json()
                                                )
                                                .then(async (data) => {
                                                    if (data) {
                                                        let poly_datafeatures =
                                                            [];
                                                        const geoids =
                                                            data.features
                                                                .filter(
                                                                    (feature) =>
                                                                        feature
                                                                            .properties
                                                                            .geoid10 !==
                                                                        ""
                                                                )
                                                                .map(
                                                                    (feature) =>
                                                                        `'${feature.properties.geoid10}'`
                                                                )
                                                                .join(", ");

                                                        if (
                                                            geoids &&
                                                            geoids.length > 0
                                                        ) {
                                                            const queryfilter_poly = `cql_filter=geoid IN (${geoids})`;
                                                            const decodedQueryfilter_poly =
                                                                decodeURIComponent(
                                                                    queryfilter_poly
                                                                );
                                                            const requestOptions_poly =
                                                            {
                                                                method: "POST",
                                                                headers: {
                                                                    "Content-Type":
                                                                        "application/x-www-form-urlencoded",
                                                                },
                                                                body: decodedQueryfilter_poly,
                                                                redirect:
                                                                    "follow",
                                                            };
                                                            const polyResponse =
                                                                await fetch(
                                                                    tazpostURL,
                                                                    requestOptions_poly
                                                                );
                                                            const polyData =
                                                                await polyResponse.json();
                                                            if (
                                                                polyData &&
                                                                polyData
                                                                    .features
                                                                    .length > 0
                                                            ) {
                                                                poly_datafeatures.push(
                                                                    ...polyData.features
                                                                );
                                                            }
                                                            var featcollection =
                                                            {
                                                                type: "FeatureCollection",
                                                                features:
                                                                    poly_datafeatures.map(
                                                                        (
                                                                            feature
                                                                        ) => ({
                                                                            type: "Feature",
                                                                            geometry:
                                                                            {
                                                                                type: "MultiPolygon",
                                                                                coordinates:
                                                                                    feature
                                                                                        .geometry
                                                                                        .coordinates,
                                                                            },
                                                                            properties:
                                                                                feature.properties,
                                                                        })
                                                                    ),
                                                            };
                                                            setzoneval({
                                                                id: feature
                                                                    .properties
                                                                    .zone_id,
                                                                name:
                                                                    feature
                                                                        .properties
                                                                        .zone_name +
                                                                    "," +
                                                                    feature
                                                                        .properties
                                                                        .statefp,
                                                                zoneType: geo,
                                                                tazcount:
                                                                    data
                                                                        .features
                                                                        .length,
                                                                datafeatures:
                                                                    featcollection,
                                                            });
                                                            dispatch(
                                                                setLoader(false)
                                                            );
                                                        } else {
                                                            dispatch(
                                                                setLoader(false)
                                                            );
                                                        }
                                                    }
                                                })
                                                .catch((err) => { });
                                        };
                                    await fetchgeofeaturebboxtest();
                                } else {
                                    let abccCOUNT = 0;
                                    let querybuilder = [];
                                    var featureBBox = [];
                                    const fetchgeofeaturebbox = async () => {
                                        var layer = map.current.getLayer(geo);

                                        querystring =
                                            layer.sourceLayer +
                                            "%26cql_filter%3D%22zone_id%22%20%3D'" +
                                            feature.properties.zone_id.toString() +
                                            "'%26outputFormat%3Dapplication%2Fjson";
                                        new_URL = decodeURIComponent(
                                            geoURL + querystring
                                        );

                                        fetch(new_URL)
                                            .then((response) => response.json())
                                            .then(async (data) => {
                                                if (data) {
                                                    for (
                                                        let i = 0;
                                                        i <=
                                                        data.features[0]
                                                            .geometry
                                                            .coordinates
                                                            .length -
                                                        1;
                                                        i++
                                                    ) {
                                                        featureBBox.push(
                                                            data.features[0]
                                                                .geometry
                                                                .coordinates[i]
                                                        );
                                                    }

                                                    fetchTAZCounts_();
                                                }
                                            })
                                            .catch((err) => { });
                                    };
                                    await fetchgeofeaturebbox();

                                    let geoid10 = [];
                                    const fetchTAZCounts_ = () => {
                                        let tazzscount = 0;

                                        featureBBox.forEach((featureitem) => {
                                            featureitem.forEach((item) => {
                                                let abc = [];
                                                let querybuilder = [];
                                                item.forEach((latlog) => {
                                                    abc.push(
                                                        latlog[0] +
                                                        " " +
                                                        latlog[1]
                                                    );
                                                });

                                                querybuilder.push(abc);

                                                querybuilder =
                                                    querybuilder.join(",");

                                                var _querybuilder =
                                                    encodeURIComponent(
                                                        querybuilder
                                                    );

                                                var myHeaders = new Headers();
                                                myHeaders.append(
                                                    "Content-Type",
                                                    "application/x-www-form-urlencoded"
                                                );

                                                var urlencoded =
                                                    new URLSearchParams();

                                                urlencoded.append(
                                                    "CQL_FILTER",
                                                    "WITHIN(point_geom,POLYGON((" +
                                                    querybuilder +
                                                    ")))"
                                                );

                                                var requestOptions = {
                                                    method: "POST",
                                                    headers: myHeaders,
                                                    body: urlencoded,
                                                    redirect: "follow",
                                                };

                                                dispatch(setLoader(true));
                                                fetch(
                                                    tazpostURL_point,
                                                    requestOptions
                                                )
                                                    .then((response) => {
                                                        if (!response.ok)
                                                            throw new Error(
                                                                response.status
                                                            );
                                                        else
                                                            return response.json();
                                                    })
                                                    .then(async (data) => {
                                                        if (data) {
                                                            data.features.forEach(
                                                                (prop) => {
                                                                    geoid10.push(
                                                                        prop
                                                                            .properties
                                                                            .geoid10
                                                                    );
                                                                }
                                                            );

                                                            tazzscount =
                                                                tazzscount +
                                                                data.features
                                                                    .length;
                                                            let poly_datafeatures =
                                                                [];

                                                            const geoids =
                                                                geoid10
                                                                    .filter(
                                                                        (
                                                                            geoid
                                                                        ) =>
                                                                            geoid !==
                                                                            null &&
                                                                            geoid !==
                                                                            undefined
                                                                    )
                                                                    .map(
                                                                        (
                                                                            geoid
                                                                        ) =>
                                                                            `'${geoid}'`
                                                                    )
                                                                    .join(", ");
                                                            const queryfilter_poly = `cql_filter=geoid IN (${geoids})`;
                                                            const decodedQueryfilter_poly =
                                                                decodeURIComponent(
                                                                    queryfilter_poly
                                                                );
                                                            const requestOptions_poly =
                                                            {
                                                                method: "POST",
                                                                headers: {
                                                                    "Content-Type":
                                                                        "application/x-www-form-urlencoded",
                                                                },
                                                                body: decodedQueryfilter_poly,
                                                                redirect:
                                                                    "follow",
                                                            };
                                                            const polyResponse =
                                                                await fetch(
                                                                    tazpostURL,
                                                                    requestOptions_poly
                                                                );
                                                            const polyData =
                                                                await polyResponse.json();
                                                            if (
                                                                polyData &&
                                                                polyData
                                                                    .features
                                                                    .length > 0
                                                            ) {
                                                                poly_datafeatures.push(
                                                                    ...polyData.features
                                                                );
                                                            }
                                                            var featcollection =
                                                            {
                                                                type: "FeatureCollection",
                                                                features:
                                                                    poly_datafeatures.map(
                                                                        (
                                                                            feature
                                                                        ) => ({
                                                                            type: "Feature",
                                                                            geometry:
                                                                            {
                                                                                type: "MultiPolygon",
                                                                                coordinates:
                                                                                    feature
                                                                                        .geometry
                                                                                        .coordinates,
                                                                            },
                                                                            properties:
                                                                                feature.properties,
                                                                        })
                                                                    ),
                                                            };
                                                            setzoneval({
                                                                id: feature // selected polygon features
                                                                    .properties
                                                                    .zone_id,
                                                                name: feature
                                                                    .properties
                                                                    .zone_name,
                                                                zoneType: geo,
                                                                tazcount:
                                                                    tazzscount,
                                                                datafeatures:
                                                                    featcollection, // taz poly features
                                                            });

                                                            dispatch(
                                                                setLoader(false)
                                                            );
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        console.log(err);
                                                    });
                                            });
                                        });
                                    };
                                }
                                return memo;
                            },
                            ["in", "zone_id"]
                        );
                        map.current.setFilter(
                            geo.toString() + "-Highlighted",
                            filter
                        );
                        const zonesids = getfeatureselected.map(
                            (zonefeature) => zonefeature.properties.zone_id
                        );
                        map.current.setFilter(geo.toString() + "-Highlighted", [
                            "in",
                            "zone_id",
                            ...zonesids,
                        ]);

                        e.preventDefault();
                    }
                });
            } catch (error) { }
        }
    }, [geo, radioOption, zonervalemoved, shapeZip, geoJSON]);

    const loadAndClose = () => {
        setShowAlert(false);
        fetchAndSaveMasterData();
    };

    const fetchAndSaveMasterData = async () => {
        dispatch(setLoader(true));
        const masterDataRes = await getMasterData(token);
        if (masterDataRes && masterDataRes.length > 0) {
            dispatch(setLoader(false));
            //save data to store
            masterDataRes.forEach((masterData) => {
                try {
                    if (masterData?.config?.url.includes("factors")) {
                        let factors = [];
                        masterData?.data?.data.forEach((factor) => {
                            factors.push(factor.attributes);
                        });
                        dispatch(setFactor(factors));
                    } else if (
                        masterData?.config?.url.includes("decision-rules")
                    ) {
                        let decisionRules = [];
                        masterData?.data?.data.forEach((decisionRule) => {
                            decisionRules.push(decisionRule.attributes);
                        });
                        dispatch(setDecisionRule(decisionRules));
                    } else if (
                        masterData?.config?.url.includes("package-additives")
                    ) {
                        let packageAdditives = [];
                        masterData?.data?.data.forEach((packageAdditive) => {
                            packageAdditives.push(packageAdditive.attributes);
                        });
                        dispatch(setPackageAdditive(packageAdditives));
                    } else if (
                        masterData?.config?.url.includes("subscriptions")
                    ) {
                        let subscriptions = [];
                        masterData?.data?.data.forEach((subscription) => {
                            subscriptions.push(subscription.attributes);
                        });
                        dispatch(setSubscription(subscriptions));
                    } else if (
                        masterData?.config?.url.includes("master-analyses")
                    ) {
                        let masterAnalyses = [];
                        masterData?.data?.data.forEach((masterAnalysis) => {
                            masterAnalyses.push(masterAnalysis.attributes);
                        });
                        dispatch(setMasterAnalysis(masterAnalyses));
                    } else if (masterData?.config?.url.includes("modes")) {
                        let masterModes = [];
                        masterData?.data?.data.forEach((masterMode) => {
                            masterModes.push(masterMode.attributes);
                        });
                        dispatch(setMasterMode(masterModes));
                    } else if (
                        masterData?.config?.url.includes(
                            "mode-analysis-mappings"
                        )
                    ) {
                        let modeAnalysisMappings = [];
                        masterData?.data?.data.forEach(
                            (modeAnalysisMapping) => {
                                modeAnalysisMappings.push(
                                    modeAnalysisMapping.attributes
                                );
                            }
                        );
                        dispatch(setModeAnalysisMapping(modeAnalysisMappings));
                    } else if (
                        masterData?.config?.url.includes(
                            "package-subscription-mappings"
                        )
                    ) {
                        let subscriptionMappings = [];
                        masterData?.data?.data.forEach(
                            (subscriptionMapping) => {
                                subscriptionMappings.push(
                                    subscriptionMapping.attributes
                                );
                            }
                        );
                        dispatch(
                            setSubscriptionPackageMapping(subscriptionMappings)
                        );
                    } else if (
                        masterData?.config?.url.includes(
                            "subscription-module-mappings"
                        )
                    ) {
                        let subscriptionMappings = [];
                        masterData?.data?.data.forEach(
                            (subscriptionMapping) => {
                                subscriptionMappings.push(
                                    subscriptionMapping.attributes
                                );
                            }
                        );
                        dispatch(
                            setSubscriptionModuleMapping(subscriptionMappings)
                        );
                    }
                    else if (
                        masterData?.config?.url.includes(
                            "subscription-module-disable-mappings"
                        )
                    ) {
                        dispatch(
                            setSubscriptionModuleDisableMapping(masterData?.data)
                        );
                    }
                    else if (
                        masterData?.config?.url.includes(
                            "subscription-addon-mappings"
                        )
                    ) {
                        let subscriptionMappings = [];
                        masterData?.data?.data.forEach(
                            (subscriptionMapping) => {
                                subscriptionMappings.push(
                                    subscriptionMapping.attributes
                                );
                            }
                        );
                        dispatch(
                            setSubscriptionAddonMapping(subscriptionMappings)
                        );
                    } else if (masterData?.config?.url.includes("modules")) {
                        let modules = [];
                        masterData?.data?.data.forEach((module) => {
                            module.attributes["id"] = module.id;
                            modules.push(module.attributes);
                        });
                        dispatch(setModules(modules));
                    } else if (masterData?.config?.url.includes("addons")) {
                        let mode_addons;
                        let metric_addons;
                        let support_addons;
                        let services_addons;
                        let special_access_addons;
                        let training_addons;

                        mode_addons = masterData.data["mode"];
                        metric_addons = masterData.data["metric"];
                        support_addons = masterData.data["support"];
                        services_addons = masterData.data["services"];
                        special_access_addons =
                            masterData.data["special access"];
                        training_addons = masterData.data["training"];

                        dispatch(setModeAddons(mode_addons));
                        dispatch(setMetricAddons(metric_addons));
                        dispatch(setTrainingAddons(training_addons));
                        dispatch(setSupportAddons(support_addons));
                        dispatch(setServiceAddons(services_addons));
                        dispatch(setSpecialAccessAddons(special_access_addons));
                    }
                } catch (error) { }
            });

            dispatch(setLoader(false));
        } else {
            dispatch(setLoader(false));
            //Show error message and reload master again
            setShowAlert(false);
        }
    };

    useEffect(() => {
        fetchAndSaveMasterData();
    }, []);

    return (
        <div className="position-relative">
            <div ref={mapContainer} className="map-container">
                <nav
                    style={{
                        position: "absolute",
                        bottom: "100px",
                        top: "500px",
                        right: "10px",
                        width: "auto",
                        zIndex: 99,
                    }}
                    id="menu"
                />
            </div>
            <MapForms
                geoLoc={geoLocationval}
                zone={zoneval}
                getStep={getStep}
                getRadioCheck={updateRadioCheck}
                getarrayzones={updateZonesList}
                getremovedzoneVal={setZoneRemoved}
                getShapeZip={setGeoJson}
            />
            <Modal
                show={showAlert}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    Master data could not be loaded. Please try again
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={loadAndClose}>
                        Try Again
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showDrawPopUp}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Please enter zone name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="mb-3">
                            <label className="col-form-label">Name:</label>
                            <textarea
                                className="form-control"
                                id="message-text"
                                onChange={(e) => updateZone(e.target.value)}
                            ></textarea>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
