import {AuthModel} from "../models/redux-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialState: AuthModel = {
  user: '',
  token: ''
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<AuthModel>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = '';
      state.token = '';
    }
  },
});

export const { setLogin, setLogout } = authSlice.actions;
export default authSlice.reducer;