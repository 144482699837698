import React, { ChangeEventHandler, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { download } from "@crmackey/shp-write";
import proj4 from "proj4";
import { polygon } from "@turf/helpers";
import { geomEach } from "@turf/meta";
import Select from "react-select";
import { useSelector } from "react-redux";

import { Zone } from "../../../../models/local-models";
import { COLORS } from "../../../../utils/constants";
import { RootState } from "../../../../store/store";
import { Customer } from "../../../../models/redux-models";
import { getFormattedPrice } from "../../../../utils/utils";

function Summary(props: {
    handleZoneCount: ChangeEventHandler<HTMLInputElement>;
    selectPackage: Function;
    solutionPackageType: string;
    seatCount: number;
    defaultSeatCount: number;
    total: number;
    addonTotal: number;
    tazCount: number;
    zoneList: Zone[];
    zoneCount: number;
}) {
    interface options_list {
        value: string;
        label: string;
    }

    let [selectedPackage, setSelectedPackage] =
        useState<String>("subscription");
    let [showAlert, setShowAlert] = useState(false);
    const sourceProjection =
        "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs";
    const targetProjection =
        "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees";

    const convertMultiPolygonToPolygon = (multiPolygon: any) => {
        const coordinates: number[][][] = [];

        // Iterate over each polygon in the MultiPolygon
        geomEach(multiPolygon, (polygonGeom) => {
            coordinates.push(...polygonGeom.coordinates[0]);
        });

        // Create the Polygon from the collected coordinates
        const convertedPolygon = polygon(coordinates);

        return convertedPolygon;
    };
    const customer: Customer = useSelector(
        (state: RootState) => state.common.customer
    );
    const companyName =
        customer.name !== "" ? customer.name : "Name of Customer or Company";
    const writeShapefile = async (featureCollections: any[]) => {
        const allFeatures: any[] = [];

        featureCollections.forEach((featureCollection) => {
            if (featureCollection.zoneType == "Draw") {
                featureCollection.d_interectval.forEach((feature: any) => {
                    const new_convertedfeature =
                        convertMultiPolygonToPolygon(feature);
                    const projectedCoordinates =
                        new_convertedfeature.geometry.coordinates[0].map(
                            (coord: number[]) =>
                                // Adjust the sourceProjection and targetProjection according to your needs
                                proj4(sourceProjection, targetProjection, coord)
                        );
                    // Filter out keys which are necessary as per insight.
                    interface Properties {
                        [key: string]: any;
                    }
                    const requiredKeys: string[] = ['is_bidi', 'is_pass', 'zone_id', 'zone_name'];

                    let filteredProperties: Properties = {};
                    requiredKeys.forEach((key) => {
                        if (key in feature.properties) {
                            filteredProperties[key] = feature.properties[key];
                        }
                    });

                    const projectedFeature = {
                        ...feature,
                        properties: filteredProperties,
                        geometry: {
                            ...new_convertedfeature.geometry,
                            coordinates: projectedCoordinates,
                        },
                    };
                    allFeatures.push(projectedFeature);
                });
            } else if (featureCollection.zoneType == "Upload") {
                featureCollection.datafeatures.forEach((feature: any) => {
                    const new_convertedfeature =
                        convertMultiPolygonToPolygon(feature);
                    const projectedCoordinates =
                        new_convertedfeature.geometry.coordinates[0].map(
                            (coord: number[]) =>
                                // Adjust the sourceProjection and targetProjection according to your needs
                                proj4(sourceProjection, targetProjection, coord)
                        );
                    const { fp2, ...remainingProperties } = feature.properties;
                    const projectedFeature = {
                        ...feature,
                        properties: remainingProperties,
                        geometry: {
                            ...new_convertedfeature.geometry,
                            coordinates: projectedCoordinates,
                        },
                    };
                    allFeatures.push(projectedFeature);
                });
            } else {
                featureCollection.datafeatures.features.forEach(
                    (feature: any) => {
                        const new_convertedfeature =
                            convertMultiPolygonToPolygon(feature);
                        const projectedCoordinates =
                            new_convertedfeature.geometry.coordinates[0].map(
                                (coord: number[]) =>
                                    // Adjust the sourceProjection and targetProjection according to your needs
                                    proj4(
                                        sourceProjection,
                                        targetProjection,
                                        coord
                                    )
                            );

                        // Filter out keys which are necessary as per insight.
                        interface Properties {
                            [key: string]: any;
                        }
                        const requiredKeys: string[] = ['is_bidi', 'is_pass', 'zone_id', 'zone_name'];

                        let filteredProperties: Properties = {};
                        requiredKeys.forEach((key) => {
                            if (key in feature.properties) {
                                filteredProperties[key] = feature.properties[key];
                            }
                        });

                        const projectedFeature = {
                            ...feature,
                            properties: filteredProperties,
                            geometry: {
                                ...new_convertedfeature.geometry,
                                coordinates: projectedCoordinates,
                            },
                        };
                        allFeatures.push(projectedFeature);
                    }
                );
            }
        });

        var options = {
            /** zip file name, id you only pass this
             * the shapefile will also receive this name
             */
            name: companyName,
            /** optional nested folder */
            folder: companyName,
            /** optional explicit names for shapefiles generated
             * for each type of geometry (applicable when mixed geometries are present)
             */
            types: {
                point: `${companyName}_points`,
                polygon: `${companyName}_polygons`,
                line: `${companyName}_lines`,
            },
        };

        try {
            const new_geo_test = await download(
                {
                    type: "FeatureCollection",
                    features: allFeatures.map((feature) => ({
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [feature.geometry.coordinates],
                        },
                        properties: feature.properties,
                    })),
                },
                options
            );
        } catch (error) {
            console.error("Error generating shapefile:", error);
        }
    };

    const dropdown_options: options_list[] = [
        { value: "subscription", label: "Solution Package" },
        { value: "mode", label: "Mode Package" },
        // ... other options
    ];
    const defaultValue: options_list = {
        value: "subscription",
        label: "Solution Package",
    };
    const handleSelect = (selected: options_list | null) => {
        if (selected) {
            setSelectedPackage(selected.value);
            props.selectPackage(selected.value);
            if (selected.value == "mode" && props.tazCount > 500) {
                setShowAlert(true);
            }
        } else {
            // if null
            console.log("Selected option is null");
        }
    };
    const loadAndClose = () => {
        setShowAlert(false);
    };

    const handleExportShapefile = () => {
        // a GeoJSON bridge for features
        writeShapefile(props.zoneList);
    };

    const style = {
        control: (styles: object, state: any) => ({
            ...styles,
            backgroundColor: state.isDisabled ? COLORS.BLACK96 : COLORS.BLACK92,
            border: "none",
            height: "45px",
            overflow: state.selectProps.height ? "hidden" : "auto",
            borderRadius: 0,
            width: "80%",
            boxShadow: "none",
            transition: "none",
        }),
        dropdownIndicator: (styles: object, state: any) => ({
            ...styles,
            padding: state.selectProps.height <= 30 ? "4px" : "8px",
            color: COLORS.YELLOW,
        }),
        menuPortal: (styles: object) => ({
            ...styles,
            zIndex: 9999,
        }),
        menu: (styles: object) => ({
            ...styles,
            margin: 0,
            background: COLORS.BLACK100,
            width: "80%",
        }),
        indicatorsContainer: (styles: object, state: any) => ({
            ...styles,
            alignItems: "start",
            height: state.selectProps.height,
        }),
        singleValue: (styles: object, state: any) => ({
            ...styles,
            color: state.isDisabled ? COLORS.BLACK100 : COLORS.LIGHT_GREY,
        }),
        clearIndicator: (styles: object) => ({
            ...styles,
            color: COLORS.YELLOW,
        }),
        indicatorSeparator: (styles: object) => ({
            ...styles,
            backgroundColor: COLORS.YELLOW,
            width: "2px",
        }),
        menuList: (styles: object) => ({
            ...styles,
            background: COLORS.BLACK100,
        }),
        option: (styles: object, state: any) => {
            const selected = state.isSelected
                ? {
                    background: COLORS.BLACK100,
                    color: COLORS.YELLOW,
                }
                : {};
            const focused = state.isFocused
                ? {
                    color: COLORS.YELLOW,
                    background: COLORS.BLACK100,
                }
                : {};

            return {
                ...styles,
                backgroundColor: COLORS.BLACK100,
                height: "100%",
                color: COLORS.YELLOW,
                ":hover": {
                    color: COLORS.YELLOW,
                    background: COLORS.LIGHT_GREY,
                },
                ...selected,
                ...focused,
            };
        },
    };

    const displayContainerStyle: React.CSSProperties = {
        display: "flex",
        width: "60%",
    };
    const displayBox: React.CSSProperties = {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        margin: "5px",
        width: "500%",
        height: "45px",
        alignItems: "center",
        backgroundColor: COLORS.BLACK92,
    };
    const displayHeader: React.CSSProperties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTop: "2px solid white",
        marginTop: "5px",
        paddingBottom: "10px",
        paddingTop: "10px",
    };

    const inputBox: React.CSSProperties = {
        borderRadius: "1px",
        border: "1px solid #000",
        textAlign: "center",
        width: "100px",
        marginLeft: "10px",
        color: "white",
        backgroundColor: COLORS.MID_GREY,
    };

    return (
        <div style={displayHeader}>
            <div style={{ width: "30%" }}>
                <Select
                    styles={style}
                    onChange={(e) =>
                        handleSelect(
                            e ? { value: e.value, label: e.label } : null
                        )
                    }
                    options={dropdown_options}
                    isSearchable={false}
                    defaultValue={defaultValue}
                />
            </div>
            <div style={displayContainerStyle}>
                {selectedPackage == "subscription" &&
                    props.solutionPackageType == "zone_based_packages" && (
                        <span className="p-3" style={displayBox}>
                            {" "}
                            Zone{" "}
                            {
                                <label>
                                    <input
                                        style={inputBox}
                                        type="text"
                                        value={props.zoneCount}
                                        onChange={props.handleZoneCount}
                                        placeholder="Input number of zones"
                                        min="0"
                                    />
                                </label>
                            }
                        </span>
                    )}
                {(selectedPackage == "mode" ||
                    props.solutionPackageType == "taz_based_packages") && (
                        <span className="p-3" style={displayBox}>
                            TAZ
                            <span style={{ marginLeft: "8px", fontSize: "1.25rem" }}>
                                {" "}
                                {props.tazCount}
                            </span>
                        </span>
                    )}
                <span className="p-3" style={displayBox}>
                    Seats
                    <span style={{ marginLeft: "8px", fontSize: "1.25rem" }}>
                        {" "}
                        {props.defaultSeatCount}
                    </span>
                    {props.seatCount - props.defaultSeatCount > 0 && (
                        <span
                            style={{ color: COLORS.YELLOW, marginLeft: "8px", fontSize: "1.25rem" }}
                        >
                            + {props.seatCount - props.defaultSeatCount}
                        </span>
                    )}
                </span>
                <span className="p-3" style={displayBox}>
                    Add-On(s) <span style={{ marginLeft: "8px", fontSize: "1.25rem" }}>
                        {" "}
                        {getFormattedPrice(props.addonTotal)}
                    </span>
                </span>
                <button
                    className="btn btn-outline-dark rounded-0 btn-sm "
                    style={displayBox}
                    onClick={handleExportShapefile}
                >
                    <strong>Export Shapefiles</strong>
                </button>
            </div>
            <Modal
                show={showAlert}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    Mode Package is not applicable for TAZ count greater than
                    500.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={loadAndClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Summary;
