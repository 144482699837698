import {
    SubscriptionMapping,
    Subscription,
    SubscriptionPackageMapping,
    SubscriptionModuleMapping,
    Modules,
    PackageAdditive,
    ModeAnalysisMapping,
    MasterMode,
    MasterAnalysis,
    Addon,
    MasterData,
    Factor,
    DecisionRule,
    ModeAddons,
    MetricAddons,
    TrainingAddons,
    ServiceAddons,
    SpecialAccessAddons,
    SupportAddons,
    SubscriptionAddonMapping
} from "../models/redux-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: MasterData = {
    addon: [],
    masterAnalysis: [],
    masterMode: [],
    modeAnalysisMapping: [],
    packageAdditive: [],
    subscription: [],
    subscriptionMapping: [],
    factor: [],
    decisionRule: [],
    subscriptionPackageMapping: [],
    subscriptionModuleMapping: [],
    subscriptionAddonMapping: [],
    subscriptionModuleDisableMapping: {},
    modules: [],
    modeAddons: [],
    metricAddons: [],
    trainingAddons: [],
    specialAccessAddons: [],
    serviceAddons: [],
    supportAddons: [],
};

export const masterSlice = createSlice({
    name: "master",
    initialState,
    reducers: {
        setAddOn: (state, action: PayloadAction<Addon[]>) => {
            state.addon = action.payload;
        },
        setMasterAnalysis: (state, action: PayloadAction<MasterAnalysis[]>) => {
            state.masterAnalysis = action.payload;
        },
        setMasterMode: (state, action: PayloadAction<MasterMode[]>) => {
            state.masterMode = action.payload;
        },
        setModeAnalysisMapping: (
            state,
            action: PayloadAction<ModeAnalysisMapping[]>
        ) => {
            state.modeAnalysisMapping = action.payload;
        },
        setPackageAdditive: (
            state,
            action: PayloadAction<PackageAdditive[]>
        ) => {
            state.packageAdditive = action.payload;
        },
        setSubscription: (state, action: PayloadAction<Subscription[]>) => {
            state.subscription = action.payload;
        },
        setSubscriptionMapping: (
            state,
            action: PayloadAction<SubscriptionMapping[]>
        ) => {
            state.subscriptionMapping = action.payload;
        },
        setFactor: (state, action: PayloadAction<Factor[]>) => {
            state.factor = action.payload;
        },
        setDecisionRule: (state, action: PayloadAction<DecisionRule[]>) => {
            state.decisionRule = action.payload;
        },
        setSubscriptionPackageMapping: (
            state,
            action: PayloadAction<SubscriptionPackageMapping[]>
        ) => {
            state.subscriptionPackageMapping = action.payload;
        },
        setSubscriptionModuleMapping: (
            state,
            action: PayloadAction<SubscriptionModuleMapping[]>
        ) => {
            state.subscriptionModuleMapping = action.payload;
        },
        setSubscriptionModuleDisableMapping: (
            state,
            action: PayloadAction<any>
        ) => {
            state.subscriptionModuleDisableMapping = action.payload;
        },
        setSubscriptionAddonMapping: (
            state,
            action: PayloadAction<SubscriptionAddonMapping[]>
        ) => {
            state.subscriptionAddonMapping = action.payload;
        },
        setModules: (state, action: PayloadAction<Modules[]>) => {
            state.modules = action.payload;
        },
        setModeAddons: (state, action: PayloadAction<ModeAddons[]>) => {
            state.modeAddons = action.payload;
        },
        setMetricAddons: (state, action: PayloadAction<MetricAddons[]>) => {
            state.metricAddons = action.payload;
        },
        setTrainingAddons: (state, action: PayloadAction<TrainingAddons[]>) => {
            state.trainingAddons = action.payload;
        },
        setSpecialAccessAddons: (state, action: PayloadAction<SpecialAccessAddons[]>) => {
            state.specialAccessAddons = action.payload;
        },
        setSupportAddons: (state, action: PayloadAction<SupportAddons[]>) => {
            state.supportAddons = action.payload;
        },
        setServiceAddons: (state, action: PayloadAction<ServiceAddons[]>) => {
            state.serviceAddons = action.payload;
        },
    },
});

export const {
    setAddOn,
    setMasterAnalysis,
    setMasterMode,
    setModeAnalysisMapping,
    setPackageAdditive,
    setSubscription,
    setSubscriptionMapping,
    setFactor,
    setDecisionRule,
    setSubscriptionPackageMapping,
    setSubscriptionModuleMapping,
    setSubscriptionModuleDisableMapping,
    setSubscriptionAddonMapping,
    setModules,
    setModeAddons,
    setMetricAddons,
    setTrainingAddons,
    setSpecialAccessAddons,
    setServiceAddons,
    setSupportAddons
} = masterSlice.actions;
export default masterSlice.reducer;
