import { Route, Routes, useLocation } from "react-router-dom";
import { Fragment } from "react";
import Login from "../pages/login/login";
import Map from "../pages/map/map";
import Admin from "../pages/admin/index";
import NotFound from "../pages/notfound";
import Proposal from "../pages/proposal/Proposal";
import MicrosoftAuthCallback from "../pages/MicrosoftAuthCallback";
import RedirectPage from "../pages/RedirectPage";

function App() {
    const location = useLocation();
    const { search } = location;
    return (
        <Fragment>
            <main>
                <Routes>
                    <Route
                        path="/"
                        element={
                            search === "" ? (
                                <Login />
                            ) : (
                                <MicrosoftAuthCallback />
                            )
                        }
                    />
                    {/* <Route path="/" element={<RedirectPage />} /> */}
                    <Route path="/map" element={<Map />} />
                    <Route
                        path="*"
                        element={
                            search === "" ? (
                                <NotFound />
                            ) : (
                                <MicrosoftAuthCallback />
                            )
                        }
                    />
                    <Route path="/proposal" element={<Proposal />} />
                    {/* <Route
                        path="/public/microsoft"
                        element={<MicrosoftAuthCallback />}
                    /> */}
                </Routes>
            </main>
        </Fragment>
    );
}

export default App;
