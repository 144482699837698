import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

function AlertDismissible(props: any) {
  const [show, setShow] = useState(true);

  
    return (
        <>
            {show && (
                <Alert
                    variant={props.variant}
                    onClose={() => setShow(false)}
                    dismissible
                >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>{props.msg}</p>
                </Alert>
            )}
        </>
    );
  
  
}

export default AlertDismissible;