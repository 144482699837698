import React, { useEffect, useState } from "react";
import { getFormattedPrice } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import { setExtraSeat } from "../../../../store/proposal-slice";
import { ProposalBaseData } from "../../../../models/redux-models";
import { COLORS } from "../../../../utils/constants";
import CustomAddons from "./CustomAddons";
import Discounts from "./Discounts";
import SpecialAccess from "./SpecialAccess";
import Accordion from 'react-bootstrap/Accordion';
import SalesTax from "./SalesTax";

function ExtraSeats(props: {
    packageType: String;
    subscriptionTotal: number;
    modeTotal: number;
    seatTotal: Function;
    seatCount: Function;
    defaultSeatCount: Function;
    accessTotal: Function;
    custTotal: Function;
    discountOnTotal: Function;
    salesTaxOnTotalAfterDiscount: Function;
}) {
    const [checkedState, setCheckedState] = useState<boolean>();
    const [seatCount, setSeatCount] = useState(0);
    const [extraSeatPrice, setExtraSeatPrice] = useState(0);
    const [extraSeatTotalPrice, setExtraSeatTotalPrice] = useState(0);
    const [defaultSeat, setDefaultSeat] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        setCheckedState(false);
        if (props.packageType === "mode") {
            setDefaultSeat(5);
            props.defaultSeatCount(5);
            props.seatCount(5);
        } else if (props.packageType === "subscription") {
            if (props.subscriptionTotal <= 100000) {
                setDefaultSeat(10);
                props.defaultSeatCount(10);
                props.seatCount(10);
                //  Roundup((Additional Seats)/10,0)*(Next 10 cost based on total subscription price)
            } else if (
                props.subscriptionTotal > 100000 &&
                props.subscriptionTotal <= 500000
            ) {
                setDefaultSeat(15);
                props.defaultSeatCount(15);
                props.seatCount(15);
            } else if (
                props.subscriptionTotal > 500000 &&
                props.subscriptionTotal <= 1000000
            ) {
                setDefaultSeat(30);
                props.defaultSeatCount(30);
                props.seatCount(30);
            } else if (
                props.subscriptionTotal > 1000000 &&
                props.subscriptionTotal <= 80000000
            ) {
                setDefaultSeat(100);
                props.defaultSeatCount(100);
                props.seatCount(100);
            }
        }
        setSeatCount(0);
        setExtraSeatTotalPrice(0);
        props.seatTotal(0);
        dispatch(setExtraSeat([]));
    }, [props.packageType, props.subscriptionTotal, props.modeTotal]);

    const handleOnChange = () => {
        setCheckedState(!checkedState);
        if (checkedState) {
            setSeatCount(0);
            setExtraSeatTotalPrice(0);
            props.seatTotal(0);
            props.seatCount(defaultSeat);
            dispatch(setExtraSeat([]));
        }
    };

    const updateSeatCountPrice = (val: number) => {
        if (val <= 0) {
            val = 0;
        }
        setSeatCount(val);
        let extraPrice = 0;
        if (props.packageType === "mode") {
            extraPrice = val * 2500;
        } else if (props.packageType === "subscription") {
            if (props.subscriptionTotal <= 100000) {
                extraPrice = Math.ceil(val / 10) * 10000;
                //  Roundup((Additional Seats)/10,0)*(Next 10 cost based on total subscription price)
            } else if (
                props.subscriptionTotal > 100000 &&
                props.subscriptionTotal <= 500000
            ) {
                extraPrice = Math.ceil(val / 10) * 8000;
            } else if (
                props.subscriptionTotal > 500000 &&
                props.subscriptionTotal <= 1000000
            ) {
                extraPrice = Math.ceil(val / 10) * 6000;
            } else if (
                props.subscriptionTotal > 1000000 &&
                props.subscriptionTotal <= 80000000
            ) {
                extraPrice = Math.ceil(val / 10) * 4000;
            }
        }
        setExtraSeatTotalPrice(extraPrice);
        props.seatTotal(extraPrice);
        props.seatCount(defaultSeat + val);
        let selectedSeatDetails = [];
        let seatDetail: ProposalBaseData = {
            name: defaultSeat + val + " Seats",
            price: extraPrice,
        };
        selectedSeatDetails.push(seatDetail);
        dispatch(setExtraSeat(selectedSeatDetails));
    };
    const PackagesAndSubscriptionHeader: React.CSSProperties = {
        color: COLORS.YELLOW,
    };
    const SubscriptionBox: React.CSSProperties = {
        marginTop: "5px",
        marginBottom: "2px",
        borderBottom: '1px solid white',

    };


    return (
        <div>
            <Accordion flush >
                <Accordion.Item eventKey="0" >
                    <Accordion.Header>
                        <h5 className="fw-bold" style={PackagesAndSubscriptionHeader}>Extra</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{ backgroundColor: COLORS.BLACK92, padding: "20px", paddingTop: '5px' }}>
                            <div>
                                <div style={SubscriptionBox}>
                                    <h4 className="fs-6 fw-bold">Additional Seats</h4>
                                    {extraSeatTotalPrice > 0 ? (
                                        <div
                                            className="right-section d-flex align-items-end flex-column fw-bold"
                                            style={{ marginTop: "-20px" }}
                                        >
                                            {getFormattedPrice(extraSeatTotalPrice)}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <h4 className="fs-6 text-light fw-normal">
                                        {defaultSeat} seats are included by default.
                                    </h4>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={checkedState}
                                            id="extraseat-check"
                                            onChange={() => handleOnChange()}
                                        />
                                        <label
                                            className="form-check-label text-light fw-normal"
                                            htmlFor="extraseat-check"
                                        >
                                            Add additional seats
                                        </label>
                                        <div
                                            className="right-section d-flex align-items-end flex-column"
                                            style={{ marginTop: "-20px", }}
                                        >
                                            <div className="input-group mb-3" style={{ width: "17%" }}>
                                                <input
                                                    className="bg-darkprimary text-light form-control input-wrap"
                                                    style={{
                                                        borderRadius: "0",
                                                        padding: "10px",
                                                        border: "none",
                                                        textAlign: "center",
                                                    }}
                                                    type="number"
                                                    min="1"
                                                    placeholder="Enter #"
                                                    onChange={(e) => {
                                                        let val = parseInt(e.target.value, 10);
                                                        if (!isNaN(val)) {
                                                            updateSeatCountPrice(val);
                                                        }
                                                    }}
                                                    disabled={!checkedState}
                                                    value={seatCount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <SpecialAccess
                                    packageType={props.packageType}
                                    subscriptionTotal={props.subscriptionTotal}
                                    modeTotal={props.modeTotal}
                                    accessTotal={props.accessTotal}
                                />
                                <CustomAddons
                                    packageType={props.packageType}
                                    custTotal={props.custTotal}
                                />
                                <Discounts
                                    discountOnTotal={props.discountOnTotal}
                                    packageType={props.packageType}
                                />
                                <SalesTax
                                    salesTaxOnTotalAfterDiscount={props.salesTaxOnTotalAfterDiscount}
                                    packageType={props.packageType}
                                />
                            </div>
                        </div >
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion >
            <div style={{ margin: '20px', marginTop: '5px', marginBottom: '5px' }} >
            </div>
        </div>
    );
}

export default ExtraSeats;
