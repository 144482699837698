import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { RootState } from "../../store/store";
import { getModeMetrices } from "../../services/api";
import { setModeMetrices } from "../../store/proposal-slice";

function Controls(props: {
    tazCount: number;
    currentStep: number;
    clickNext: Function;
    clickPrevious: Function;
}) {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const subscription = useSelector(
        (state: RootState) => state.proposal.subscription
    );
    const mode = useSelector((state: RootState) => state.proposal.mode);
    const token = useSelector((state: RootState) => state.auth.token);
    const modules = useSelector((state: RootState) => state.proposal.subsAddonModules);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();
    const handlePriviousClick = () => {
        props.clickPrevious();
    };

    const handleNextClick = () => {
        props.clickNext();
    };

    const handleConfirm = () => {
        setShow(true);
    };

    const handleConfirmAndClose = async () => {
        setShow(false);
        const modeIds = new Array<number | undefined>();
        if (mode && mode.length > 0) {
            mode.forEach((value) => {
                modeIds.push(value.id);
            });
            const data = await getModeMetrices(modeIds, token);
            dispatch(setModeMetrices(data));
        }
        navigate("/proposal");
    };

    return (
        <div className="py-2 d-flex">
            <button
                className={`btn btn-lg rounded-0 mx-2 ${props.currentStep !== 1
                    ? "btn-outline-dark"
                    : "disabled btn-outline-lightprimary"
                    }`}
                onClick={handlePriviousClick}
            >
                Back
            </button>
            <button
                className={`btn btn-lg rounded-0 mx-2 ${props.currentStep < 4
                    ? props.currentStep === 1 ||
                        (props.currentStep > 1 && props.tazCount > 0)
                        ? "btn-outline-dark"
                        : "disabled btn-outline-lightprimary"
                    : "disabled btn-outline-lightprimary"
                    }`}
                onClick={handleNextClick}
            >
                Next
            </button>
            <button className="btn btn-outline-dark btn-lg rounded-0 mx-2">
                Save as Draft
            </button>
            <button
                className={`btn btn-lg rounded-0 mx-2 ${props.currentStep >= 4 &&
                    (subscription.length > 0 ||
                        mode.length > 0 ||
                        modules.length > 0
                    )
                    ? "btn-outline-dark"
                    : "disabled btn-lightprimary text-darkprimary"
                    }`}
                onClick={handleConfirm}
            >
                Confirm
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header closeButton>
                  <Modal.Title>Street Light</Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                    Once you proceed, you can't make changes. Are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmAndClose}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Controls;
