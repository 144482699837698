import { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header/Header";
import LoadingSpinner from "./components/Loader/LoadingSpinner";
import Routes from "./components/Routes";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";

function App() {
    const isLoading = useSelector((state: RootState) => state.common.loader);
    return (
        <Fragment>
            <div className="main-container">
                {isLoading && (
                    <div
                        style={{ top: "0px", position: "absolute", zIndex: 10 }}
                    >
                        <LoadingSpinner></LoadingSpinner>
                    </div>
                )}

                <BrowserRouter>
                    <Header />
                    <Routes />
                </BrowserRouter>
            </div>
        </Fragment>
    );
}

export default App;
