export const COLORS = {
    YELLOW: "#FFDA6A",
    BLACK100: "#202020",
    BLACK96: "#292929",
    BLACK92: "#313131",
    WHITE: "#fff",
    BLUE: "#4668bf",
    MID_GREY: "#707070",
    LIGHT_GREY: "#f5f5f5",
};

export const MIN_PRICE_ZONE_BASED = 5000
//fix discounts
export const DISCOUNT_TAZ_BASED = 0.1
export const DISCOUNT_ZONE_BASED = 0.3