import React, { useEffect, useState } from "react";
import LocationSearch from "./forms/LocationSearch";
import SelectRegion from "./forms/SelectRegion";
import RefineTaz from "./forms/RefineTaz";
import ConfigureCalculate from "./forms/ConfigureAndCalculate";
import DuplicateOption from "./forms/DuplicateOption";
import Footer from "../../components/Footer/Footer";
import { Zone } from "../../models/local-models";

function MapForms(props: {
    geoLoc: Function;
    zone: Zone;
    getStep: Function;
    getRadioCheck: Function;
    getarrayzones: Function;
    getremovedzoneVal: Function;
    getShapeZip: Function;
}) {
    let [currentStep, setCurrentStep] = useState(1);
    let [tazCount, setTazCount] = useState(0);
    let [geo, setGeo] = useState("");
    let [zoneList, setZoneList] = useState<Zone[]>([]);
    let [updateRadioCheck, setupdateRadioCheck] = useState("");
    let [removedZoneValue, setremovedZoneValue] = useState("");

    // function for going to next step
    const clickNext = () => {
        // If the current step is 1 or 2, then add one on "next" button click
        currentStep = currentStep >= 3 ? 4 : currentStep + 1;
        setCurrentStep(currentStep);
        props.getStep(currentStep);
    };
    const setGeoLocn = (data: string) => {
        setGeo(data);
        props.geoLoc(data);
    };

    const setZipFile = (data: File, geo: string) => {
        props.getShapeZip(data, geo);

    };
    const setRadioCheck = (data: string) => {
        setupdateRadioCheck(data);
        props.getRadioCheck(data);
    };
    const setrevisedzonelist = (data: []) => {
        setZoneList(data);
        props.getarrayzones(data);

    }
    const setremovedZoneVal = (data: string) => {
        setremovedZoneValue(data);
        props.getremovedzoneVal(data);

    }
    // function for going to previous step
    const clickPrevious = () => {
        // If the current step is 2 or 3, then subtract one on "previous" button click
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        setCurrentStep(currentStep);
    };

    return (
        <>
            <LocationSearch currentStep={currentStep} />
            <SelectRegion
                currentStep={currentStep}
                geo={setGeoLocn}
                zone={props.zone}
                updateZoneData={setZoneList}
                updateRadioCheck={setRadioCheck}
                getupdatedzoneData={setrevisedzonelist}
                getremovedZoneVal={setremovedZoneVal}
                getZipFile={setZipFile}
            />
            <RefineTaz
                currentStep={currentStep}
                setTazCount={setTazCount}
                zoneList={zoneList}
            />
            <ConfigureCalculate currentStep={currentStep} tazCount={tazCount}
                zoneList={zoneList} />
            <Footer
                tazCount={tazCount}
                currentStep={currentStep}
                clickNext={clickNext}
                clickPrevious={clickPrevious}
            />
        </>
    );
}

export default MapForms;
