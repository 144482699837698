import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import { useSelector, useDispatch} from "react-redux";
import { updateCustomer } from '../../store/common-slice';
import { RootState } from '../../store/store';
import { Customer } from '../../models/redux-models';

function CompanyName() {
  const [show, setShow] = useState(false);
  const [custName, setCustName] = useState("");
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const updateName = (val: string) =>{
    setCustName(val);
  }

  const customer: Customer = useSelector((state: RootState) => state.common.customer);
  const updateCustDetails = () => {
    dispatch(
      updateCustomer({...customer, name:custName})
    );
    setShow(false);
  }
  return (
    <>
      <div className="text-white flex-fill">{customer.name !== '' ? customer.name : 'Name of Customer or Company'}  <FontAwesomeIcon icon={faPen} className="text-primary" onClick={() => handleShow()} /></div>
      <Modal show= {show} onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
              {/* <Modal.Header closeButton>
                  <Modal.Title>Street Light</Modal.Title>
              </Modal.Header> */}
              <Modal.Body>
                  <Form>
                    <div className="mb-3">
                      <label className="col-form-label">Name:</label>
                      <textarea className="form-control" id="message-text" onChange={(e) => updateName(e.target.value)}></textarea>
                    </div>
                  </Form>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                      Cancel
                  </Button>
                  <Button variant="primary" onClick={updateCustDetails}>
                      Confirm
                  </Button>
              </Modal.Footer>
          </Modal>
    </>
    
  )
}

export default CompanyName