import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLogin } from "../store/auth-slice";
import { useNavigate } from "react-router-dom";

function MicrosoftAuthCallback() {
    const [auth, setAuth] = useState();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location) {
            return;
        }
        const { search } = location;
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/auth/microsoft/callback${search}`,
        })
            .then((res) => res.data)
            .then((data) => {
                dispatch(
                    setLogin({
                        user: data.user.username,
                        token: data.jwt,
                    })
                );
                navigate("/map");
            });
    }, []);

    return <div></div>;
}

export default MicrosoftAuthCallback;
