import { useState, useEffect, ChangeEvent } from "react";

import Summary from "./Summary";
import Subscriptions from "./Subscriptions";
import ExtraSeats from "./ExtraSeats";
import Services from "./Services";
import { getFormattedPrice } from "../../../../utils/utils";
import "./configcalc.css";
import Modes from "./Modes";
import { getBasePrice } from "../../../../services/api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { BasePrice, Zone } from "../../../../models/local-models";
import { useDispatch } from "react-redux";
import { resetStore } from "../../../../store/proposal-slice";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfigureCalculate(props: {
    currentStep: number;
    tazCount: number;
    zoneList: Zone[];
}) {
    let [packageType, setPackageType] = useState<String>("subscription");
    let [zoneCount, setZoneCount] = useState<number>(0);
    let [solutionPackageType, setSolutionPackageType] = useState<string>("taz_based_packages");
    let [basePriceList, setBasePriceList] = useState<BasePrice[]>([]);
    let [basePriceMode, setBasePriceMode] = useState(0);
    let [basePriceSol, setBasePriceSol] = useState(0);
    let [subscriptionTotal, setSubscriptionTotal] = useState(0);
    let [modeTotal, setModeTotal] = useState(0);
    let [supportTotal, setSupportTotal] = useState(0);
    let [trainingTotal, setTrainingTotal] = useState(0);
    let [serviceTotal, setServiceTotal] = useState(0);
    let [seatTotal, setSeatTotal] = useState(0);
    let [seatCount, setSeatCount] = useState(0);
    let [accessTotal, setAccessTotal] = useState(0);
    let [customAddonTotal, setCustomAddonTotal] = useState(0);
    let [grandTotal, setGrandTotal] = useState(0);
    let [showAlert, setShowAlert] = useState(false);
    let [discountOnTotal, setDiscount] = useState(0);
    let [addonTotal, setaddOnTotal] = useState(0);
    let [defaultSeatCount, setDefaultSeatCount] = useState(0);
    let [salesTaxOnTotalAfterDiscount, setSalesTaxOnTotalAfterDiscount] = useState(0);
    const token = useSelector((state: RootState) => state.auth.token);
    const dispatch = useDispatch();

    const resetAll = () => {
        setGrandTotal(0);
        setSubscriptionTotal(0);
        setModeTotal(0);
        setSupportTotal(0);
        setTrainingTotal(0);
        setServiceTotal(0);
        setSeatTotal(0);
        setSeatCount(0);
        setAccessTotal(0);
        setCustomAddonTotal(0);
        setDiscount(0);
        setaddOnTotal(0);
        //Reset proposal store data
        dispatch(resetStore());
    };

    useEffect(() => {
        let total = subscriptionTotal +
            modeTotal +
            addonTotal -
            (packageType === "alacarte" || packageType == "mode"
                ? (discountOnTotal * (modeTotal + addonTotal)) / 100
                : packageType === "subscription"
                    ? (discountOnTotal * (subscriptionTotal + addonTotal)) / 100
                    : 0)

        if (salesTaxOnTotalAfterDiscount > 0) {
            total = total * (1 + salesTaxOnTotalAfterDiscount / 100)
        }
        setGrandTotal(total);
    }, [
        subscriptionTotal,
        modeTotal,
        discountOnTotal,
        packageType,
        addonTotal,
        salesTaxOnTotalAfterDiscount,
    ]);
    useEffect(() => {
        setaddOnTotal(
            supportTotal +
            trainingTotal +
            serviceTotal +
            seatTotal +
            accessTotal +
            customAddonTotal
        );
    }, [
        supportTotal,
        trainingTotal,
        serviceTotal,
        seatTotal,
        accessTotal,
        customAddonTotal,
    ]);

    const loadAndClose = () => {
        setShowAlert(false);
        fetchBasePrice();
    };

    const fetchBasePrice = async () => {
        const basePriceRes = await getBasePrice(
            token,
            props.tazCount > 10000 ? 1 : props.tazCount
        );
        let basePrices: BasePrice[] = [];
        if (basePriceRes?.data?.data) {
            basePriceRes?.data?.data.forEach((basePrice: any) => {
                basePrices.push(basePrice.attributes);
            });

            setBasePriceList(basePrices);
            basePrices.forEach((basePrice) => {
                if (basePrice.package_type === "mode") {
                    setBasePriceMode(basePrice.price);
                } else if (basePrice.package_type === "solution") {
                    setBasePriceSol(basePrice.price);
                }
            });
        } else {
            setShowAlert(true);
        }
    };

    useEffect(() => {
        resetAll();
        fetchBasePrice();
    }, [props.tazCount]);

    useEffect(() => {
        setSolutionPackageType("taz_based_packages");
    }, [packageType]);

    if (props.currentStep !== 4) {
        return null;
    }
    // function for going to next step
    const selectPackage = (val: string) => {
        setPackageType(val);
        resetAll();
    };
    const handleZoneCount = (e: any) => {
        setZoneCount(Number(e.target.value));
    };

    return (
        <div className="sidebar w-100 p-5">
            <div className="p-3 text-light bg-normalprimary d-flex align-items-center shadow">
                <div className="w-100 p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="fs-5 fw-normal mb-3">
                            Configure and Calculate
                        </h2>
                        <div className="text-light fw-bold justify-content-end d-flex gap-1">
                            <span className="fs-5 fw-normal mb-3">
                                Grand Total <span style={{ marginLeft: "8px", fontSize: "1.75rem" }}>
                                    {" "}
                                    {getFormattedPrice(grandTotal)}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div style={{ padding: "0px" }}>
                        <Summary
                            selectPackage={selectPackage}
                            handleZoneCount={handleZoneCount}
                            zoneCount={zoneCount}
                            solutionPackageType={solutionPackageType}
                            seatCount={seatCount}
                            defaultSeatCount={defaultSeatCount}
                            total={grandTotal}
                            tazCount={props.tazCount}
                            zoneList={props.zoneList}
                            addonTotal={addonTotal}
                        />
                    </div>
                    <div className="container-wrap">
                        <div className="row">
                            <div className="row d-flex">
                                {packageType === "subscription" ? (
                                    <Subscriptions
                                        solutionPackageType={
                                            setSolutionPackageType
                                        }
                                        zoneCount={zoneCount}
                                        basePrice={basePriceSol}
                                        subTotal={setSubscriptionTotal}
                                        tazCount={props.tazCount}
                                    />
                                ) : (
                                    <Modes
                                        basePrice={
                                            props.tazCount > 500
                                                ? 0
                                                : basePriceMode
                                        }
                                        modeTotal={setModeTotal}
                                    />
                                )}
                                <Services
                                    packageType={packageType}
                                    serviceTotal={setServiceTotal}
                                    trainingTotal={setTrainingTotal}
                                    subscriptionTotal={subscriptionTotal}
                                    supportTotal={setSupportTotal}
                                />
                                <ExtraSeats
                                    packageType={packageType}
                                    subscriptionTotal={subscriptionTotal}
                                    modeTotal={modeTotal}
                                    seatTotal={setSeatTotal}
                                    seatCount={setSeatCount}
                                    defaultSeatCount={setDefaultSeatCount}
                                    accessTotal={setAccessTotal}
                                    custTotal={setCustomAddonTotal}
                                    discountOnTotal={setDiscount}
                                    salesTaxOnTotalAfterDiscount={setSalesTaxOnTotalAfterDiscount}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showAlert}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    Base price could not be loaded. Please try again.
                    <br />
                    Note: Try logging in again if the issue persists.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={loadAndClose}>
                        Try Again
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ConfigureCalculate;
