import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDiscount } from "../../../../store/proposal-slice";
import { ProposalBaseData } from "../../../../models/redux-models";

function Discounts(props: {
    discountOnTotal: Function; packageType: String;
}) {
    const [checkedState, setCheckedState] = useState<boolean>(false);
    const [discount, setCurrDiscount] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        setCheckedState(false);
        setCurrDiscount(0);
    }, [props.packageType]);

    //setCheckedState(false);
    const handleOnChange = () => {
        setCheckedState(!checkedState);
        if (checkedState) {
            setCurrDiscount(0);
            props.discountOnTotal(0);
            dispatch(setDiscount([]));
        }
    };

    const updateDiscount = (val: number) => {
        if (val <= 0) {
            val = 0;
        }
        if (val > 100) {
            val = 100;
        }
        setCurrDiscount(val);
        props.discountOnTotal(val);
        let selectedDiscountDetails = [];
        let discountDetail: ProposalBaseData = {
            name: "Discount",
            price: val,
        };
        selectedDiscountDetails.push(discountDetail);
        dispatch(setDiscount(selectedDiscountDetails));
    };

    return (
        <div style={{
            borderBottom: '0.5px solid white', marginTop: "5px",
            marginBottom: "2px", paddingTop: "10px", paddingBottom: "10px"
        }}>
            <h4 className="fs-6 fw-bold">Discount</h4>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={checkedState}
                    id="discount-check"
                    onChange={() => handleOnChange()}
                />
                <label
                    className="form-check-label text-light fw-normal"
                    htmlFor="discount-check"
                >
                    Add discount (in %)
                </label>
                <div
                    className="right-section d-flex align-items-end flex-column"
                    style={{ marginTop: "-20px" }}
                >
                    <div className="input-group mb-3" style={{ width: "17%" }}>
                        <input
                            className="bg-darkprimary text-light form-control input-wrap"
                            style={{
                                borderRadius: "0",
                                padding: "10px",
                                border: "none",
                                textAlign: "center",
                            }}
                            type="number"
                            min="1"
                            max="100"
                            placeholder="%"
                            onChange={(e) => {
                                let val = parseInt(e.target.value, 10);
                                if (!isNaN(val)) {
                                    updateDiscount(val);
                                }
                            }}
                            disabled={!checkedState}
                            value={discount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Discounts;
