import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    ProposalBaseData,
    Subscription,
    Modules,
    ModeAddons,
    MetricAddons,
} from "../../../../models/redux-models";
import { RootState } from "../../../../store/store";
import { getFormattedPrice } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import {
    setSubscription,
    setSubsAddonModes,
    setSubsAddonMetrics,
    setSubsMetricsPrice,
    setSubsModesPrice,
    setSubsAddonModules,
    setSubsModulesPrice,
} from "../../../../store/proposal-slice";
import Modal from "react-bootstrap/Modal";
import metricimg_01 from "../../../../assets/package_option_01.jpg";
import metricimg_02 from "../../../../assets/package_option_02.jpg";
import metricimg_03 from "../../../../assets/package_option_03.jpg";
import {
    COLORS,
    MIN_PRICE_ZONE_BASED,
    DISCOUNT_TAZ_BASED,
    DISCOUNT_ZONE_BASED,
} from "../../../../utils/constants";
import Accordion from "react-bootstrap/Accordion";
import { Carousel } from "react-bootstrap";
import { PackageTypeEnum } from "../ConfigureAndCalculate/constants";
import { MarginalCostToAddModule } from "../ConfigureAndCalculate/constants";

function Subscriptions(props: {
    basePrice: number;
    subTotal: Function;
    tazCount: number;
    zoneCount: number;
    solutionPackageType: Function;
}) {
    let [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    let [modulesList, setModules] = useState<Modules[]>([]);
    let [metricAddons, setMetricAddons] = useState<MetricAddons[]>([]);
    let [modeAddons, setModeAddons] = useState<ModeAddons[]>([]);

    let [disableModule, setDisableModule] = useState<any>();
    let [subscriptionPrice, setSubscriptionPrice] = useState<number[]>([]);
    useState<number[]>([]);
    let [totSubscriptionCost, setTotSubscriptionCost] = useState(0);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const [modeAddonCheckedState, setModeAddonCheckedState] = useState<
        boolean[]
    >([]);
    const [metricAddonCheckedState, setMetricAddonCheckedState] = useState<
        boolean[]
    >([]);
    const [modesAddonDetails, setModesAddonDetails] = useState<any[]>([]);
    const [metricsAddonDetails, setMetricsAddonDetails] = useState<any[]>([]);
    const [moduleAddonCheckedState, setModuleAddonCheckedState] = useState<
        boolean[]
    >([]);
    const [moduleState, setModulesState] = useState<number[]>([]);
    const [addonsState, setAddonsState] = useState<number[]>([]);
    const [modeAddonCount, setModeAddonCount] = useState(0);
    const [metricAddonCount, setMetricAddonCount] = useState(0);
    const [modeAddonPrice, setModeAddonPrice] = useState(0);
    const [metricAddonPrice, setMetricAddonPrice] = useState(0);
    let [checkedStateCount, setCheckedStateCount] = useState(0);
    let [selectedRegionBasedPackage, setSelectedRegionBasedPackage] =
        useState<String>("taz_based_packages");
    const dispatch = useDispatch();
    const masterSubscription = useSelector(
        (state: RootState) => state.master.subscription
    );
    const [showModal, setShowModal] = useState(false);
    const masterFactors = useSelector(
        (state: RootState) => state.master.factor
    );
    const subscriptionPackageMapping = useSelector(
        (state: RootState) => state.master.subscriptionPackageMapping
    );
    const masterModulesList = useSelector(
        (state: RootState) => state.master.modules
    );
    const masterModeAddons = useSelector(
        (state: RootState) => state.master.modeAddons
    );
    const masterMetricAddons = useSelector(
        (state: RootState) => state.master.metricAddons
    );
    const subscriptionModuleDisableMapping = useSelector(
        (state: RootState) => state.master.subscriptionModuleDisableMapping
    );

    const subscriptionModuleMapping = useSelector(
        (state: RootState) => state.master.subscriptionModuleMapping
    );

    const subscriptionAddonMapping = useSelector(
        (state: RootState) => state.master.subscriptionAddonMapping
    );

    const setSelectedModules = (moduleId: number, index: number) => {
        if (checkedState.some((elem) => elem)) {
            const module = moduleState.filter((id) => id === moduleId);
            return module.length == 1;
        } else {
            return moduleAddonCheckedState[index];
        }
    };

    const getSubscriptionPriceWithModules = (
        modules_count: number,
        base_price: number
    ) => {
        let sum = 0;
        for (let i = 0; i <= modules_count; i++) {
            let marginal_sum = MarginalCostToAddModule[i] * base_price;
            sum += marginal_sum;
        }
        return sum;
    };
    function removeDuplicates(arr: any) {
        return arr.filter(
            (item: any, index: number) => arr.indexOf(item) === index
        );
    }
    function getModuleAddonDetails(module_ids: any) {
        let moduleAddonsDetails: any[] = [];
        let modulesMap = new Map(modulesList.map(module => [module.id, module]));

        for (let id of module_ids) {
            if (modulesMap.has(id)) {
                let module = modulesMap.get(id);
                if (module !== undefined) {
                    let addonsDetail = {
                        id: module.id,
                        name: module.name,
                    };
                    moduleAddonsDetails.push(addonsDetail);
                }
            }
        }
        return moduleAddonsDetails;
    }
    function getModeOrMetricsAddonDetails(addon_ids: any, addons: any) {
        let addonsDetails: any[] = [];
        for (let i = 0; i < addon_ids.length; i++) {
            for (let j = 0; j < addons.length; j++) {
                if (addons[j].id == addon_ids[i]) {
                    let addonsDetail = {
                        id: addons[j].id,
                        name: addons[j].name,
                    };
                    addonsDetails.push(addonsDetail);
                }
            }
        }
        return addonsDetails;
    }
    function fillSubscriptionPrices(
        totalAmount: number,
        subscriptionCounts: Record<number, number>
    ) {
        const updatedPrices: Record<number, number> = {};
        const totalCount = Object.values(subscriptionCounts).reduce(
            (acc, curr) => acc + curr,
            0
        );

        for (const subscription_id in subscriptionCounts) {
            const count = subscriptionCounts[subscription_id];
            const pricePerSubscription = (totalAmount / totalCount) * count;
            updatedPrices[+subscription_id] = pricePerSubscription;
        }

        let subDetails: ProposalBaseData[] = [];

        const updatedSubscriptions = subscriptions.map((subscription) => {
            const updatedPrice = updatedPrices[subscription.subscription_id];
            if (updatedPrice !== undefined) {
                let subDetail = {
                    id: subscription.subscription_id,
                    type: "taz_based_packages",
                    name: subscription.name,
                    price: updatedPrice,
                };
                subDetails.push(subDetail);
                return { ...subscription, price: updatedPrice };
            }
            return subscription; // Keep unchanged subscriptions
        });

        setSubscriptions(updatedSubscriptions);
        dispatch(setSubscription(subDetails));
    }
    function fillModulePrices(
        totalAmount: number,
        moduleCounts: Record<number, number>
    ) {
        const updatedPrices: Record<number, number> = {};
        const totalCount = Object.values(moduleCounts).reduce(
            (acc, curr) => acc + curr,
            0
        );

        for (const module_id in moduleCounts) {
            const count = moduleCounts[module_id];
            const pricePerModule = (totalAmount / totalCount) * count;
            updatedPrices[+module_id] = pricePerModule;
        }

        const modulesListCopy = [...modulesList];

        let moduleAddonsDetails: any[] = [];
        const updatedModules = modulesListCopy.map((module) => {
            const updatedPrice = updatedPrices[module.id];
            if (updatedPrice !== undefined) {
                let addonsDetail = {};
                addonsDetail = {
                    id: module.id,
                    name: module.name,
                    price: updatedPrice,
                };
                moduleAddonsDetails.push(addonsDetail);
                return { ...module, price: updatedPrice };
            }
            return module;
        });
        setModules(updatedModules);
        dispatch(setSubsAddonModules(moduleAddonsDetails));
    }
    function fillModulePricesForZoneBased(
        totalAmountTolling: number,
        totalAmountSiteSelection: number,
        moduleCountsTolling: Record<number, number>,
        moduleCountsSiteSelection: Record<number, number>
    ) {
        const updatedPricesSiteSelection: Record<number, number> = {};
        const updatedPricesTolling: Record<number, number> = {};
        const totalCount = Object.values(moduleCountsTolling).reduce(
            (acc, curr) => acc + curr,
            0
        );

        for (const module_id in moduleCountsTolling) {
            const count = moduleCountsTolling[module_id];
            const pricePerModule = (totalAmountTolling / totalCount) * count;
            updatedPricesTolling[+module_id] = pricePerModule;
        }
        for (const module_id in moduleCountsSiteSelection) {
            const count = moduleCountsSiteSelection[module_id];
            const pricePerModule =
                (totalAmountSiteSelection / totalCount) * count;
            updatedPricesTolling[+module_id] = pricePerModule;
        }
        const modulesListCopy = [...modulesList];

        let moduleAddonsDetails: any[] = [];
        let updatedModules = modulesListCopy.map((module) => {
            const updatedPrice = updatedPricesTolling[module.id];
            if (updatedPrice !== undefined && updatedPrice !== 0) {
                let addonsDetail = {};
                addonsDetail = {
                    id: module.id,
                    name: module.name,
                    price: updatedPrice,
                };
                moduleAddonsDetails.push(addonsDetail);
                return { ...module, price: updatedPrice };
            }
            return module;
        });
        updatedModules = updatedModules.map((module) => {
            const updatedPrice = updatedPricesSiteSelection[module.id];
            if (updatedPrice !== undefined && updatedPrice !== 0) {
                let addonsDetail = {};
                addonsDetail = {
                    id: module.id,
                    name: module.name,
                    price: updatedPrice,
                };
                moduleAddonsDetails.push(addonsDetail);
                return { ...module, price: updatedPrice };
            }
            return module;
        });
        setModules(updatedModules);
        dispatch(setSubsAddonModules(moduleAddonsDetails));
    }
    function fillMetricModePrices(
        totalAmount: number,
        addonCounts: Record<number, number>,
        type: string
    ) {
        const totalCount = Object.values(addonCounts).reduce(
            (acc, curr) => acc + curr,
            0
        );
        const updatedPrices: Record<number, number> = {};
        const pricePerModule = totalAmount / totalCount;

        for (const id in addonCounts) {
            updatedPrices[+id] = pricePerModule;
        }
        let modeAddonsDetails: any = [];
        let metricAddonsDetails: any = [];
        if (type === "mode") {
            const updatedAddons = modeAddons.map((addon) => {
                const updatedPrice = updatedPrices[addon.id];
                if (updatedPrice !== undefined) {
                    let addonsDetail = {};
                    addonsDetail = {
                        id: addon.id,
                        name: addon.name,
                        price: updatedPrice,
                    };
                    modeAddonsDetails.push(addonsDetail);
                    return { ...addon, price: updatedPrice };
                }
                return addon;
            });
            setModeAddons(updatedAddons);
            dispatch(setSubsAddonModes(modeAddonsDetails));
        } else {
            const updatedAddons = metricAddons.map((addon) => {
                const updatedPrice = updatedPrices[addon.id];
                if (updatedPrice !== undefined) {
                    let addonsDetail = {};
                    addonsDetail = {
                        id: addon.id,
                        name: addon.name,
                        price: updatedPrice,
                    };
                    metricAddonsDetails.push(addonsDetail);
                    return { ...addon, price: updatedPrice };
                }
                return addon;
            });
            setMetricAddons(updatedAddons);
            dispatch(setSubsAddonMetrics(metricAddonsDetails));
        }
    }

    const handleOnChange = (position: number, solutionPackageType: String) => {
        //To cleanup the modules details if "only addon modules" is selected during the flow
        dispatch(setSubsAddonModules([]));
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        const checkedStateCount = updatedCheckedState.reduce(
            (sum, currentState) => {
                if (currentState === true) {
                    return sum + 1;
                }
                return sum;
            },
            0
        );
        setCheckedStateCount(checkedStateCount);
        //Filter out modules for subscription selected
        const { selectedSubscriptionIds } = updatedCheckedState.reduce(
            (
                result: { selectedSubscriptionIds: number[] },
                currentState,
                index
            ) => {
                if (currentState === true) {
                    result.selectedSubscriptionIds.push(
                        subscriptions[index].subscription_id
                    );
                }
                return result;
            },
            {
                selectedSubscriptionIds: [],
            }
        );

        const validRows = subscriptionModuleMapping.filter((sub) =>
            selectedSubscriptionIds.includes(sub.subscription_id)
        );

        const validRowsAddons = subscriptionAddonMapping.filter((sub) =>
            selectedSubscriptionIds.includes(sub.subscription_id)
        );
        let subscriptionModuleCounts: any = {};

        validRows.forEach((row) => {
            if (subscriptionModuleCounts[row.subscription_id]) {
                subscriptionModuleCounts[row.subscription_id] += 1;
            } else {
                subscriptionModuleCounts[row.subscription_id] = 1;
            }
        });

        let moduleIdCounts: any = {};
        let validModuleIds = validRows.map((sub) => sub.module_id);

        validRows.forEach((row) => {
            if (moduleIdCounts[row.module_id]) {
                moduleIdCounts[row.module_id] += 1;
            } else {
                moduleIdCounts[row.module_id] = 1;
            }
        });

        validModuleIds = removeDuplicates(validModuleIds);
        setModulesState(validModuleIds);
        dispatch(setSubsAddonModules(getModuleAddonDetails(validModuleIds)));

        let validAddonsIds = validRowsAddons.map((sub) => sub.addon_id);

        validModuleIds = removeDuplicates(validModuleIds);
        validAddonsIds = removeDuplicates(validAddonsIds);
        setModulesState(validModuleIds);
        let moCheckedState: boolean[] = Array.apply(null, Array(modeAddons.length)).map(function () { return false });
        let meCheckedState: boolean[] = Array.apply(null, Array(metricAddons.length)).map(function () { return false });
        let indexMode = 0, indexMetric = 0, modeAddonCount = 0, metricAddonCount = 0;
        for (let index in validAddonsIds) {
            let addonId = validAddonsIds[index];
            if (modeAddons.find(ele => { return ele.id === addonId })) {
                moCheckedState[indexMode++] = true;
                modeAddonCount++;
            } else if (metricAddons.find(ele => { return ele.id === addonId })) {
                meCheckedState[indexMetric++] = true;
                metricAddonCount++;
            }
        }
        setMetricAddonCount(metricAddonCount);
        setModeAddonCount(modeAddonCount);
        handleMetricModeAddOnChange(0, selectedRegionBasedPackage, "mode", validModuleIds.length, moCheckedState)
        handleMetricModeAddOnChange(0, selectedRegionBasedPackage, "metrics", validModuleIds.length, meCheckedState)

        dispatch(setSubsAddonModules(getModuleAddonDetails(validModuleIds)));
        dispatch(setSubsAddonModes(getModeOrMetricsAddonDetails(validAddonsIds, modeAddons)));
        dispatch(setSubsAddonMetrics(getModeOrMetricsAddonDetails(validAddonsIds, metricAddons)));
        const updateModuleActiveStates: any = {};
        for (const module in subscriptionModuleDisableMapping) {
            if (findInArray(selectedSubscriptionIds, subscriptionModuleDisableMapping[module])) {
                updateModuleActiveStates[module] = true
            } else {
                updateModuleActiveStates[module] = false
            }
        }
        setDisableModule(updateModuleActiveStates);

        //Get total price and update subscription details
        let subDetails: ProposalBaseData[] = [];
        let totalPrice = 0;

        if (solutionPackageType == "taz_based_packages") {
            //Subscription price with modules
            const subpriceWithModules = getSubscriptionPriceWithModules(
                validModuleIds.length,
                props.basePrice
            );

            fillSubscriptionPrices(
                subpriceWithModules,
                subscriptionModuleCounts
            );
            fillModulePrices(subpriceWithModules, moduleIdCounts);

            //Subscription price with Addons (Metrics + Modes)
            const subpriceWithAddons =
                subpriceWithModules *
                (1 + 0.15 * (modeAddonCount + metricAddonCount));

            updatedCheckedState.reduce((sum, currentState, index) => {
                if (currentState === true) {
                    let subDetail = {
                        id: subscriptions[index].subscription_id,
                        type: "taz_based_packages",
                        name: subscriptions[index].name,
                        price: subpriceWithModules,
                    };
                    subDetails.push(subDetail);
                }
                return sum;
            }, 0);
            totalPrice = subpriceWithAddons;
            if (checkedStateCount > 0) {
                totalPrice *= 1 - DISCOUNT_TAZ_BASED;
            }
            setTotSubscriptionCost(subpriceWithModules);
        } else if (solutionPackageType == "zone_based_packages") {
            totalPrice = updatedCheckedState.reduce(
                (sum, currentState, index) => {
                    let pricePerZone = 50;
                    if (props.zoneCount && props.zoneCount <= 1000) {
                        pricePerZone =
                            subscriptions[index].subscription_id == 1014
                                ? 75
                                : 150;
                    }
                    if (currentState === true) {
                        subscriptionPrice[index] =
                            pricePerZone * props.zoneCount +
                            MIN_PRICE_ZONE_BASED;
                        let subDetail = {
                            id: subscriptions[index].subscription_id,
                            type: "zone_based_packages",
                            name: subscriptions[index].name,
                            price: subscriptionPrice[index],
                        };
                        subDetails.push(subDetail);
                        return sum + subscriptionPrice[index];
                    } else {
                        subscriptionPrice[index] = 0;
                    }
                    return sum;
                },
                0
            );
            let tollingModuleIdCounts: any = {};
            let siteSelectionModuleIdCounts: any = {};

            const validRowsTolling = subscriptionModuleMapping.filter(
                (sub) => sub.subscription_id === 1008
            );
            const validRowsSiteSelection = subscriptionModuleMapping.filter(
                (sub) => sub.subscription_id === 1014
            );
            validRowsTolling.forEach((row) => {
                if (tollingModuleIdCounts[row.module_id]) {
                    tollingModuleIdCounts[row.module_id] += 1;
                } else {
                    tollingModuleIdCounts[row.module_id] = 1;
                }
            });
            validRowsSiteSelection.forEach((row) => {
                if (siteSelectionModuleIdCounts[row.module_id]) {
                    siteSelectionModuleIdCounts[row.module_id] += 1;
                } else {
                    siteSelectionModuleIdCounts[row.module_id] = 1;
                }
            });
            setTotSubscriptionCost(totalPrice);
            fillModulePricesForZoneBased(
                subscriptionPrice[1],
                subscriptionPrice[0],
                tollingModuleIdCounts,
                siteSelectionModuleIdCounts
            );
            //Subscription price with Addons (Metrics + Modes)
            totalPrice =
                totalPrice * (1 + 0.15 * (modeAddonCount + metricAddonCount));

            if (checkedStateCount > 1) {
                // for more than 1 package apply 30% discount
                totalPrice *= 1 - DISCOUNT_ZONE_BASED;
            }
            dispatch(setSubscription(subDetails));
        }

        setSubscriptionPrice(subscriptionPrice);
        props.subTotal(totalPrice);
    };

    const findInArray = (searchArray: number[], values: number[]) => {
        const len = values.length
        for (let i = 0; i < len; i++) {
            if (searchArray.indexOf(values[i]) > -1)
                return true
        }
        return false
    }
    const handleMetricModeAddOnChange = (
        position: number,
        solutionPackageType: String,
        type: string,
        moduleCount: any = undefined,
        mappedAddonsState: any = undefined
    ) => {
        moduleCount = moduleCount == undefined ? moduleState.length : moduleCount;
        let checkedAddonState: boolean[] = []
        let updatedCheckedState: boolean[] = []
        if (mappedAddonsState)
            updatedCheckedState = [...mappedAddonsState]
        else {
            checkedAddonState = type == "mode" ? modeAddonCheckedState : metricAddonCheckedState;
            updatedCheckedState = checkedAddonState.map((item, index) =>
                index === position ? !item : item
            );
        }
        let modeAddonsDetails: any[] = [];
        let metricAddonsDetails: any[] = [];
        const checkedAddonStateCount = updatedCheckedState.reduce(
            (sum, currentState, index) => {
                if (currentState === true) {
                    let addonsDetail = {};
                    if (type == "mode") {
                        addonsDetail = {
                            id: modeAddons[index].id,
                            name: modeAddons[index].name,
                        };
                        modeAddonsDetails.push(addonsDetail);
                    } else {
                        addonsDetail = {
                            id: metricAddons[index].id,
                            name: metricAddons[index].name,
                        };
                        metricAddonsDetails.push(addonsDetail);
                    }
                    return sum + 1;
                }
                return sum;
            },
            0
        );
        let totalPrice = 0;
        let previousAddonCount = 0;
        if (type == "metrics") {
            previousAddonCount = modeAddonCount;
        } else {
            previousAddonCount = metricAddonCount;
        }
        let subpriceWithModules = 0;
        if (solutionPackageType == "taz_based_packages") {
            //Subscription price with modules
            subpriceWithModules = getSubscriptionPriceWithModules(
                moduleCount,
                props.basePrice
            );
        } else {
            subpriceWithModules = subscriptionPrice.reduce(
                (value: number, sum: number) => {
                    return value + sum;
                },
                0
            );
        }

        let addOnCounts: any = {};
        if (type == "mode") {
            modeAddonsDetails.forEach((row) => {
                if (addOnCounts[row.id]) {
                    addOnCounts[row.id] += 1;
                } else {
                    addOnCounts[row.id] = 1;
                }
            });
        } else {
            metricAddonsDetails.forEach((row) => {
                if (addOnCounts[row.id]) {
                    addOnCounts[row.id] += 1;
                } else {
                    addOnCounts[row.id] = 1;
                }
            });
        }

        //Subscription price with Addons (Metrics + Modes)
        const subpriceWithAddons =
            subpriceWithModules *
            (1 + 0.15 * (previousAddonCount + checkedAddonStateCount));

        totalPrice = subpriceWithAddons;

        if (type == "metrics") {
            setMetricAddonCheckedState(updatedCheckedState);
            setMetricAddonCount(checkedAddonStateCount);
            setMetricAddonPrice(
                checkedAddonStateCount * subpriceWithModules * 0.15
            );
            dispatch(
                setSubsMetricsPrice(
                    checkedAddonStateCount * subpriceWithModules * 0.15
                )
            );
            dispatch(setSubsAddonMetrics(metricAddonsDetails));
        } else {
            setModeAddonCheckedState(updatedCheckedState);
            setModeAddonCount(checkedAddonStateCount);
            setModeAddonPrice(
                checkedAddonStateCount * subpriceWithModules * 0.15
            );
            dispatch(
                setSubsModesPrice(
                    checkedAddonStateCount * subpriceWithModules * 0.15
                )
            );
            dispatch(setSubsAddonModes(modeAddonsDetails));
        }
        //Apply discount only if a package is selected ex: Corridor Studies
        if (checkedStateCount > 0) {
            if (solutionPackageType == "taz_based_packages")
                totalPrice *= 1 - DISCOUNT_TAZ_BASED;
            else if (checkedStateCount == 2)
                totalPrice *= 1 - DISCOUNT_ZONE_BASED;
        }
        props.subTotal(totalPrice);

        if (type == "mode") {
            fillMetricModePrices(
                checkedAddonStateCount * subpriceWithModules * 0.15,
                addOnCounts,
                type
            );
        } else {
            fillMetricModePrices(
                checkedAddonStateCount * subpriceWithModules * 0.15,
                addOnCounts,
                type
            );
        }
    };
    const handleModuleAddOnChange = (
        position: number,
        solutionPackageType: String
    ) => {
        if (solutionPackageType == "taz_based_packages") {
            setCheckedState(new Array(subscriptions.length).fill(false));
            setCheckedStateCount(0);
            dispatch(setSubscription([]));
            const updatedCheckedState = moduleAddonCheckedState.map(
                (item, index) => (index === position ? !item : item)
            );
            setModuleAddonCheckedState(updatedCheckedState);

            let moduleAddonsDetails: any[] = [];
            let moduleIds: any[] = [];

            const checkedAddonStateCount = updatedCheckedState.reduce(
                (sum, currentState, index) => {
                    if (currentState === true) {
                        let addonsDetail = {};
                        addonsDetail = {
                            id: modulesList[index].id,
                            name: modulesList[index].name,
                        };
                        moduleAddonsDetails.push(addonsDetail);
                        moduleIds.push(modulesList[index].id);
                        return sum + 1;
                    }
                    return sum;
                },
                0
            );
            setModulesState(moduleIds);
            let moduleIdCounts: any = {};

            moduleIds.forEach((module_id) => {
                if (moduleIdCounts[module_id]) {
                    moduleIdCounts[module_id] += 1;
                } else {
                    moduleIdCounts[module_id] = 1;
                }
            });
            let totalPrice = 0;

            //Subscription price with modules
            const subpriceWithModules = getSubscriptionPriceWithModules(
                checkedAddonStateCount,
                props.basePrice
            );

            fillModulePrices(subpriceWithModules, moduleIdCounts);

            //Subscription price with Addons (Metrics + Modes)
            const subpriceWithAddons =
                subpriceWithModules *
                (1 + 0.15 * (modeAddonCount + metricAddonCount));

            totalPrice = subpriceWithAddons;
            dispatch(setSubsModulesPrice(subpriceWithModules));
            dispatch(setSubsAddonModules(moduleAddonsDetails));
            setTotSubscriptionCost(subpriceWithModules);
            setMetricAddonPrice(metricAddonCount * subpriceWithModules * 0.15);
            dispatch(
                setSubsMetricsPrice(
                    metricAddonCount * subpriceWithModules * 0.15
                )
            );
            setModeAddonPrice(modeAddonCount * subpriceWithModules * 0.15);
            dispatch(
                setSubsModesPrice(modeAddonCount * subpriceWithModules * 0.15)
            );
            //Apply discount only if a package is selected ex: Corridor Studies
            if (checkedStateCount > 0) {
                totalPrice *= 1 - DISCOUNT_TAZ_BASED;
            }
            props.subTotal(totalPrice);
        }
    };
    useEffect(() => {
        let subDetails: ProposalBaseData[] = [];
        let totalPrice = checkedState.reduce((sum, currentState, index) => {
            let pricePerZone = 50;
            if (props.zoneCount && props.zoneCount <= 1000) {
                pricePerZone =
                    subscriptions[index].subscription_id == 1014 ? 75 : 150;
            }
            if (currentState === true) {
                subscriptionPrice[index] =
                    pricePerZone * props.zoneCount + MIN_PRICE_ZONE_BASED;
                let subDetail = {
                    id: subscriptions[index].subscription_id,
                    name: subscriptions[index].name,
                    price: subscriptionPrice[index],
                };
                subDetails.push(subDetail);
                return sum + subscriptionPrice[index];
            } else {
                subscriptionPrice[index] = 0;
            }
            return sum;
        }, 0);

        const subpriceWithModules = subscriptionPrice.reduce(
            (value: number, sum: number) => {
                return value + sum;
            },
            0
        );
        let tollingModuleIdCounts: any = {};
        let siteSelectionModuleIdCounts: any = {};

        const validRowsTolling = subscriptionModuleMapping.filter(
            (sub) => sub.subscription_id === 1008
        );
        const validRowsSiteSelection = subscriptionModuleMapping.filter(
            (sub) => sub.subscription_id === 1014
        );
        validRowsTolling.forEach((row) => {
            if (tollingModuleIdCounts[row.module_id]) {
                tollingModuleIdCounts[row.module_id] += 1;
            } else {
                tollingModuleIdCounts[row.module_id] = 1;
            }
        });
        validRowsSiteSelection.forEach((row) => {
            if (siteSelectionModuleIdCounts[row.module_id]) {
                siteSelectionModuleIdCounts[row.module_id] += 1;
            } else {
                siteSelectionModuleIdCounts[row.module_id] = 1;
            }
        });
        fillModulePricesForZoneBased(
            subscriptionPrice[1],
            subscriptionPrice[0],
            tollingModuleIdCounts,
            siteSelectionModuleIdCounts
        );

        setTotSubscriptionCost(subpriceWithModules);

        const subpriceWithAddons =
            subpriceWithModules *
            (1 + 0.15 * (modeAddonCount + metricAddonCount));
        //Final price of subscription with 10% discount
        const subpriceWith10PercDiscount =
            checkedStateCount == 2
                ? subpriceWithAddons * 0.7
                : subpriceWithAddons;

        totalPrice = subpriceWith10PercDiscount;

        setModeAddonPrice(subpriceWithModules * 0.15 * modeAddonCount);
        setMetricAddonPrice(metricAddonCount * subpriceWithModules * 0.15);
        props.subTotal(totalPrice);
        dispatch(setSubscription(subDetails));
        dispatch(setSubsAddonModes(modesAddonDetails));
        dispatch(setSubsAddonMetrics(metricsAddonDetails));
        dispatch(
            setSubsMetricsPrice(metricAddonCount * subpriceWithModules * 0.15)
        );
        dispatch(
            setSubsModesPrice(modeAddonCount * subpriceWithModules * 0.15)
        );
    }, [props.zoneCount]);

    useEffect(() => {
        let validSubs: any[] = [];
        if (selectedRegionBasedPackage === "taz_based_packages") {
            validSubs = subscriptionPackageMapping.filter(
                (sub) => sub.package_id === PackageTypeEnum.TAZ_BASED_PACKAGES
            );
        } else if (selectedRegionBasedPackage === "zone_based_packages") {
            validSubs = subscriptionPackageMapping.filter(
                (sub) => sub.package_id === PackageTypeEnum.ZONE_BASED_PACKAGES
            );
        }
        let validSubsIds = validSubs.map((sub) => sub.subscription_id);
        const subscriptions = masterSubscription.filter((sub) =>
            validSubsIds.includes(sub.subscription_id)
        );

        setModules(masterModulesList);
        setModeAddons(masterModeAddons);
        setMetricAddons(masterMetricAddons);

        setSubscriptions(subscriptions);
        setCheckedState(new Array(subscriptions.length).fill(false));
        setMetricAddonCheckedState(
            new Array(masterMetricAddons.length).fill(false)
        );
        setModeAddonCheckedState(
            new Array(masterModeAddons.length).fill(false)
        );
        setModuleAddonCheckedState(
            new Array(masterModulesList.length).fill(false)
        );
        setModeAddonPrice(0);
        setMetricAddonPrice(0);
        setSubscriptionPrice(new Array(subscriptions.length).fill(0));
        const updateModuleActiveStates: object = { 'active_transportation': true, 'zone_activity': false }
        setDisableModule(updateModuleActiveStates);

        //populate subscription price
        let totalPrice = 0;
        setTotSubscriptionCost(totalPrice);
    }, [props.basePrice, selectedRegionBasedPackage]);

    const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRegionBasedPackage(event.target.value);
        props.solutionPackageType(event.target.value);
        props.subTotal(0);
        setModulesState([]);
        const updateModuleActiveStates: object = { 'active_transportation': true, 'zone_activity': false }
        setDisableModule(updateModuleActiveStates);
        setTotSubscriptionCost(0);
        setMetricAddonCheckedState([]);
        setModeAddonCount(0);
        setMetricAddonCount(0);
        setMetricAddonPrice(0);
        setModeAddonCheckedState([]);
        setModuleAddonCheckedState([]);
    };
    const PackagesAndSubscriptionHeader: React.CSSProperties = {
        color: COLORS.YELLOW,
    };
    const PackageTypeRadioBtn: React.CSSProperties = {
        width: "30%",
        marginBottom: "15px",
    };
    const RadioBtn: React.CSSProperties = {
        borderColor: COLORS.YELLOW,
        backgroundColor: COLORS.BLACK92,
    };
    const SubscriptionBox: React.CSSProperties = {
        marginTop: "8px",
        marginBottom: "10px",
        borderBottom: "0.1px block #909090",
        paddingBottom: "5px",
        paddingTop: "1px",
    };
    const LastSubscriptionBox: React.CSSProperties = {
        marginTop: "5px",
    };
    return (
        <div>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header
                        style={{ backgroundColor: COLORS.BLACK92 }}
                    >
                        <h5
                            className="fw-bold"
                            style={PackagesAndSubscriptionHeader}
                        >
                            Package Type and Subscription
                        </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div
                            style={{
                                backgroundColor: COLORS.BLACK92,
                                padding: "20px",
                                paddingTop: "5px",
                            }}
                        >
                            <div style={{ borderBottom: "0.5px solid white" }}>
                                <h6 className="fw-bold">Package Type</h6>
                                <div
                                    className="package-selection"
                                    style={PackageTypeRadioBtn}
                                >
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            style={RadioBtn}
                                            type="radio"
                                            name="packageType"
                                            id="taz_based_packages"
                                            value="taz_based_packages"
                                            onChange={radioHandler}
                                            checked={
                                                selectedRegionBasedPackage ===
                                                "taz_based_packages"
                                            }
                                        />
                                        <label
                                            className="form-check-label text-light fw-normal"
                                            htmlFor="taz_based_packages"
                                        >
                                            TAZ Based Packages
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            style={RadioBtn}
                                            type="radio"
                                            name="packageType"
                                            id="zone_based_packages"
                                            value="zone_based_packages"
                                            checked={
                                                selectedRegionBasedPackage ===
                                                "zone_based_packages"
                                            }
                                            onChange={radioHandler}
                                        />
                                        <label
                                            className="form-check-label text-light fw-normal"
                                            htmlFor="zone_based_packages"
                                        >
                                            Zone Based Packages
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div style={SubscriptionBox}>
                                <div
                                    className="d-flex align-items-center"
                                    style={{ paddingBottom: "10px" }}
                                >
                                    <h6 className="fw-bold me-2 mb-0">
                                        Subscription
                                    </h6>
                                    <a
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                    >
                                        <img
                                            src={
                                                require("../../../../assets/question_mark.svg")
                                                    .default
                                            }
                                            alt="Question Mark"
                                        />
                                    </a>
                                </div>
                                <div className="row">
                                    <div className="d-flex">
                                        <div className="col"></div>
                                        <div className="col">
                                            {" "}
                                            <h6 className="right-section d-flex align-items-end flex-column">
                                                List Price
                                            </h6>
                                        </div>
                                        <div className="col"></div>
                                        <h6 className="right-section d-flex align-items-end flex-column">
                                            Total
                                        </h6>
                                    </div>
                                </div>

                                {totSubscriptionCost > 0 ? (
                                    <>
                                        <div
                                            className="right-section d-flex align-items-end flex-column fw-bold"
                                            style={{ marginTop: "-5px" }}
                                        >
                                            {getFormattedPrice(
                                                totSubscriptionCost
                                            )}{" "}
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}

                                <>
                                    {selectedRegionBasedPackage ==
                                        "zone_based_packages" && (
                                            <div className="container">
                                                <div className="row">
                                                    <div className="form-check">
                                                        <div className="d-flex">
                                                            <div className="col">
                                                                <label className="form-check-label text-light fw-bold"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {subscriptions &&
                                        subscriptions.map(
                                            (subscription, index) => (
                                                <div
                                                    className="container"
                                                    key={index}
                                                >
                                                    <div className="row">
                                                        <div className="form-check">
                                                            <div className="d-flex">
                                                                <div className="col">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={
                                                                            subscription.name
                                                                        }
                                                                        id={`subscription-check-${index}`}
                                                                        checked={
                                                                            checkedState[
                                                                            index
                                                                            ]
                                                                        }
                                                                        onChange={() =>
                                                                            handleOnChange(
                                                                                index,
                                                                                selectedRegionBasedPackage
                                                                            )
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-check-label text-light fw-normal"
                                                                        htmlFor={`subscription-check-${index}`}
                                                                    >
                                                                        {
                                                                            subscription.name
                                                                        }
                                                                    </label>
                                                                </div>
                                                                <div className="col">
                                                                    {selectedRegionBasedPackage ==
                                                                        "taz_based_packages" &&
                                                                        checkedState[
                                                                        index
                                                                        ] && (
                                                                            <div className="right-section d-flex align-items-end flex-column">
                                                                                {getFormattedPrice(
                                                                                    subscription.price
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                    {selectedRegionBasedPackage ==
                                                                        "zone_based_packages" &&
                                                                        checkedState[
                                                                        index
                                                                        ] && (
                                                                            <div className="right-section d-flex align-items-end flex-column">
                                                                                {getFormattedPrice(
                                                                                    subscriptionPrice[
                                                                                    index
                                                                                    ]
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                <div className="col"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    {showModal && (
                                        <Modal
                                            show={showModal}
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                            size="lg"
                                            onHide={() => setShowModal(false)}
                                        >
                                            <Modal.Header
                                                closeButton
                                            ></Modal.Header>
                                            <Modal.Body>
                                                <Carousel>
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={metricimg_01}
                                                            alt="First slide"
                                                        />
                                                    </Carousel.Item>
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={metricimg_02}
                                                            alt="Second slide"
                                                        />
                                                    </Carousel.Item>
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={metricimg_03}
                                                            alt="Third slide"
                                                        />
                                                    </Carousel.Item>
                                                </Carousel>
                                            </Modal.Body>
                                        </Modal>
                                    )}
                                </>
                            </div>
                            <div style={SubscriptionBox}>
                                <div className="col"></div>
                                <h6 className="fw-bold">Modules(Add-Ons)</h6>
                                {modulesList &&
                                    modulesList.map((module, index) => (
                                        <div className="container" key={index}>
                                            <div className="row">
                                                <div className="form-check">
                                                    <div className="d-flex">
                                                        <div className="col">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={
                                                                    module.name
                                                                }
                                                                disabled={
                                                                    (Object.keys(disableModule).indexOf(module.label) > -1 ? disableModule[module.label] : false)
                                                                }
                                                                id={`module-check-${index}`}
                                                                checked={setSelectedModules(
                                                                    module.id,
                                                                    index
                                                                )}
                                                                onChange={() =>
                                                                    handleModuleAddOnChange(
                                                                        index,
                                                                        selectedRegionBasedPackage
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label text-light fw-normal"
                                                                htmlFor={`module-check-${index}`}
                                                            >
                                                                {module.name}
                                                            </label>
                                                        </div>
                                                        <div className="col">
                                                            {setSelectedModules(
                                                                module.id,
                                                                index
                                                            ) && (
                                                                    <div className="right-section d-flex align-items-end flex-column">
                                                                        {getFormattedPrice(
                                                                            module.price
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className="col">
                                                            <></>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            <div style={SubscriptionBox}>
                                <div className="col">
                                    <h6 className="fw-bold">Modes(Add-Ons)</h6>
                                    {modeAddonPrice > 0 ? (
                                        <>
                                            <div
                                                className="right-section d-flex align-items-end flex-column fw-bold"
                                                style={{ marginTop: "-20px" }}
                                            >
                                                {getFormattedPrice(
                                                    modeAddonPrice
                                                )}{" "}
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {modeAddons &&
                                    modeAddons.map((mode, index) => (
                                        <div className="container" key={index}>
                                            <div className="row">
                                                <div className="form-check">
                                                    <div className="d-flex">
                                                        <div className="col">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={
                                                                    mode.name
                                                                }
                                                                id={`mode-check-${index}`}
                                                                checked={
                                                                    modeAddonCheckedState[index]
                                                                }
                                                                onChange={() =>
                                                                    handleMetricModeAddOnChange(
                                                                        index,
                                                                        selectedRegionBasedPackage,
                                                                        "mode"
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label text-light fw-normal"
                                                                htmlFor={`module-check-${index}`}
                                                            >
                                                                {mode.name}
                                                            </label>
                                                        </div>
                                                        <div className="col">
                                                            {modeAddonCheckedState[
                                                                index
                                                            ] && (
                                                                    <div className="right-section d-flex align-items-end flex-column">
                                                                        {getFormattedPrice(
                                                                            mode.price
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className="col">
                                                            <></>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div style={LastSubscriptionBox}>
                                <div className="col">
                                    <h6 className="fw-bold">
                                        Metrics(Add-Ons)
                                    </h6>
                                    {metricAddonPrice > 0 ? (
                                        <>
                                            <div
                                                className="right-section d-flex align-items-end flex-column fw-bold"
                                                style={{ marginTop: "-20px" }}
                                            >
                                                {getFormattedPrice(
                                                    metricAddonPrice
                                                )}{" "}
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {metricAddons &&
                                    metricAddons.map((metric, index) => (
                                        <div className="container" key={index}>
                                            <div className="row">
                                                <div className="form-check">
                                                    <div className="d-flex">
                                                        <div className="col">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={
                                                                    metric.name
                                                                }
                                                                id={`metrics-check-${index}`}
                                                                checked={
                                                                    metricAddonCheckedState[index]
                                                                }
                                                                onChange={() =>
                                                                    handleMetricModeAddOnChange(
                                                                        index,
                                                                        selectedRegionBasedPackage,
                                                                        "metrics"
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label text-light fw-normal"
                                                                htmlFor={`module-check-${index}`}
                                                            >
                                                                {metric.name}
                                                            </label>
                                                        </div>
                                                        <div className="col">
                                                            {metricAddonCheckedState[
                                                                index
                                                            ] && (
                                                                    <div className="right-section d-flex align-items-end flex-column">
                                                                        {getFormattedPrice(
                                                                            metric.price
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className="col">
                                                            <></>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div
                style={{
                    margin: "20px",
                    marginTop: "5px",
                    marginBottom: "5px",
                }}
            ></div>
        </div>
    );
}

export default Subscriptions;
