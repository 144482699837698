import React, { useEffect, useState } from "react";
import { SpecialAccessAddons, ProposalBaseData } from "../../../../models/redux-models";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { Accordion } from "react-bootstrap";
import { getFormattedPrice } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import { setApiAccess } from "../../../../store/proposal-slice";

function SpecialAccess(props: {
    packageType: String;
    subscriptionTotal: number;
    modeTotal: number;
    accessTotal: Function;
}) {
    let [spAccess, setSpAccess] = useState<SpecialAccessAddons[]>([]);
    let [accessCost, setAccessCost] = useState(0);
    let [totAccessCost, setTotAccessCost] = useState(0);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const dispatch = useDispatch();
    const specialAccessAddons = useSelector((state: RootState) => state.master.specialAccessAddons);

    useEffect(() => {
        setSpAccess(specialAccessAddons);
        setTotAccessCost(0);
        props.accessTotal(0);
        dispatch(setApiAccess([]));
        setCheckedState(new Array(specialAccessAddons.length).fill(false));
        setAccessCost(
            props.packageType === "mode"
                ? (props.modeTotal * 10) / 100
                : (props.subscriptionTotal * 10) / 100
        );
    }, [props.packageType, props.modeTotal, props.subscriptionTotal]);

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        //Get total number of modes selected
        let accessDetails: ProposalBaseData[] = [];
        const totalPrice = updatedCheckedState.reduce(
            (sum, currentState, index) => {
                if (currentState === true) {
                    let acsDetail = {
                        name: spAccess[index].name,
                        price: accessCost,
                    };
                    accessDetails.push(acsDetail);
                    return sum + accessCost;
                }
                return sum;
            },
            0
        );
        setTotAccessCost(totalPrice);
        props.accessTotal(totalPrice);
        dispatch(setApiAccess(accessDetails));
    };

    return (
        <div style={{
            borderBottom: '0.5px solid white', marginTop: "5px",
            marginBottom: "2px", paddingTop: "10px", paddingBottom: "10px"
        }}>
            <h4 className="fs-6 fw-bold">Special Access</h4>
            {totAccessCost > 0 ? (
                <div
                    className="right-section d-flex align-items-end flex-column fw-bold"
                    style={{ marginTop: "-20px" }}
                >
                    {getFormattedPrice(totAccessCost)}
                </div>
            ) : (
                <></>
            )}
            <>
                {spAccess &&
                    spAccess.map((spAcs, index) => (
                        <div className="form-check" key={index}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                checked={checkedState[index]}
                                onChange={() => handleOnChange(index)}
                            />
                            <label className="form-check-label text-light fw-normal">
                                {spAcs.name}
                            </label>
                            {checkedState[index] && (
                                <div
                                    className="right-section d-flex align-items-end flex-column"
                                    style={{ marginTop: "-20px" }}
                                >
                                    {getFormattedPrice(accessCost)}
                                </div>
                            )}
                        </div>
                    ))}
            </>
        </div>
    );
}

export default SpecialAccess;
