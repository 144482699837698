import { Fragment } from "react";
import Stepper from "./Stepper";
import Controls from "./Controls";

function Footer(props: {
    tazCount: number;
    currentStep: number;
    clickNext: Function;
    clickPrevious: Function;
}) {
    return (
        <Fragment>
            <footer
                className="bg-darkprimary text-light d-flex flex-row align-items-center fixed-bottom"
                style={{ paddingBottom: "11px" }}
            >
                <Stepper currentStep={props.currentStep} />
                <Controls
                    tazCount={props.tazCount}
                    currentStep={props.currentStep}
                    clickNext={props.clickNext}
                    clickPrevious={props.clickPrevious}
                />
            </footer>
        </Fragment>
    );
}

export default Footer;
