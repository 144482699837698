import React, { useEffect, useState } from "react";
import {
    ServiceAddons,
    ProposalBaseData,
} from "../../../../models/redux-models";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import { getFormattedPrice } from "../../../../utils/utils";
import { setService } from "../../../../store/proposal-slice";
import { COLORS } from "../../../../utils/constants";
import Training from "./Training";
import Support from "./Support";
import Accordion from "react-bootstrap/Accordion";

function Services(props: {
    packageType: String;
    serviceTotal: Function;
    trainingTotal: Function;
    subscriptionTotal: number;
    supportTotal: Function;
}) {
    let [services, setServices] = useState<ServiceAddons[]>([]);
    let [totalServicesCost, setTotalServicesCost] = useState(0);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const servicesAddons = useSelector(
        (state: RootState) => state.master.serviceAddons
    );
    let selectedService = useSelector(
        (state: RootState) => state.proposal.service
    );
    const dispatch = useDispatch();

    const handleOnChange = (position: number) => {
        let currentState = false;
        const updatedCheckedState = checkedState.map((item, index) => {
            if (index === position) {
                item = !item;
                currentState = item;
            }
            return item;
        });
        setCheckedState(updatedCheckedState);
        if (!currentState) {
            let locServices = services.map((service, i) => {
                if (position === i) {
                    return {
                        ...service,
                        price: 0,
                    };
                }
                return service;
            });
            setServices(locServices);

            let updatedService = selectedService.filter((serviceLoc) => {
                if (serviceLoc.name !== services[position].name) {
                    return serviceLoc;
                }
            });

            let serviceTotalPrice = updatedService.reduce((sum, currentObj) => {
                return sum + currentObj.price;
            }, 0);

            dispatch(setService(updatedService));
            setTotalServicesCost(serviceTotalPrice);
            props.serviceTotal(serviceTotalPrice);
        }
    };

    const updateServiceCost = (val: number, index: number) => {
        if (val <= 0) {
            val = 0;
        }
        let locServices = services.map((service, i) => {
            if (index === i) {
                return {
                    ...service,
                    price: val,
                };
            }
            return service;
        });
        setServices(locServices);

        let serviceTotalPrice = 0;
        let serviceExists = () => {
            for (let i = 0; i < selectedService.length; i++) {
                if (selectedService[i].name === services[index].name) {
                    return true;
                }
            }
            return false;
        };
        if (serviceExists()) {
            let updatedService = selectedService.map((serviceLoc) => {
                if (serviceLoc.name === services[index].name) {
                    return {
                        ...serviceLoc,
                        price: val,
                    };
                }
                return serviceLoc;
            });

            serviceTotalPrice = updatedService.reduce((sum, currentObj) => {
                return sum + currentObj.price;
            }, 0);

            dispatch(setService(updatedService));
        } else {
            let serviceDetail: ProposalBaseData = {
                name: services[index].name,
                price: val,
            };
            // selectedService.push(serviceDetail);
            selectedService = [...selectedService, serviceDetail];
            serviceTotalPrice = selectedService.reduce((sum, currentObj) => {
                return sum + currentObj.price;
            }, 0);
            dispatch(setService(selectedService));
        }

        setTotalServicesCost(serviceTotalPrice);
        props.serviceTotal(serviceTotalPrice);
    };

    useEffect(() => {
        setServices(servicesAddons);
        setCheckedState(new Array(servicesAddons.length).fill(false));
        setTotalServicesCost(0);
    }, [props.packageType]);
    const PackagesAndSubscriptionHeader: React.CSSProperties = {
        color: COLORS.YELLOW,
    };
    const SubscriptionBox: React.CSSProperties = {
        marginTop: "8px",
        marginBottom: "10px",
        borderBottom: "0.1px block #909090",
        paddingBottom: "5px",
        paddingTop: "1px"
    };
    const LastSubscriptionBox: React.CSSProperties = {
        marginTop: "5px",
    };
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h5
                            className="fw-bold"
                            style={PackagesAndSubscriptionHeader}
                        >
                            Training & Services
                        </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div
                            style={{
                                backgroundColor: COLORS.BLACK92,
                                padding: "20px",
                                paddingTop: "5px",
                            }}
                        >
                            <div>
                                <div>
                                    <Training
                                        packageType={props.packageType}
                                        trainingTotal={props.trainingTotal}
                                    />
                                    <div style={SubscriptionBox}>
                                        <h4 className="fs-6 fw-bold">
                                            Services
                                        </h4>
                                        {totalServicesCost > 0 ? (
                                            <div
                                                className="right-section d-flex align-items-end flex-column fw-bold"
                                                style={{ marginTop: "-20px" }}
                                            >
                                                {getFormattedPrice(
                                                    totalServicesCost
                                                )}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <>
                                            {services &&
                                                services.map(
                                                    (service, index) => (
                                                        <div
                                                            className="form-check"
                                                            key={index}
                                                        >
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={
                                                                    service.name
                                                                }
                                                                id={`service-check-${index}`}
                                                                checked={
                                                                    checkedState[
                                                                    index
                                                                    ]
                                                                }
                                                                onChange={() =>
                                                                    handleOnChange(
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                            <label className="form-check-label text-light fw-normal">
                                                                {service.name}
                                                            </label>
                                                            <div
                                                                className="right-section d-flex align-items-end flex-column"
                                                                style={{
                                                                    marginTop:
                                                                        "-20px",
                                                                }}
                                                            >
                                                                <div
                                                                    className="input-group mb-3"
                                                                    style={{
                                                                        width: "17%",
                                                                    }}
                                                                >
                                                                    <input
                                                                        className="bg-darkprimary text-light form-control input-wrap"
                                                                        style={{
                                                                            borderRadius:
                                                                                "0",
                                                                            padding:
                                                                                "10px",
                                                                            border: "none",
                                                                            textAlign:
                                                                                "center",
                                                                        }}
                                                                        type="number"
                                                                        min="1"
                                                                        placeholder="Cost"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            let val =
                                                                                parseInt(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                    10
                                                                                );
                                                                            if (
                                                                                !isNaN(
                                                                                    val
                                                                                )
                                                                            ) {
                                                                                updateServiceCost(
                                                                                    val,
                                                                                    index
                                                                                );
                                                                            }
                                                                        }}
                                                                        disabled={
                                                                            !checkedState[
                                                                            index
                                                                            ]
                                                                        }
                                                                        value={
                                                                            service.price
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                        </>
                                    </div>
                                    <Support
                                        packageType={props.packageType}
                                        subscriptionTotal={
                                            props.subscriptionTotal
                                        }
                                        supportTotal={props.supportTotal}
                                    />
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div
                style={{
                    margin: "20px",
                    marginTop: "5px",
                    marginBottom: "5px",
                }}
            ></div>
        </div>
    );
}

export default Services;
