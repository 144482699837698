import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth-slice";
import masterReducer from "./master-slice";
import commonReducer from "./common-slice";
import proposalReducer from "./proposal-slice";

const appReducer = combineReducers({
    auth: authReducer,
    master: masterReducer,
    proposal: proposalReducer,
    common: commonReducer
  });

export const store = configureStore({
    reducer: appReducer
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
