import { ProposalData } from "../models/redux-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ProposalData = {
    subscription: [],
    mode: [],
    alacarte: [],
    extraSeat: [],
    apiAccess: [],
    support: [],
    training: [],
    service: [],
    customAddon: [],
    modeMetrices: [],
    subsAddonModes: {},
    subsAddonMetrics: {},
    subsAddonModules: {},
    subsMetricsPrice: 0,
    subsModesPrice: 0,
    subsModulesPrice: 0,
    discount: [],
    salesTax: 0
};

export const proposalSlice = createSlice({
    name: "proposal",
    initialState,
    reducers: {
        setSubscription: (state, action: PayloadAction<any[]>) => {
            state.subscription = action.payload;
        },
        setMode: (state, action: PayloadAction<any[]>) => {
            state.mode = action.payload;
        },
        setAlacarte: (state, action: PayloadAction<any[]>) => {
            state.alacarte = action.payload;
        },
        setExtraSeat: (state, action: PayloadAction<any>) => {
            state.extraSeat = action.payload;
        },
        setApiAccess: (state, action: PayloadAction<any[]>) => {
            state.apiAccess = action.payload;
        },
        setSupport: (state, action: PayloadAction<any[]>) => {
            state.support = action.payload;
        },
        setTraining: (state, action: PayloadAction<any[]>) => {
            state.training = action.payload;
        },
        setService: (state, action: PayloadAction<any[]>) => {
            state.service = action.payload;
        },
        setCustomAddon: (state, action: PayloadAction<any[]>) => {
            state.customAddon = action.payload;
        },
        setModeMetrices: (state, action: PayloadAction<any[]>) => {
            state.modeMetrices = action.payload;
        },
        setSubsAddonMetrics: (state, action: PayloadAction<any[]>) => {
            state.subsAddonMetrics = action.payload;
        },
        setSubsAddonModes: (state, action: PayloadAction<any[]>) => {
            state.subsAddonModes = action.payload;
        },
        setSubsAddonModules: (state, action: PayloadAction<any[]>) => {
            state.subsAddonModules = action.payload;
        },
        setDiscount: (state, action: PayloadAction<any[]>) => {
            state.discount = action.payload;
        },
        setSalesTax: (state, action: PayloadAction<any>) => {
            state.salesTax = action.payload;
        },
        setSubsMetricsPrice: (state, action: PayloadAction<any>) => {
            state.subsMetricsPrice = action.payload;
        },
        setSubsModesPrice: (state, action: PayloadAction<any>) => {
            state.subsModesPrice = action.payload;
        },
        setSubsModulesPrice: (state, action: PayloadAction<any>) => {
            state.subsModulesPrice = action.payload;
        },
        resetStore: (state) => {
            state.subscription = [];
            state.mode = [];
            state.alacarte = [];
            state.extraSeat = [];
            state.apiAccess = [];
            state.support = [];
            state.training = [];
            state.service = [];
            state.customAddon = [];
            state.modeMetrices = [];
            state.subsAddonModes = {};
            state.subsAddonMetrics = {};
            state.subsAddonModules = {};
            state.discount = [];
            state.salesTax = 0;
            state.subsMetricsPrice = 0;
            state.subsModesPrice = 0;
        },
    },
});

export const {
    setSubscription,
    setMode,
    setAlacarte,
    setExtraSeat,
    setApiAccess,
    setSupport,
    setTraining,
    setService,
    setCustomAddon,
    setModeMetrices,
    setSubsAddonMetrics,
    resetStore,
    setSubsAddonModes,
    setDiscount,
    setSalesTax,
    setSubsModesPrice,
    setSubsMetricsPrice,
    setSubsAddonModules,
    setSubsModulesPrice
} = proposalSlice.actions;
export default proposalSlice.reducer;
