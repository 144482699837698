import { Fragment } from "react";
import Account from "./Account";
import Logo from "./Logo";
import CompanyName from "./CompanyName";

import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

function Header() {
  const user = useSelector((state: RootState) => state.auth.user);
  return (
    <Fragment>
      <header className="bg-darkprimary d-flex align-items-center">
        <Logo />
        {user ? <CompanyName /> : ''}
        {/* {user ? <Account /> : ''} */}
      </header>
    </Fragment>
  );
}

export default Header;
