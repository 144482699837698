import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import {
    ProposalBaseData,
    ModeMetrics,
    SubscriptionModeMetrics,
    AlaCarteProposalBaseData,
} from "../../models/redux-models";
import { RootState } from "../../store/store";
import {
    getFormattedPrice,
    getFormattedDiscountPrice,
} from "../../utils/utils";
import propimg from "../../assets/propimg.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { DISCOUNT_TAZ_BASED, DISCOUNT_ZONE_BASED } from "../../utils/constants";


function Proposal() {
    const subscription = useSelector(
        (state: RootState) => state.proposal.subscription
    );
    const mode = useSelector((state: RootState) => state.proposal.mode);
    const modeMetrices = useSelector((state: RootState) => state.proposal.modeMetrices);
    const subsAddonMetrics = useSelector((state: RootState) => state.proposal.subsAddonMetrics);
    const setSubsAddonModes = useSelector((state: RootState) => state.proposal.subsAddonModes);
    const subsAddonModules = useSelector((state: RootState) => state.proposal.subsAddonModules);
    const subsMetricsPrice = useSelector((state: RootState) => state.proposal.subsMetricsPrice);
    const subsModesPrice = useSelector((state: RootState) => state.proposal.subsModesPrice);
    const subsModulesPrice = useSelector((state: RootState) => state.proposal.subsModulesPrice);
    const training = useSelector((state: RootState) => state.proposal.training);
    const service = useSelector((state: RootState) => state.proposal.service);
    const support = useSelector((state: RootState) => state.proposal.support);
    const seats = useSelector((state: RootState) => state.proposal.extraSeat);
    const access = useSelector((state: RootState) => state.proposal.apiAccess);
    const custAddOn = useSelector(
        (state: RootState) => state.proposal.customAddon
    );
    const discount = useSelector((state: RootState) => state.proposal.discount);
    const salseTax = useSelector((state: RootState) => state.proposal.salesTax);
    const customer = useSelector((state: RootState) => state.common.customer);
    const mapScreen = useSelector((state: RootState) => state.common.mapScreen);
    const printRef = React.useRef<HTMLInputElement>(null);

    const handleDownloadPdf = async () => {
        const element = printRef.current!;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(data, "PNG", 0, 5, pdfWidth, pdfHeight);
        pdf.save(
            customer.name === ""
                ? "proposal.pdf"
                : customer.name + "-proposal.pdf"
        );
    };


    return (
        <Fragment>
            <main>
                <div className="container-fluid py-4 bg-normalprimary">
                    <div ref={printRef} className="row align-items-md-stretch">
                        <div className="col-md-5 ps-5">
                            <div
                                className="h-100 p-5 text-darkprimary bg-dark rounded-3 position-relative"
                                style={{ minHeight: "550px" }}
                            >
                                <hr className="w-100 bg-darkprimary" />
                                <h4>STREETLIGHT INSIGHT®</h4>
                                <h1 className="mt-3">
                                    {subscription.length > 0
                                        ? "Solution "
                                        : mode.length > 0
                                            ? "Mode "
                                            : "Solution"}{" "}
                                    Packages Proposal
                                </h1>
                                {customer.name !== "" ? (
                                    <div className="d-flex gap-2">
                                        <h4>Customer :</h4>
                                        <h4>{customer.name}</h4>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <img
                                    className="position-absolute bottom-0 mb-5"
                                    src={propimg}
                                />
                            </div>
                        </div>
                        <div className="col-md-7 pe-5">
                            <div className="h-100 p-5 bg-midprimary text-light rounded-3 d-flex flex-column">
                                {subscription.length > 0 && (
                                    <>
                                        <h4>Subscription</h4>
                                        {
                                            <div
                                                className="right-section d-flex align-items-end flex-column"
                                                style={{ marginTop: "-20px", marginRight: "30px" }}
                                            >
                                                {getFormattedPrice(
                                                    getSubscriptionPrice()
                                                )}
                                            </div>
                                        }

                                        {getSubscriptionView(subscription)}
                                    </>
                                )}
                                {subsAddonModules.length > 0 && subscription.length > 0 &&
                                    <>
                                        <h6>Modules (Addons)</h6>

                                        {getSubsModeMetricesView(subsAddonModules)}

                                        <label className="form-check-label text-light fw-normal">{getSubscriptionIdExists(1004) && (<i>(Active Transporation is included as a part of package)</i>)}</label>
                                    </>}
                                {subsAddonModules.length > 0 && subscription.length == 0 &&
                                    <>
                                        <h6>Modules (Addons)</h6>
                                        {
                                            <div
                                                className="right-section d-flex align-items-end flex-column"
                                                style={{ marginTop: "-20px", marginRight: "30px" }}
                                            >
                                                {getFormattedPrice(subsModulesPrice + (calculateSalesTax() * subsAddonModules.length))}
                                            </div>
                                        }
                                        {getSubsModeMetricesView(subsAddonModules)}
                                    </>}
                                {subsAddonMetrics.length > 0 &&
                                    <>
                                        <h6>Metrics (Addons)</h6>
                                        {
                                            <div
                                                className="right-section d-flex align-items-end flex-column"
                                                style={{ marginTop: "-20px", marginRight: "30px" }}
                                            >
                                                {getFormattedPrice(subsMetricsPrice + (calculateSalesTax() * subsAddonMetrics.length))}
                                            </div>
                                        }
                                        {getSubsModeMetricesView(subsAddonMetrics)}
                                    </>}
                                {setSubsAddonModes.length > 0 &&
                                    <>
                                        <h6>Modes (Addons)</h6>
                                        {
                                            <div
                                                className="right-section d-flex align-items-end flex-column"
                                                style={{ marginTop: "-20px", marginRight: "30px" }}
                                            >
                                                {getFormattedPrice(subsModesPrice + (calculateSalesTax() * setSubsAddonModes.length))}
                                            </div>
                                        }
                                        {getSubsModeMetricesView(setSubsAddonModes)}
                                    </>}
                                {(getSubscriptionType() == 'zone_based_packages' && subscription.length > 1) && <>
                                    <h6><i>(Final package value (after {getSubscriptionType() == 'zone_based_packages' ? DISCOUNT_ZONE_BASED * 100 : DISCOUNT_TAZ_BASED * 100}% flat discount))</i></h6>
                                    {
                                        <div
                                            className="right-section d-flex align-items-end flex-column"
                                            style={{ marginTop: "-20px", marginRight: "30px" }}
                                        >
                                            {getFormattedPrice(getSubsPriceWithAddons('zone_based_packages'))}
                                        </div>
                                    }
                                </>}
                                {(getSubscriptionType() == 'taz_based_packages' && subscription.length > 0) && <>
                                    <h6><i>(Final package value (after {getSubscriptionType() == 'zone_based_packages' ? DISCOUNT_ZONE_BASED * 100 : DISCOUNT_TAZ_BASED * 100}% flat discount))</i></h6>
                                    {
                                        <div
                                            className="right-section d-flex align-items-end flex-column"
                                            style={{ marginTop: "-20px", marginRight: "30px" }}
                                        >
                                            {getFormattedPrice(getSubsPriceWithAddons('taz_based_packages'))}
                                        </div>
                                    }
                                </>}
                                {mode.length > 0 && (
                                    <>
                                        <h4>Mode</h4>
                                        {getView(mode)}
                                    </>
                                )}

                                {modeMetrices && modeMetrices.length > 0 && (
                                    <>
                                        <h4>Metrics</h4>
                                        {getMetricesView(modeMetrices)}
                                    </>
                                )}


                                <div>
                                    {!!(
                                        support.length > 0 ||
                                        training.length > 0 ||
                                        service.length > 0 ||
                                        seats.length ||
                                        access.length > 0 ||
                                        custAddOn.length
                                    ) && <h4>Additional Items</h4>}
                                    {training.length > 0 && (
                                        <>{getView(training)}</>
                                    )}
                                </div>
                                {service.length > 0 && <>{getView(service)}</>}
                                {seats.length > 0 && <>{getView(seats)}</>}
                                {access.length > 0 && <>{getView(access)}</>}
                                {custAddOn.length > 0 && (
                                    <>{getView(custAddOn)}</>
                                )}
                                {discount.length > 0 ? (
                                    <>
                                        <h4>Discount</h4>
                                        {getDiscountView(discount)}
                                        <h4>Total</h4>
                                        {
                                            <h4
                                                className="right-section d-flex align-items-end flex-column text-bold"
                                                style={{ marginTop: "-20px", marginRight: "30px" }}
                                            >
                                                {getFormattedPrice(getTotalPrice(true))}
                                            </h4>
                                        }
                                    </>
                                ) : discount.length == 0 ? (
                                    <>
                                        <h4>Total</h4>
                                        {
                                            <h4
                                                className="right-section d-flex align-items-end flex-column text-bold"
                                                style={{ marginTop: "-20px", marginRight: "30px" }}
                                            >
                                                {getFormattedPrice(getTotalPrice())}
                                            </h4>
                                        }
                                    </>
                                ) : null}
                                <div style={{ flex: '1' }}>
                                    { }
                                </div>
                                <footer
                                    className="text-light justify-content-left" style={{ marginTop: "20px" }}
                                >
                                    *This quote is valid for 30 days only. {salseTax == 0 && "State sales tax may apply on top of the quoted price."}
                                </footer>
                            </div>
                        </div>
                        <img className="p-5" src={mapScreen.map} />
                    </div>
                </div>
                <footer
                    className="bg-darkprimary text-light d-flex justify-content-end"
                    style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <button
                        className="btn btn-outline-dark btn-lg rounded-0 mx-2"
                        onClick={handleDownloadPdf}
                    >
                        Print Proposal
                    </button>
                </footer>
            </main>
        </Fragment >
    );

    function getSubsPriceWithAddons(type: string) {
        let price = Math.round(getSubscriptionPrice());
        let addons = setSubsAddonModes.concat(subsAddonMetrics);
        for (const data of addons) {
            price += data.price + calculateSalesTax();
        }
        if (type == 'zone_based_packages') {
            price *= (1 - DISCOUNT_ZONE_BASED);
        }
        else if (type == 'taz_based_packages') {
            price *= (1 - DISCOUNT_TAZ_BASED);
        }
        return price;
    }
    function getSubscriptionPrice() {
        let price = 0;
        for (const data of subscription) {
            price += data.price;
        }
        return price + calculateSalesTax(true);
    }

    function getSubscriptionType() {
        if (subscription.length > 0) {
            return subscription[0].type;
        }
        return 'taz_based_packages'

    }


    function getView(dataSet: ProposalBaseData[]) {
        return dataSet.map((data) => (
            <div className="form-check">
                <label className="form-check-label text-light fw-normal">
                    {data.name}
                </label>
                {
                    <div
                        className="right-section d-flex align-items-end flex-column"
                        style={{ marginTop: "-20px", marginRight: "30px" }}
                    >
                        {getFormattedPrice(data.price)}
                    </div>
                }
            </div>
        ));
    }

    function getSubscriptionIdExists(id: number) {
        return subscription.find(x => x.id == id)
    }

    function getSubscriptionView(dataSet: ProposalBaseData[]) {
        const tax = calculateSalesTax(true);
        const perSubscriptionTax = tax / dataSet.length;
        return dataSet.map((data) => (
            <div className="form-check">
                <label className="form-check-label text-light fw-normal">
                    {data.name}
                </label>
                {data.price !== undefined &&
                    <div
                        className="right-section d-flex align-items-end flex-column"
                        style={{ marginTop: "-20px" }}
                    >
                        {getFormattedPrice(data.price + perSubscriptionTax)}
                    </div>
                }
            </div>
        ));
    }

    function getMetricesView(dataSet: ModeMetrics[]) {
        return dataSet.map((data, index) => (
            <div className="form-check" key={index}>
                <label className="form-check-label text-light fw-normal">
                    {data.name}
                </label>
            </div>
        ));
    }

    function getDiscountView(discount: ProposalBaseData[]) {
        let total = 0;
        total = getTotalPrice(false, false, true);

        return discount.map((data) => (
            <div className="form-check">
                <label className="form-check-label text-light fw-normal">
                    {"Amount"}
                </label>
                {
                    <div
                        className="right-section d-flex align-items-end flex-column"
                        style={{ marginTop: "-20px", marginRight: "30px" }}
                    >
                        {getFormattedDiscountPrice((total * data.price) / 100)}
                    </div>
                }
            </div>
        ));
    }

    function getSubsModeMetricesView(dataSet: SubscriptionModeMetrics[]) {
        const subscription_type = getSubscriptionType()
        return dataSet.map((data) => (
            <div className="form-check">
                <label className="form-check-label text-light fw-normal">
                    {data.name} {data.name == 'Network Performance' && (<i>(Segment Analysis is included as part of the package)</i>)} {data.name == 'Network OD' && subscription_type == "zone_based_packages" && (<i>(OD is included as part of the package)</i>)}
                </label>
                {data.price !== undefined &&
                    <div
                        className="right-section d-flex align-items-end flex-column"
                        style={{ marginTop: "-20px" }}
                    >
                        {getFormattedPrice(data.price + calculateSalesTax())}
                    </div>
                }

            </div>
        ));
    }

    function getTotalPrice(additionalDiscountApplied: boolean = false, getSalesTax = false, excludeSalesTax = false) {
        //Subscription, mode mutually exclusive
        let total = 0;
        let substype = subscription.length > 0 ? 'zone_based_packages' : null;
        if (subscription.length > 0) {
            for (const data of subscription) {
                total += data.price;
                if (data.type == 'taz_based_packages') {
                    substype = data.type;
                }
            }
        };
        if (substype == 'taz_based_packages' && subscription.length > 0) {
            //apply 10% discount
            let modeCount = subsAddonMetrics.length
            let metricsCount = setSubsAddonModes.length
            total = total *
                (1 + 0.15 * (modeCount + metricsCount));
            total *= (1 - DISCOUNT_TAZ_BASED);
        }
        if (substype == 'zone_based_packages') {
            if (subscription.length == 1) {
                total += (subsMetricsPrice + subsModesPrice)
            } else {
                //apply 30% discount
                let modeCount = subsAddonMetrics.length
                let metricsCount = setSubsAddonModes.length
                total = total *
                    (1 + 0.15 * (modeCount + metricsCount));
                total *= (1 - DISCOUNT_ZONE_BASED);
            }
        }
        if (mode.length > 0) {
            for (const data of mode) {
                total += data.price;
            }
        }

        //for only modules
        if (subscription.length == 0 && mode.length == 0 && subsAddonModules.length > 0) {
            let modeCount = subsAddonMetrics.length
            let metricsCount = setSubsAddonModes.length
            total = subsModulesPrice
            total = total *
                (1 + 0.15 * (modeCount + metricsCount));
        }


        if (support.length > 0) {
            support.forEach((data) => {
                total = total + data.price;
            });
        }
        if (training.length > 0) {
            training.forEach((data) => {
                total = total + data.price;
            });
        }
        if (service.length > 0) {
            service.forEach((data) => {
                total = total + data.price;
            });
        }
        if (seats.length > 0) {
            seats.forEach((data) => {
                total = total + data.price;
            });
        }
        if (access.length > 0) {
            access.forEach((data) => {
                total = total + data.price;
            });
        }
        if (custAddOn.length > 0) {
            custAddOn.forEach((data) => {
                total = total + data.price;
            });
        }
        if (additionalDiscountApplied == true) {
            //Applying additional discount to total if present
            discount.forEach((data) => {
                total = total - (total * data.price) / 100;
            });
        }
        if (getSalesTax)
            return total * salseTax / 100;
        if (!excludeSalesTax)
            total = total + total * salseTax / 100
        return total;
    }

    function calculateSalesTax(forSubscription = false) {
        // Sales Tax value 
        const salesTaxValue = discount.length > 0 ? getTotalPrice(true, true) : getTotalPrice(false, true);
        const totalComponents = subsAddonMetrics.length + setSubsAddonModes.length + subsAddonModules.length
        const perComponantTax = Math.round(salesTaxValue / totalComponents);
        if (forSubscription)
            return salesTaxValue - (perComponantTax * (subsAddonMetrics.length + setSubsAddonModes.length));
        return perComponantTax;
    }

}
export default Proposal;
