import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommonData, Customer, MapScreen } from "../models/redux-models";

const initialState: CommonData = {
    customer: { name: "" },
    mapScreen: { map: "" },
    loader: false,
};

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        updateCustomer: (state, action: PayloadAction<Customer>) => {
            state.customer = action.payload;
        },
        updateMap: (state, action: PayloadAction<MapScreen>) => {
            state.mapScreen = action.payload;
        },
        setLoader: (state, action: PayloadAction<Boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { updateCustomer, updateMap, setLoader } = commonSlice.actions;
export default commonSlice.reducer;
