import React, { useEffect, useState } from "react";
import {
    SupportAddons,
    ProposalBaseData,
} from "../../../../models/redux-models";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useDispatch } from "react-redux";
import { setSupport } from "../../../../store/proposal-slice";

function Support(props: {
    packageType: String;
    subscriptionTotal: number;
    supportTotal: Function;
}) {
    let [supports, setSupports] = useState<SupportAddons[]>([]);
    let [totSupportCost, setTotSupportCost] = useState(0);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const supportAddons = useSelector(
        (state: RootState) => state.master.supportAddons
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.packageType === "subscription") {
            let updatedSupportAddon: SupportAddons[] = [];
            if (props.subscriptionTotal <= 25000) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 5000,
                }));
            } else if (
                props.subscriptionTotal > 25000 &&
                props.subscriptionTotal <= 50000
            ) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 5000,
                }));
            } else if (
                props.subscriptionTotal > 50000 &&
                props.subscriptionTotal <= 80000
            ) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 7500,
                }));
            } else if (
                props.subscriptionTotal > 80000 &&
                props.subscriptionTotal <= 100000
            ) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 9600,
                }));
            } else if (
                props.subscriptionTotal > 100000 &&
                props.subscriptionTotal <= 200000
            ) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 12000,
                }));
            } else if (
                props.subscriptionTotal > 200000 &&
                props.subscriptionTotal <= 300000
            ) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 20000,
                }));
            } else if (
                props.subscriptionTotal > 300000 &&
                props.subscriptionTotal <= 500000
            ) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 30000,
                }));
            } else if (
                props.subscriptionTotal > 500000 &&
                props.subscriptionTotal <= 1000000
            ) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 40000,
                }));
            } else if (props.subscriptionTotal > 1000000) {
                updatedSupportAddon = supportAddons.map((addon) => ({
                    ...addon,
                    price: 50000,
                }));
            }

            setSupports(updatedSupportAddon);
        } else {
            setSupports(supportAddons);
        }

        setCheckedState(new Array(supportAddons.length).fill(false));
        setTotSupportCost(0);
        props.supportTotal(0);
        dispatch(setSupport([]));
    }, [props.packageType, props.subscriptionTotal]);

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        let supportDetails: ProposalBaseData[] = [];
        const totalPrice = updatedCheckedState.reduce(
            (sum, currentState, index) => {
                if (currentState === true) {
                    let supportDetail = {
                        name: supports[index].name,
                        price: supports[index].price,
                    };
                    supportDetails.push(supportDetail);
                    return sum + supports[index].price;
                }
                return sum;
            },
            0
        );

        setTotSupportCost(totalPrice);
        props.supportTotal(totalPrice);
        dispatch(setSupport(supportDetails));
    };

    const getFormattedPrice = (price: number) => `$${price}`;
    const LastSubscriptionBox: React.CSSProperties = {
        marginTop: "5px",
    };
    return (
        <div style={LastSubscriptionBox}>
            <h4 className="fs-6 fw-bold">Support</h4>
            {totSupportCost > 0 ? (
                <div
                    className="right-section d-flex align-items-end flex-column fw-bold"
                    style={{ marginTop: "-20px" }}
                >
                    {getFormattedPrice(totSupportCost)}
                </div>
            ) : (
                <></>
            )}
            <>
                {supports &&
                    supports.map((support, index) => (
                        <div className="form-check" key={index}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={support.name}
                                id={`support-check-${index}`}
                                checked={checkedState[index]}
                                onChange={() => handleOnChange(index)}
                            />
                            <label
                                className="form-check-label text-light fw-normal"
                                htmlFor={`support-check-${index}`}
                            >
                                {support.name}
                            </label>
                            {checkedState[index] && (
                                <div
                                    className="right-section d-flex align-items-end flex-column"
                                    style={{ marginTop: "-20px" }}
                                >
                                    {getFormattedPrice(support.price)}
                                </div>
                            )}
                        </div>
                    ))}
            </>
        </div>
    );
}

export default Support;
