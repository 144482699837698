import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MasterModePrice } from "../../../../models/local-models";
import {
    MasterMode,
    Factor,
    ProposalBaseData,
} from "../../../../models/redux-models";
import { RootState } from "../../../../store/store";
import { getFormattedPrice } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import { setMode } from "../../../../store/proposal-slice";
import { COLORS } from "../../../../utils/constants";
import Accordion from 'react-bootstrap/Accordion';

function Modes(props: { basePrice: number; modeTotal: Function }) {
    let [modes, setModes] = useState<MasterModePrice[]>([]);
    let [factors, setFactors] = useState<Factor[]>([]);
    let [modePrice, setModePrice] = useState(0);
    let [totModeCost, setTotModeCost] = useState(0);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const masterModes = useSelector(
        (state: RootState) => state.master.masterMode
    );
    const masterFactors = useSelector(
        (state: RootState) => state.master.factor
    );
    console.log("Mastermodes", masterModes);
    const dispatch = useDispatch();
    useEffect(() => {
        setModes(masterModes);
        setFactors(masterFactors);
        setCheckedState(new Array(masterModes.length).fill(false));
    }, [masterModes, props.basePrice]);

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        //Get total number of modes selected
        let modeDetails: ProposalBaseData[] = [];
        const modeCount = updatedCheckedState.reduce(
            (sum, currentState, index) => {
                if (currentState === true) {
                    let modeDetail = {
                        id: modes[index].mode_id,
                        name: modes[index].name,
                        price: 0,
                    };
                    modeDetails.push(modeDetail);
                    return sum + 1;
                }
                return sum;
            },
            0
        );

        //Get mode price
        if (modeCount > 0) {
            const modePrice = () => {
                let modeFactor = factors.filter(
                    (factor) =>
                        factor.type === "mode" && factor.mode_count === modeCount
                );
                return (props.basePrice * modeFactor[0].factor) / modeCount;
            };
            setModePrice(modePrice);
            modeDetails.map((modeDetail) => {
                return (modeDetail.price = modePrice());
            });
            //Get total price
            const totalPrice = () => {
                let modeFactor = factors.filter(
                    (factor) =>
                        factor.type === "mode" && factor.mode_count === modeCount
                );
                return props.basePrice * modeFactor[0].factor;
            };
            setTotModeCost(totalPrice);
            props.modeTotal(totalPrice);
            dispatch(setMode(modeDetails));
        }
        else {
            setModePrice(0);
            modeDetails.map((modeDetail) => {
                return (modeDetail.price = 0);
            });
            setTotModeCost(0);
            props.modeTotal(0);
            dispatch(setMode(modeDetails));
        };
    }
    const SubscriptionBox: React.CSSProperties = {
        marginTop: "5px",
        marginBottom: "2px",
        borderBottom: '1px solid white',
    };
    const LastSubscriptionBox: React.CSSProperties = {
        marginTop: "5px",
    };
    const PackagesAndSubscriptionHeader: React.CSSProperties = {
        color: COLORS.YELLOW,

    };

    return (
        <div>
            <Accordion flush>
                <Accordion.Item eventKey="0" >
                    <Accordion.Header>
                        <h5 className="fw-bold" style={PackagesAndSubscriptionHeader}>Mode & Metrics</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{ backgroundColor: COLORS.BLACK92, padding: "20px", paddingTop: '5px' }}>
                            <div style={LastSubscriptionBox}>

                                <h6 className="fw-bold">Modes</h6>
                                {totModeCost > 0 ? (
                                    <div
                                        className="right-section d-flex align-items-end flex-column fw-bold"
                                        style={{ marginTop: "-20px" }}
                                    >
                                        {getFormattedPrice(totModeCost)}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <>
                                    {modes &&
                                        modes.map((mode, index) => (
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={mode.name}
                                                    id={`mode-check-${index}`}
                                                    checked={checkedState[index]}
                                                    onChange={() => handleOnChange(index)}
                                                />
                                                <label
                                                    className="form-check-label text-light fw-normal"
                                                    htmlFor={`mode-check-${index}`}
                                                >
                                                    {mode.name}
                                                </label>
                                                {checkedState[index] && (
                                                    <div
                                                        className="right-section d-flex align-items-end flex-column"
                                                        style={{ marginTop: "-20px" }}
                                                    >
                                                        {getFormattedPrice(modePrice)}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion >
            <div style={{ borderBottom: '1px solid white', margin: '20px', marginTop: '5px', marginBottom: '5px' }} >
            </div>
        </div>
    );
}

export default Modes;
